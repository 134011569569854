@for (group of groupedBookables(); track $index) {
  <guest-app-bookable-item
    [bookableItemGroup]="group"
    (openDialog)="onOpenDialog($event)">
  </guest-app-bookable-item>
} @empty {
  @if (loading()) {
    <div class="flex w-full items-center justify-center overflow-hidden">
      <div class="loadingSpinner text-primary pointer-events-none my-4 select-none"></div>
    </div>
  } @else {
    <div class="animate-fadeIn mx-2 mt-4">
      <div class="elite-text-bold border-border w-full border-2 p-4 text-center shadow">
        {{ 'appointmentlines.noEvents' | translate }}
      </div>
    </div>
  }
}
