import { Injectable } from '@angular/core';
import { QueryObserverResult, injectQuery } from '@ngneat/query';
import { Result } from '@ngneat/query/lib/types';
import { of } from 'rxjs';
import { LanguageInfos } from '../models/models';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  #query = injectQuery();

  getAllLanguages(): Result<QueryObserverResult<LanguageInfos[], Error>> {
    const langs: LanguageInfos[] = [
      {
        name: 'Deutsch',
        iconPath: 'assets/languages/german.png',
        locale: 'de',
      },
      {
        name: 'English',
        iconPath: 'assets/languages/english.png',
        locale: 'en',
      },
    ];
    return this.#query({
      queryKey: ['languages'] as const,
      queryFn: () => {
        return of(langs);
      },
    });
  }
}
