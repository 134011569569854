import { TranslateLoader } from '@ngx-translate/core/dist/lib/translate.loader';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

export type Language = 'de' | 'en';

export class CustomGuestAppLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'de':
        return of(de);
      default:
        return of(en);
    }
  }
}

export const de = {
  common: {
    YES: 'JA',
    NO: 'NEIN',
    close: 'Schließen',
    notice: 'Hinweis',
  },
  headerline: {
    settings: 'Einstellungen',
    logout: 'Abmelden',
    room: 'Zimmer',
    login: 'Anmelden',
    dataprotection: 'Datenschutz',
  },
  filteroptions: {
    showOldAppointments: 'Vergangene Termine anzeigen',
    showCommonAppointments: 'Alle Allgemeine Termine anzeigen',
    showGeneralInformation: 'Allgemeine Informationen anzeigen',
    today: 'Heute',
    tomorrow: 'Morgen',
    yesterday: 'Gestern',
  },
  settings: {
    changePin: 'PIN ändern',
    yourAccount: 'Ihr Zugang',
    oldPin: 'alter PIN',
    newPin: 'neuer PIN',
    confirmNewPin: 'neuen PIN bestätigen',
    lockAccount: 'Account sperren',
    lockMyAccount: 'Meinen Account sperren',
    back: 'Zurück',
    sureLock: 'Sind Sie Sicher, dass Sie Ihren Account sperren wollen?',
    pinsDontMatch: 'Neue PINs stimmen nicht überein.',
  },
  appointmentlines: {
    noEvents: 'Keine Termine gefunden',
    EventsNotAvailable: 'Termine können nicht angezeigt werden',
  },
  appointmentline: {
    personalAppointment: 'persönlicher Termin',
    arrival: 'Ankunft',
    departure: 'Abreise',
  },
  bookableItem: {
    duration: 'Dauer',
    date: 'Termin',
    dates: 'Termine',
    booknow: 'Jetzt buchen',
    selectOption: 'Bitte wählen Sie einen Termin',
    enterData: 'Bitte geben Sie Ihre Daten an',
    surname: 'Nachname',
    givenname: 'Vorname',
    birthday: 'Geburtsdatum',
    roomnumber: 'Zimmernummer',
    email: 'E-Mail',
    tel: 'Telefonnummer',
    dateOfResidence: 'Aufenthaltsdatum',
    from: 'von',
    to: 'bis',
    bindBooking: 'Jetzt verbindlich buchen',
    successText: 'Ihre Anfrage wird an das Spa Team geschickt, Sie erhalten eine Bestätigung per E-Mail oder Telefon',
    noBookables: 'Keine freien Termine verfügbar',
    time: 'Uhr',
    cancel: 'Abbrechen',
  },
  login: {
    yourAppointments: 'Ihre Termine',
    cookiesBlocked:
      'Sie haben die Cookies blockiert, dadurch sind verschiedene Funktionen wie z.B. die automatische Anmeldung inaktiv!',
    cantReachServer: 'Der Server konnte nicht erreicht werden!',
  },
  homepage: {
    switchToBookableAppointments: 'Freie Termine anzeigen',
    switchToMyAppointments: 'Meine Termine anzeigen',
    switchToCommonAppointments: 'Allgemeine Termine anzeigen',
  },
};

export const en = {
  common: {
    YES: 'YES',
    NO: 'NO',
    close: 'Close',
    notice: 'Notice',
  },
  headerline: {
    settings: 'Settings',
    logout: 'Logout',
    room: 'Room',
    login: 'Login',
    dataprotection: 'Dataprotection',
  },
  filteroptions: {
    showOldAppointments: 'Show past appointments',
    showCommonAppointments: 'Show all common appointments',
    showGeneralInformation: 'Show general information',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
  },
  settings: {
    changePin: 'Change PIN',
    yourAccount: 'Your Account',
    oldPin: 'old PIN',
    newPin: 'new PIN',
    confirmNewPin: 'confirm new PIN',
    lockAccount: 'Lock Account',
    lockMyAccount: 'Lock my Account',
    back: 'Back',
    sureLock: 'Are you sure, that you want to lock your Account?',
    pinsDontMatch: 'New PINs do not match.',
  },
  appointmentlines: {
    noEvents: 'No Appointments found',
    EventsNotAvailable: 'Appointments could not be loaded',
  },
  appointmentline: {
    personalAppointment: 'personal appointment',
    arrival: 'Arrival',
    departure: 'Departure',
  },
  bookableItem: {
    duration: 'Duration',
    date: 'Date',
    dates: 'Dates',
    booknow: 'Book now',
    selectOption: 'Please choose a date',
    enterData: 'Please enter your data',
    surname: 'Surname',
    givenname: 'Givenname',
    birthday: 'Date of Birth',
    roomnumber: 'Roomnumber',
    email: 'E-Mail',
    tel: 'Phone number',
    dateOfResidence: 'Date of Residence',
    from: 'from',
    to: 'to',
    bindBooking: 'Bind booking now',
    successText: 'Your request will be sent to the spa team, you will receive a confirmation by e-mail or phone',
    noBookables: 'No appointments available',
    time: '',
    cancel: 'Cancel',
  },
  login: {
    yourAppointments: 'Your Appointments',
    cookiesBlocked:
      'You have blocked the cookies, which means that various functions such as automatic login are inactive!',
    cantReachServer: 'The server could not be reached!',
  },
  homepage: {
    switchToBookableAppointments: 'Show available appointments',
    switchToMyAppointments: 'Show my appointments',
    switchToCommonAppointments: 'Show common appointments',
  },
};
