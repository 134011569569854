/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GuestAppStore } from '../../stores/guest-app.store';
import { AppointmentLineComponent } from '../appointment-line/appointment-line.component';
import { DatelineComponent } from '../dateline/dateline.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-appointment-lines',
  standalone: true,
  imports: [CommonModule, TranslateModule, DatelineComponent, AppointmentLineComponent],
  template: `@for (day of filteredAndGroupedAppointments(); let i = $index; track i) {
      @for (appointment of day; let i = $index; track i) {
        @if (i === 0) {
          <guest-app-dateline
            *ngIf="i === 0"
            [appointmentDate]="appointment.fromTime">
          </guest-app-dateline>
        }
        <guest-app-appointment-line [appointment]="appointment"></guest-app-appointment-line>
      }
    } @empty {
      @if (!loading()) {
        <div class="mx-2 mt-4">
          <div class="elite-text-bold border-border w-full rounded-lg border-2 p-4 text-center shadow">
            @if (outOfValidRange()) {
              {{ 'appointmentlines.EventsNotAvailable' | translate }}
            } @else {
              {{ 'appointmentlines.noEvents' | translate }}
            }
          </div>
        </div>
      }
    }

    @if (loading()) {
      <div
        class="loadingSpinner text-primary pointer-events-none mx-auto my-4 w-full select-none overflow-hidden"></div>
    } `,
  host: { class: 'flex flex-col gap-4 px-2' },
})
export class AppointmentLinesComponent {
  #store = inject(GuestAppStore);

  loading = computed(() => this.#store.loadingAppointments());
  outOfValidRange = computed(() => this.#store.outOfValidRange());
  filteredAndGroupedAppointments = computed(() => this.#store.filteredAndGroupedAppointments());
}
