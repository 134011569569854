//Info needed for Jest
if (!window.structuredClone) {
  window.structuredClone = function structuredClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  };
}

export type SortDirection = 'none' | 'asc' | 'desc';

export interface TableState {
  sortField: string;
  sortDirection: SortDirection;
  preset: string;
  presetConfig: PresetConfig;
  data: any[];
  displayedCols: string[];
  columns: TableColumn[];
  windowHeight: string;
  windowWidth: string;
}

export interface PresetConfig {
  drawBorders: boolean;
  drawHeader: boolean;
  drawFooter: boolean;
  drawZebra: boolean;
  drawTooltipsOnHeader: boolean;
  drawTooltipsOnFooter: boolean;
  colors: TableColors;
}

export interface TableData {
  config?: TableConfig;
  columns: TableColumn[];
  data: any[];
}

export interface TableColumn {
  keyName: string;
  displayName: string;
  width?: string;
  type?: string;
  colored?: boolean;
  clickAction?: string;
  alignTitle?: string;
  alignCellsH?: string;
  alignCellsV?: string;
  mono?: boolean;
}

export interface TableConfig {
  tableHeight?: string;
  tableWidth?: string;
  sortKey?: string;
  sortASC?: boolean;
  preset?: string;
  presetConfig?: PresetConfig;
}

interface TableColors {
  border: string;
  font: string;
}

//SECTION----------------------------------------------------------------------------------------------------Empty Refs:

const emptyTableREF: TableData = {
  config: {
    tableHeight: 'auto',
    tableWidth: '50vw',
  },
  columns: [
    {
      keyName: 'icon',
      displayName: '',
      type: 'icon',
      width: '32px',
      colored: true,
    },
    {
      keyName: 'error',
      displayName: 'Information',
    },
  ],
  data: [
    {
      icon: 'featherAlertTriangle',
      error: 'Die Tabelle beinhaltet keine Daten bzw. Spalteninformationen!',
      ColorCode: '#ffcc00',
    },
  ],
};
export const emptyTable: TableData = structuredClone(emptyTableREF);

const defaultConfigREF: TableConfig = {
  tableHeight: '100%',
  tableWidth: '100%',
};
export const defaultConfig: TableConfig = structuredClone(defaultConfigREF);

const errorTableREF: TableData = {
  config: {
    tableHeight: 'auto',
    tableWidth: '50vw',
  },
  columns: [
    {
      keyName: 'icon',
      displayName: '',
      type: 'icon',
      width: '32px',
      colored: true,
    },
    {
      keyName: 'error',
      displayName: 'Information',
    },
  ],
  data: [
    {
      icon: 'featherAlertCircle',
      error: 'Die Tabelle hat ein fehlerhaftes JSON-Format und konnte deshalb nicht geladen werden!',
      ColorCode: '#f54b4c',
    },
  ],
};
export const errorTable: TableData = structuredClone(errorTableREF);

const defaultPresetConfigREF: PresetConfig = {
  drawBorders: true,
  drawHeader: true,
  drawFooter: false,
  drawZebra: true,
  drawTooltipsOnHeader: true,
  drawTooltipsOnFooter: false,
  colors: { border: 'accent', font: 'foreground' },
};
export const defaultPresetConfig: PresetConfig = structuredClone(defaultPresetConfigREF);

const emptyTableStateREF: TableState = {
  sortField: '',
  sortDirection: 'none',
  preset: 'default',
  presetConfig: defaultPresetConfig,
  data: [],
  displayedCols: [],
  columns: [],
  windowHeight: '',
  windowWidth: '',
};
export const emptyTableState: TableState = structuredClone(emptyTableStateREF);
