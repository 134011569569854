/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, computed, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GuestAppStore } from '../../stores/guest-app.store';
import { BookableItemComponent } from '../bookable-item/bookable-item.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-bookable-items-day',
  standalone: true,
  imports: [CommonModule, BookableItemComponent, TranslateModule],
  templateUrl: './bookable-items-day.component.html',
  host: { class: 'flex h-full flex-col gap-8  px-2 min-w-0 shrink-0 grow-0 basis-full' },
})
export class BookableItemsDayComponent {
  @Output()
  bookItemClicked = new EventEmitter<number>();

  day = input.required<Date>();

  #store = inject(GuestAppStore);

  readonly groupedBookables = computed(() => this.#store.groupedBookablesByDay(this.day()));
  readonly loading = this.#store.loadingBookables;

  onOpenDialog(id: number): void {
    this.bookItemClicked.emit(id);
  }
}
