import { Directive, ElementRef, inject } from '@angular/core';
import { ImageLoadedService } from '../services/image.loaded.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[forImageLoadingService]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(load)': 'onLoad()',
    '(error)': 'onError()',
  },
})
export class ImgLoadingDirective {
  #el = inject(ElementRef);
  #imageService = inject(ImageLoadedService);
  constructor() {
    this.#imageService.imageLoading(this.#el.nativeElement);
  }

  onLoad() {
    this.#imageService.imageLoadedOrError(this.#el.nativeElement);
  }

  onError() {
    this.#imageService.imageLoadedOrError(this.#el.nativeElement);
  }
}
