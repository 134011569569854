import { Observable } from 'rxjs';
import { BookingResponse, GetBookablesResponseRoot } from '../../models/models';

export abstract class BookableServiceAbstract {
  abstract getBookables(from: Date, until: Date): Observable<GetBookablesResponseRoot>;
  abstract booking(username: string, id: number): Observable<BookingResponse>;
  abstract bookingForGuest(
    givenname: string,
    surname: string,
    id: number,
    birthday: string,
    roomnumber: string,
    tel: string,
    email: string,
    from: string,
    to: string,
  ): Observable<BookingResponse>;
}
