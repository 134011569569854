/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { Consts, LoginCredentials, UserResponse } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';
import { AppointmentLinesComponent } from '../appointment-lines/appointment-lines.component';
import { BookableItemsComponent } from '../bookable-items/bookable-items.component';
import { DateSelectComponent } from '../date-select/date-select.component';
import { FilterOptionsComponent } from '../filter-options/filter-options.component';
import { GeneralInformationsComponent } from '../general-informations/general-informations.component';
import { HeaderLineComponent } from '../header-line/header-line.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    HeaderLineComponent,
    GeneralInformationsComponent,
    FilterOptionsComponent,
    AppointmentLinesComponent,
    BookableItemsComponent,
    HlmButtonDirective,
    TranslateModule,
    DateSelectComponent,
  ],
  template: `@if (userloaded()) {
    <guest-app-header-line></guest-app-header-line>
    @if (isEmployee() || showBookableAppointments()) {
      <guest-app-date-select [withEmblaCarousel]="showBookableAppointments()"></guest-app-date-select>
    }
    @if (showBookableAppointments()) {
      <guest-app-bookable-items></guest-app-bookable-items>
    } @else {
      <guest-app-general-informations></guest-app-general-informations>
      @if (!isEmployee()) {
        <guest-app-filter-options></guest-app-filter-options>
      }
      <guest-app-appointment-lines></guest-app-appointment-lines>
    }
    @if (!isEmployee() && canShowBookables() && !forceBookables()) {
      <div class="sticky -bottom-0.5 mt-auto">
        <div class="from-background h-2 bg-gradient-to-t to-transparent"></div>
        <div class="bg-background flex justify-end px-2 py-2">
          <button
            hlmBtn
            (click)="onSwitchAppointments()"
            class="@5xl:w-80 w-full">
            @if (showBookableAppointments()) {
              @if (isGuestLogin()) {
                {{ 'homepage.switchToCommonAppointments' | translate }}
              } @else {
                {{ 'homepage.switchToMyAppointments' | translate }}
              }
            } @else {
              {{ 'homepage.switchToBookableAppointments' | translate }}
            }
          </button>
        </div>
      </div>
    }
  } `,
  host: { class: '@container flex flex-col h-full' },
})
export class HomePageComponent {
  #store = inject(GuestAppStore);
  #route = inject(ActivatedRoute);
  #destroy = inject(DestroyRef);

  readonly userloaded = signal(false);
  readonly showBookableAppointments = computed(() => this.#store.showBookablePage());
  readonly canShowBookables = computed(() => this.#store.user().canshowbookables);
  readonly isEmployee = computed(() => this.#store.user().jwt.length && this.#store.user().isemployee);
  readonly isGuestLogin = computed(() => this.#store.user().jwt.length && this.#store.isGuestLogin());
  readonly forceBookables = computed(() => this.#store.forceBookables());

  constructor() {
    this.#route.data.pipe(takeUntilDestroyed(this.#destroy)).subscribe((data) => {
      if (data['guestlogin']) {
        const days = this.#route.snapshot.queryParams['days'] || 7;
        const user = Consts.openUserName + days.toString();
        const credentials: LoginCredentials = { username: user, password: '' };
        this.#store.login(credentials).subscribe((data: UserResponse) => {
          this.userloaded.set(true);
          if (!(data && data.user && data.user.jwt && data.user.arrival && data.user.departure)) {
            console.warn(data.message);
          } else {
            this.#store.setUser(data.user);
          }
        });
      } else {
        this.userloaded.set(true);
      }
    });
  }

  onSwitchAppointments(): void {
    this.#store.showBookables(!this.#store.showBookablePage());
    window.scrollTo(0, 0);
  }
}
