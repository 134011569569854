<div class="flex w-full pl-2">
  <ng-content></ng-content>
  <select
    name="option-select"
    [ngModel]="selectedOptionIndex"
    (ngModelChange)="onChange($event)"
    [ngStyle]="{ width: width }"
    class="group-hover:bg-accent group-hover:text-accent-foreground mr-1 w-full cursor-pointer bg-transparent px-1 outline-none">
    @for (option of _options; track option; let i = $index) {
      <option [value]="option.index">
        {{ option.label }}
      </option>
    }
  </select>
</div>
