/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HlmBadgeDirective } from '@spartan-ng/ui-badge-helm';
import { HlmSwitchComponent } from '@spartan-ng/ui-switch-helm';
import { getText } from '../../helper/get-text';
import { GuestAppStore } from '../../stores/guest-app.store';
import { DateSelectComponent } from '../date-select/date-select.component';

interface MappedGuestAppCategory {
  id: number;
  text: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, HlmSwitchComponent, HlmBadgeDirective, DateSelectComponent],
  selector: 'guest-app-filter-options',
  template: `@if (!isGuestLogin() && canShowAllAppointments()) {
      <label
        class="flex select-none"
        hlmLabel>
        <hlm-switch
          class="mr-2"
          [checked]="filterOptions().showCommonAppointments"
          (changed)="onShowCommonAppointments($event)" />
        {{ 'filteroptions.showCommonAppointments' | translate }}
      </label>
    }

    @if (!isGuestLogin()) {
      <label
        class="flex select-none"
        hlmLabel>
        <hlm-switch
          class="mr-2"
          [checked]="filterOptions().showOldAppointments"
          (changed)="onShowOldAppointments($event)" />
        {{ 'filteroptions.showOldAppointments' | translate }}
      </label>
    }

    @if (!hideCategories()) {
      <div class="elite-h-scrollbar flex w-full gap-2 py-4">
        @for (category of categories(); track category.id) {
          <div
            hlmBadge
            class="elite-text-small cursor-pointer select-none px-3 py-2"
            [ngClass]="filterOptions().filterCategories.includes(category.id) ? '' : '!bg-card !text-card-foreground'"
            (click)="onCategoryClick(category.id)">
            {{ category.text }}
          </div>
        }
      </div>
    } `,
  host: { class: 'flex flex-col gap-2 p-2' },
})
export class FilterOptionsComponent {
  #store = inject(GuestAppStore);

  filterOptions = computed(() => this.#store.filterOptions());
  isGuestLogin = computed(() => this.#store.isGuestLogin());
  canShowAllAppointments = computed(() => this.#store.user().canshowallappointments);
  hideCategories = computed(() => {
    return this.#store.user().hidecategories || this.categories().length <= 0;
  });
  categories = computed(() => {
    const result: MappedGuestAppCategory[] = [];
    this.#store.user().guestappcategories.forEach((element) => {
      if (this.#store.availableCategories()?.includes(element.id)) {
        result.push({
          id: element.id,
          text: getText(element.texts, this.#store.language()),
        });
      }
    });
    return result;
  });

  onShowCommonAppointments(event: boolean): void {
    this.#store.setShowCommonAppointments(event);
  }

  onShowOldAppointments(event: boolean): void {
    this.#store.setShowOldAppointments(event);
  }

  onCategoryClick(categoryid: number): void {
    const index = this.#store.filterOptions().filterCategories.indexOf(categoryid);
    if (index >= 0) {
      this.#store.setFilterCategories(
        this.#store.filterOptions().filterCategories.filter((category) => category !== categoryid),
      );
    } else {
      this.#store.setFilterCategories([...this.#store.filterOptions().filterCategories, categoryid]);
    }
  }
}
