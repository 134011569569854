import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { LanguageInfos } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HlmButtonDirective],
  selector: 'guest-app-language-button',
  template: ` <button
    variant="ghost"
    class="w-full justify-start"
    [class.justify-center]="!forPopup()"
    (click)="useLanguage(languageInfos().locale)"
    hlmBtn>
    <img
      class="mr-2 h-auto max-w-[25px] rounded-full"
      [src]="languageInfos().iconPath"
      [alt]="languageInfos().name" />
    {{ languageInfos().name }}
  </button>`,
})
export class LanguageButtonComponent {
  #store = inject(GuestAppStore);

  languageInfos = input<LanguageInfos>({ iconPath: '', locale: '', name: '' });
  forPopup = input(false);

  useLanguage(language: string): void {
    this.#store.setLanguage(language);
  }
}
