import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

@Injectable()
export class StyleService {
  #http = inject(HttpClient);

  addStylesheet(): void {
    const styleUrl = 'assets/customstyles.css';
    this.#http.get(styleUrl, { responseType: 'text' }).subscribe({
      next: () => {
        this.#appendStylesheet(styleUrl);
      },
      error: () => {
        console.warn('Stylesheet not found');
      },
    });
  }

  #appendStylesheet(styleUrl: string): void {
    const stylesheet = document.getElementById('client-theme');
    if (stylesheet) {
      stylesheet.remove();
    }
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleUrl;
    } else {
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = styleUrl;
      head.appendChild(style);
    }
  }
}
