/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: 'global-mock-badge',
  template: 'Mock',
  host: {
    class:
      'bg-warning select-none pointer-events-none text-warning-foreground elite-text-xs fixed left-0 top-0 flex h-14 w-14 -translate-x-[50%] -translate-y-[50%] -rotate-45 items-end justify-center text-center z-50',
  },
})
export class MockBadgeComponent {}
