import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, Output, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OptionItem, ToasterService } from '@shared/util-global';
import { ScreenService } from '@shared/util-screen';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import {
  HlmCardContentDirective,
  HlmCardDescriptionDirective,
  HlmCardDirective,
  HlmCardFooterDirective,
  HlmCardHeaderDirective,
  HlmCardTitleDirective,
} from '@spartan-ng/ui-card-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { HlmLabelDirective } from '@spartan-ng/ui-label-helm';
import { BrnTabsDirective } from '@spartan-ng/ui-tabs-brain';
import { HlmTabsContentDirective, HlmTabsListComponent, HlmTabsTriggerDirective } from '@spartan-ng/ui-tabs-helm';
import { formatISO } from 'date-fns';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { InputDateComponent } from '../input-date/input-date.component';
import { InputNumberComponent, NumberInputType } from '../input-number/input-number.component';
import { ListComponent } from '../list/list.component';
import { PopupComponent } from '../popup/popup.component';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';

type Gender = '' | 'male' | 'female' | 'diverse';
type CustomInputTypes = 'empty' | 'text' | 'number' | 'checkbox' | 'textarea' | 'date';

export interface AdditionalGuest {
  givenName: string;
  surname: string;
  birthday: string;
}

interface InputFieldConfig {
  maxLength?: number;
  required?: boolean;
}

interface CustomInput {
  name: string;
  label: string;
  type: CustomInputTypes;
  value?: string | number | boolean;
  required?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  numberType?: NumberInputType;
  hideButtons?: boolean;
}

export interface RegistrationForm {
  additionalGuests: AdditionalGuest[];
  birthday: string;
  city: string;
  country: string;
  gender: Gender;
  givenName: string;
  id: number;
  isMale: boolean;
  mailForCard: string;
  nation: string;
  occupation: string;
  passport: string;
  roomNumber: string;
  street: string;
  surname: string;
  title: string;
  zip: string;
  additionalGuestsList?: string[];
  checkedIn?: boolean;
  signature?: string;
  inputFieldsConfig?: Record<string, InputFieldConfig>;
  customInputs?: CustomInput[];
}
const emptyRegistrationFormREF: RegistrationForm = {
  additionalGuests: [],
  birthday: '',
  city: '',
  country: '',
  gender: '',
  givenName: '',
  id: -1,
  isMale: true,
  mailForCard: '',
  nation: '',
  occupation: '',
  passport: '',
  roomNumber: '',
  street: '',
  surname: '',
  title: '',
  zip: '',
};
export const emptyRegistrationForm: RegistrationForm = structuredClone(emptyRegistrationFormREF);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: 'global-registration-form',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HlmButtonDirective,
    HlmIconComponent,
    HlmInputDirective,
    HlmLabelDirective,
    BrnTabsDirective,
    HlmTabsListComponent,
    HlmTabsTriggerDirective,
    HlmTabsContentDirective,
    HlmCardContentDirective,
    HlmCardDescriptionDirective,
    HlmCardDirective,
    HlmCardFooterDirective,
    HlmCardHeaderDirective,
    HlmCardTitleDirective,
    SignaturePadComponent,
    InputDateComponent,
    RadioGroupComponent,
    ListComponent,
    PopupComponent,
    CheckboxComponent,
    InputNumberComponent,
  ],
  templateUrl: './registration-form.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'block p-1 w-full max-w-4xl',
  },
})
export class RegistrationFormComponent {
  public screenService = inject(ScreenService);
  private toaster = inject(ToasterService);
  private ngZone = inject(NgZone);

  private presetterCount = 0;
  public regForms: RegistrationForm[] = [];
  public formGroups: FormGroup[] = [];
  @Input() set registrationFormsPreset(inputRegForms: RegistrationForm[]) {
    if (this.presetterCount++ < 1) {
      this.regForms = structuredClone(inputRegForms);
      for (const regForm of this.regForms) {
        if (!regForm.inputFieldsConfig) {
          regForm.inputFieldsConfig = {};
        }
        regForm.inputFieldsConfig = {
          ...regForm.inputFieldsConfig,
          ...this.inputFieldsConfig,
        };
        if (regForm.birthday === '0001-01-01') {
          regForm.birthday = '';
        }
        if (!regForm.signature?.includes('path')) {
          regForm.signature = '';
        }
        regForm.additionalGuestsList = [];
        for (const additionalGuest of regForm.additionalGuests) {
          regForm.additionalGuestsList.push(
            additionalGuest.givenName + ' ' + additionalGuest.surname + ' ' + additionalGuest.birthday,
          );
        }
        const formControls: Record<string, FormControl> = {
          birthday: new FormControl<string>(regForm.birthday),
          city: new FormControl<string>(regForm.city),
          country: new FormControl<string>(regForm.country),
          gender: new FormControl<Gender>(regForm.gender),
          givenName: new FormControl<string>(regForm.givenName),
          isMale: new FormControl<boolean>(regForm.isMale),
          mailForCard: new FormControl<string>(regForm.mailForCard),
          nation: new FormControl<string>(regForm.nation),
          occupation: new FormControl<string>(regForm.occupation),
          passport: new FormControl<string>(regForm.passport),
          signature: new FormControl<string>(regForm.signature),
          street: new FormControl<string>(regForm.street),
          surname: new FormControl<string>(regForm.surname),
          title: new FormControl<string>(regForm.title),
          zip: new FormControl<string>(regForm.zip),
        };
        for (const customInput of regForm.customInputs ?? []) {
          formControls[customInput.name] = new FormControl<string | number | boolean>(customInput.value ?? '');
        }
        this.formGroups.push(new FormGroup(formControls));
      }
    }
  }
  @Input() requiredFields: string[] = [];
  @Input() inputFieldsConfig: Record<string, InputFieldConfig> = {};
  @Output() public postRegForm = new EventEmitter<RegistrationForm[]>();

  public readonly defaultMaxLength = 999;
  public editGuestStringS = signal('');
  public showAddGuestPopupS = signal(false);
  public newAdditionalGuestGivenNameInput = '';
  public newAdditionalGuestSurnameInput = '';
  public newAdditionalGuestBirthday = '';
  public canCloseAddGuestPopupS = signal(true);
  public activateAddGuestPopupS = signal(true);
  public actualRegForm: RegistrationForm | undefined;
  public isMaleOptions: OptionItem[] = [
    {
      label: 'männlich',
      value: true,
    },
    {
      label: 'weiblich',
      value: false,
    },
  ];
  public genderOptions: OptionItem[] = [
    {
      label: 'männlich',
      value: 'male',
    },
    {
      label: 'weiblich',
      value: 'female',
    },
    {
      label: 'divers',
      value: 'diverse',
    },
    {
      label: 'keine Angabe',
      value: 'n.a.',
    },
  ];

  public onAddGuest(regForm: RegistrationForm): void {
    this.actualRegForm = regForm;
    this.editGuestStringS.set('');
    this.newAdditionalGuestGivenNameInput = '';
    this.newAdditionalGuestSurnameInput = '';
    this.newAdditionalGuestBirthday = '';
    this.showAddGuestPopupS.set(true);
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        document.getElementById('addGuestGivenNameInput')?.focus();
      });
    });
  }

  public onAddNewOrEditGuest(): void {
    if (this.actualRegForm) {
      if (this.editGuestStringS() !== '') {
        this.actualRegForm.additionalGuestsList = this.actualRegForm.additionalGuestsList?.filter((guest) => {
          return guest !== this.editGuestStringS();
        });
        this.actualRegForm.additionalGuests = this.actualRegForm.additionalGuests.filter((guest) => {
          return this.actualRegForm?.additionalGuestsList?.includes(
            guest.givenName + ' ' + guest.surname + ' ' + guest.birthday,
          );
        });
      }
      if (
        this.newAdditionalGuestGivenNameInput === '' ||
        this.newAdditionalGuestSurnameInput === '' ||
        this.newAdditionalGuestBirthday === ''
      ) {
        this.toaster.insertToast({
          type: 'info',
          text:
            'Bitte alle Felder ausfüllen' +
            this.newAdditionalGuestGivenNameInput +
            this.newAdditionalGuestSurnameInput +
            this.newAdditionalGuestBirthday,
        });
        return;
      }
      const newAdditionalGuestsAsString =
        this.newAdditionalGuestGivenNameInput +
        ' ' +
        this.newAdditionalGuestSurnameInput +
        ' ' +
        this.newAdditionalGuestBirthday;
      if (this.actualRegForm.additionalGuestsList?.includes(newAdditionalGuestsAsString)) {
        this.toaster.insertToast({ type: 'info', text: 'Dieser Gast ist bereits in der Liste enthalten.' });
        return;
      }
      this.actualRegForm.additionalGuests.push({
        givenName: this.newAdditionalGuestGivenNameInput,
        surname: this.newAdditionalGuestSurnameInput,
        birthday: this.newAdditionalGuestBirthday,
      });
      this.actualRegForm.additionalGuestsList?.push(
        this.newAdditionalGuestGivenNameInput +
          ' ' +
          this.newAdditionalGuestSurnameInput +
          ' ' +
          this.newAdditionalGuestBirthday,
      );
      this.newAdditionalGuestGivenNameInput = '';
      this.newAdditionalGuestSurnameInput = '';
      this.newAdditionalGuestBirthday = '';
      this.showAddGuestPopupS.set(false);
      this.actualRegForm = undefined;
    }
  }

  public onDeleteGuest(regForm: RegistrationForm): void {
    regForm.additionalGuests = regForm.additionalGuests.filter((guest) => {
      return regForm.additionalGuestsList?.includes(guest.givenName + ' ' + guest.surname + ' ' + guest.birthday);
    });
  }

  public onEditGuest(regForm: RegistrationForm, event: string): void {
    this.actualRegForm = regForm;
    this.editGuestStringS.set(event);
    this.newAdditionalGuestGivenNameInput = event.split(' ')[0];
    this.newAdditionalGuestSurnameInput = event.split(' ')[1];
    this.newAdditionalGuestBirthday = event.split(' ')[2];
    this.showAddGuestPopupS.set(true);
  }

  public onCloseAddGuestPopup(): void {
    this.showAddGuestPopupS.set(false);
  }

  public onNewSignature(regForm: RegistrationForm, svgString: string): void {
    if (!svgString) {
      regForm.signature = undefined;
    } else {
      regForm.signature = svgString;
    }
  }

  public setNewAdditionalGuestDate($event: Date): void {
    this.newAdditionalGuestBirthday = formatISO($event, { representation: 'date' });
  }

  public onAddGuestDateInputPopupChange(isDateInputVisible: boolean): void {
    this.canCloseAddGuestPopupS.set(!isDateInputVisible);
    this.activateAddGuestPopupS.set(!isDateInputVisible);
  }

  public isBooleanAndTrue(value: unknown): boolean {
    return typeof value === 'boolean' && value === true;
  }

  public onPostRegform(): void {
    for (let index = 0; index < this.formGroups.length; index++) {
      if (this.formGroups[index].status !== 'VALID') {
        const regForm = this.regForms[index];
        let errorText = 'Bitte füllen Sie alle Felder des Meldescheins ' + (index + 1) + ' aus!';
        if (regForm.inputFieldsConfig?.['givenName']?.required && this.formGroups[index].value.givenName === '') {
          errorText = 'Bitte tragen Sie einen Vornamen im Meldeschein ' + (index + 1) + ' ein!';
        } else if (regForm.inputFieldsConfig?.['surname']?.required && this.formGroups[index].value.surname === '') {
          errorText = 'Bitte tragen Sie einen Nachnamen im Meldeschein ' + (index + 1) + ' ein!';
        } else if (regForm.inputFieldsConfig?.['birthday']?.required && this.formGroups[index].value.birthday === '') {
          errorText = 'Bitte geben Sie ein Geburtsdatum im Meldeschein ' + (index + 1) + ' an!';
        } else if (regForm.inputFieldsConfig?.['passport']?.required && this.formGroups[index].value.passport === '') {
          errorText = 'Bitte tragen Sie die Reisepassnummer im Meldeschein ' + (index + 1) + ' ein!';
        } else if (regForm.inputFieldsConfig?.['street']?.required && this.formGroups[index].value.street === '') {
          errorText = 'Bitte tragen Sie eine Straße im Meldeschein ' + (index + 1) + ' ein!';
        } else if (regForm.inputFieldsConfig?.['zip']?.required && this.formGroups[index].value.zip === '') {
          errorText = 'Bitte geben Sie eine Postleitzahl im Meldeschein ' + (index + 1) + ' an!';
        } else if (regForm.inputFieldsConfig?.['city']?.required && this.formGroups[index].value.city === '') {
          errorText = 'Bitte geben Sie eine Stadt im Meldeschein ' + (index + 1) + ' ein!';
        } else if (regForm.inputFieldsConfig?.['gender']?.required && this.formGroups[index].value.gender === '') {
          errorText = 'Bitte geben Sie das Geschlecht im Meldeschein ' + (index + 1) + ' an!';
        } else if (regForm.inputFieldsConfig?.['isMale']?.required && this.formGroups[index].value.isMale === '') {
          errorText = 'Bitte geben Sie das Geschlecht im Meldeschein ' + (index + 1) + ' an!';
        } else if (regForm.inputFieldsConfig?.['country']?.required && this.formGroups[index].value.country === '') {
          errorText = 'Bitte tragen Sie ein Land im Meldeschein ' + (index + 1) + ' ein!';
        } else if (
          regForm.inputFieldsConfig?.['occupation']?.required &&
          this.formGroups[index].value.occupation === ''
        ) {
          errorText = 'Bitte tragen Sie den Beruf im Meldeschein ' + (index + 1) + ' ein!';
        } else if (
          regForm.inputFieldsConfig?.['mailForCard']?.required &&
          this.formGroups[index].value.mailForCard === ''
        ) {
          errorText = 'Bitte geben Sie eine Mailadresse für die Gästekarte im Meldeschein ' + (index + 1) + ' an!';
        } else if (regForm.inputFieldsConfig?.['title']?.required && this.formGroups[index].value.title === '') {
          errorText = 'Bitte geben Sie einen Titel im Meldeschein ' + (index + 1) + ' an!';
        } else if (regForm.inputFieldsConfig?.['nation']?.required && this.formGroups[index].value.nation === '') {
          errorText = 'Bitte tragen Sie die Nationalität im Meldeschein ' + (index + 1) + ' ein!';
        } else if (
          regForm.inputFieldsConfig?.['signature']?.required &&
          this.formGroups[index].value.signature === ''
        ) {
          errorText = 'Bitte unterschreiben Sie den Meldeschein ' + (index + 1) + '.';
        } else {
          const notRequireAbleFields: CustomInputTypes[] = ['empty', 'checkbox'];
          for (const customInput of regForm.customInputs ?? []) {
            if (
              customInput.required &&
              this.formGroups[index].value[customInput.name]?.toString() === '' &&
              !notRequireAbleFields.includes(customInput.type)
            ) {
              errorText = 'Bitte füllen Sie das Feld ' + customInput.label + ' im Meldeschein ' + (index + 1) + ' aus!';
            }
          }
        }
        this.toaster.insertToast({ text: errorText, type: 'error' });
        for (const formGroup of this.formGroups) {
          this.markAllAsTouched(formGroup);
        }
        return;
      }
    }
    for (let index = 0; index < this.formGroups.length; index++) {
      this.regForms[index].birthday = this.formGroups[index].value.birthday;
      this.regForms[index].city = this.formGroups[index].value.city;
      this.regForms[index].country = this.formGroups[index].value.country;
      this.regForms[index].gender = this.formGroups[index].value.gender;
      this.regForms[index].givenName = this.formGroups[index].value.givenName;
      this.regForms[index].isMale = this.formGroups[index].value.isMale;
      this.regForms[index].mailForCard = this.formGroups[index].value.mailForCard;
      this.regForms[index].nation = this.formGroups[index].value.nation;
      this.regForms[index].occupation = this.formGroups[index].value.occupation;
      this.regForms[index].passport = this.formGroups[index].value.passport;
      this.regForms[index].signature = this.formGroups[index].value.signature;
      this.regForms[index].street = this.formGroups[index].value.street;
      this.regForms[index].surname = this.formGroups[index].value.surname;
      this.regForms[index].title = this.formGroups[index].value.title;
      this.regForms[index].zip = this.formGroups[index].value.zip;
      for (const customInput of this.regForms[index]?.customInputs ?? []) {
        customInput.value = this.formGroups[index].value[customInput.name];
      }
    }
    for (const regForm of this.regForms) {
      regForm.additionalGuestsList = undefined;
      regForm.checkedIn = undefined;
      regForm.inputFieldsConfig = undefined;
    }
    this.postRegForm.emit(structuredClone(this.regForms));
  }

  public markAllAsTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markAllAsTouched(control);
      }
    });
  }
}
