/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  addDays,
  intervalToDuration,
  isFriday,
  isMonday,
  isSaturday,
  isThursday,
  isTuesday,
  isWednesday,
} from 'date-fns';
import { Observable, delay, of } from 'rxjs';
import { BookingResponse, GetBookablesResponseRoot, Texts } from '../../models/models';
import { BookableServiceAbstract } from '../abstract/bookable-abstract.service';
// import mockResponseBookables from './mockResponseBookables.json';

@Injectable()
export class BookableServiceMock extends BookableServiceAbstract {
  getBookables(from: Date, until: Date): Observable<GetBookablesResponseRoot> {
    // return of(mockResponseBookables);
    // return of(mockResponseBookables).pipe(delay(Math.random() * 500));
    //ACHTUNG! Bei den Generierten Bookables funktioniert 'alreadyBooked' nicht, da die IDs nicht eindeutig sind!
    const response: GetBookablesResponseRoot = {
      get_bookables_response: {
        status: 'success',
        message: '',
        data: [],
      },
    };
    const description: Texts[] = [
      {
        lang: 'de',
        text: 'Lorem ipsum dolor sit amet, \nnächste Zeile \nconsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',
      },
      {
        lang: 'en',
        text: 'Lorem ipsum dolor sit amet, \nnächste Zeile \nconsetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',
      },
    ];
    const days = (intervalToDuration({ start: from, end: until }).days ?? 0) + 1;
    let generatedID = 100;
    console.log('MOCK');
    for (let index = 0; index < days; index++) {
      const day = addDays(new Date(from), index);
      if (isMonday(day)) {
        for (let i = 0; i < 3; i++) {
          response.get_bookables_response.data.push({
            id: generatedID++,
            title: [
              { lang: 'de', text: 'Montag' },
              { lang: 'en', text: 'Monday' },
            ],
            description,
            fromTime: new Date(day.setHours(12 + i, 0, 0, 0)).toISOString(),
            untilTime: new Date(day.setHours(12 + i, 30, 0, 0)).toISOString(),
            imageUrl: 'https://rigirutschn.de/wp-content/uploads/RigiRutschn-Sauna-01.jpg',
            price: 50.5,
            serviceCode: 'Monday_1',
          });
        }
        for (let i = 0; i < 2; i++) {
          response.get_bookables_response.data.push({
            id: generatedID++,
            title: [
              { lang: 'de', text: 'Montag' },
              { lang: 'en', text: 'Monday' },
            ],
            description,
            fromTime: new Date(day.setHours(11, 0, 0, 0)).toISOString(),
            untilTime: new Date(day.setHours(11, 30 + i * 30, 0, 0)).toISOString(),
            imageUrl: 'https://thermen.at/itrfile/_1_/8d3ef3d6f6096397d89b4501664c0fb1.jpg',
            price: 60.05 + i * 20,
            serviceCode: 'Monday_2',
          });
        }
      } else if (isTuesday(day)) {
        response.get_bookables_response.data.push({
          id: generatedID++,
          title: [
            { lang: 'de', text: 'Dienstag' },
            { lang: 'en', text: 'Tuesday' },
          ],
          description,
          fromTime: new Date(day.setHours(12, 0, 0, 0)).toISOString(),
          untilTime: new Date(day.setHours(12, 30, 0, 0)).toISOString(),
          imageUrl: 'https://thermen.at/itrfile/_1_/8d3ef3d6f6096397d89b4501664c0fb1.jpg',
          price: 60.05,
          serviceCode: 'Tuesday_1',
        });
      } else if (isWednesday(day)) {
        for (let i = 0; i < 2; i++) {
          response.get_bookables_response.data.push({
            id: generatedID++,
            title: [
              { lang: 'de', text: 'Mittwoch' },
              { lang: 'en', text: 'Wednesday' },
            ],
            description,
            fromTime: new Date(day.setHours(9 + i, 0, 0, 0)).toISOString(),
            untilTime: new Date(day.setHours(10 + i, 30, 0, 0)).toISOString(),
            imageUrl: 'https://wellspa-portal.de/wp-content/uploads/2016/02/6_Massageraum-1030x687.jpg',
            price: 35,
            serviceCode: 'Wednesday_1',
          });
        }
      } else if (isThursday(day)) {
        response.get_bookables_response.data.push({
          id: generatedID++,
          title: [
            { lang: 'de', text: 'Donnerstag' },
            { lang: 'en', text: 'Thursday' },
          ],
          description,
          fromTime: new Date(day.setHours(16, 0, 0, 0)).toISOString(),
          untilTime: new Date(day.setHours(16, 50, 0, 0)).toISOString(),
          imageUrl:
            'https://www.dollenberg.de/(cms)/media/resize/size=1024x768%2Cscale=crop%2Cinterlace=1%2Cquality=70/2356903',
          price: 150.0,
          serviceCode: 'Thursday_1',
        });
        response.get_bookables_response.data.push({
          id: generatedID++,
          title: [
            { lang: 'de', text: 'Donnerstag' },
            { lang: 'en', text: 'Thursday' },
          ],
          description,
          fromTime: new Date(day.setHours(15, 0, 0, 0)).toISOString(),
          untilTime: new Date(day.setHours(15, 30, 0, 0)).toISOString(),
          imageUrl: 'https://thermen.at/itrfile/_1_/8d3ef3d6f6096397d89b4501664c0fb1.jpg',
          price: 60.05,
          serviceCode: 'Thursday_2',
        });
      } else if (isFriday(day)) {
        for (let i = 0; i < 5; i++) {
          response.get_bookables_response.data.push({
            id: generatedID++,
            title: [
              { lang: 'de', text: 'Freitag' },
              { lang: 'en', text: 'Friday' },
            ],
            description,
            fromTime: new Date(day.setHours(6 + i * 2, 0, 0, 0)).toISOString(),
            untilTime: new Date(day.setHours(6 + i * 2, 90, 0, 0)).toISOString(),
            imageUrl:
              'https://image.urlaubspiraten.de/640/image/upload/v1603285311/mediavault_images/x6jscp1jon6nr7jsvgpt.jpg',
            price: 88,
            serviceCode: 'Friday_1',
          });
        }
      } else if (isSaturday(day)) {
        response.get_bookables_response.data.push({
          id: generatedID++,
          title: [
            { lang: 'de', text: 'Samstag' },
            { lang: 'en', text: 'Saturday' },
          ],
          description,
          fromTime: new Date(day.setHours(17, 0, 0, 0)).toISOString(),
          untilTime: new Date(day.setHours(19, 15, 0, 0)).toISOString(),
          imageUrl: 'https://www.tsvlindau1850.de/sites/default/files/images/IMG_1706.JPG',
          price: 45.44,
          serviceCode: 'Saturday_1',
        });
        response.get_bookables_response.data.push({
          id: generatedID++,
          title: [
            { lang: 'de', text: 'Samstag' },
            { lang: 'en', text: 'Saturday' },
          ],
          description,
          fromTime: new Date(day.setHours(10, 0, 0, 0)).toISOString(),
          untilTime: new Date(day.setHours(11, 15, 0, 0)).toISOString(),
          imageUrl: 'https://thermen.at/itrfile/_1_/8d3ef3d6f6096397d89b4501664c0fb1.jpg',
          price: 60.05,
          serviceCode: 'Saturday_2',
        });
        response.get_bookables_response.data.push({
          id: generatedID++,
          title: [
            { lang: 'de', text: 'Samstag' },
            { lang: 'en', text: 'Saturday' },
          ],
          description,
          fromTime: new Date(day.setHours(8, 0, 0, 0)).toISOString(),
          untilTime: new Date(day.setHours(8, 90, 0, 0)).toISOString(),
          imageUrl:
            'https://image.urlaubspiraten.de/640/image/upload/v1603285311/mediavault_images/x6jscp1jon6nr7jsvgpt.jpg',
          price: 88,
          serviceCode: 'Saturday_3',
        });
      }
    }
    return of(response).pipe(delay(Math.random() * 100));
  }

  booking(username: string, id: number): Observable<BookingResponse> {
    if (username === 'lucas') {
      return of({
        booking_response: {
          status: 'success',
          responseText: [
            {
              lang: 'de',
              text: 'Der Termin wurde erfolgreich gebucht.',
            },
            {
              lang: 'en',
              text: '',
            },
          ],
        },
      }).pipe(delay(Math.random() * 100));
    } else if (username === 'benni') {
      return of({
        booking_response: {
          status: 'fail',
          message: '',
          errortexts: [
            {
              lang: 'de',
              text: 'Termin konnte nicht gebucht werden!',
            },
            {
              lang: 'en',
              text: 'Appointment could not be booked!',
            },
          ],
        },
      }).pipe(delay(Math.random() * 100));
    } else {
      return of({
        booking_response: {
          status: 'error',
          message: 'Der Server konnte nicht erreicht werden!',
          errortexts: [],
        },
      }).pipe(delay(Math.random() * 100));
    }
  }

  bookingForGuest(
    givenname: string,
    surname: string,
    id: number,
    birthday: string,
    roomnumber: string,
    tel: string,
    email: string,
    from: string,
    to: string,
  ): Observable<BookingResponse> {
    const request = { givenname, surname, id, birthday, roomnumber, tel, email, from, to };
    console.log(request);
    if (givenname === 'Lucas') {
      return of({
        booking_response: {
          status: 'success',
          message: '',
          success: true,
          errortexts: [],
        },
      }).pipe(delay(Math.random() * 100));
    } else if (givenname === 'Benni') {
      return of({
        booking_response: {
          status: 'fail',
          message: '',
          success: false,
          errortexts: [
            {
              lang: 'de',
              text: 'Termin konnte nicht gebucht werden!',
            },
            {
              lang: 'en',
              text: 'Appointment could not be booked!',
            },
          ],
        },
      }).pipe(delay(Math.random() * 100));
    } else {
      return of({
        booking_response: {
          status: 'error',
          message: 'Der Server konnte nicht erreicht werden!',
          success: false,
          errortexts: [],
        },
      }).pipe(delay(Math.random() * 100));
    }
  }
}
