<div class="border p-2" [ngClass]="border() ? 'border-muted rounded-md' : 'border-transparent'">
  @if (!monthPick() && selectedMonthNumber() !== -1) {
  <div class="elite-text-small flex justify-end px-2 pb-0.5">
    {{ months[selectedMonthNumber()].label }}
  </div>
  }
  @if (!yearPick()) {
  <div class="elite-text-small flex justify-end px-2 pb-0.5">
    {{ selectedYearNumber }}
  </div>
  }
  <div class="flex flex-col items-center pb-3">
    @if (yearPick()) {
    <div class="flex items-center justify-center gap-1">
      <button hlmBtn size="icon" variant="outline" (click)="prevYear()">
        <hlm-icon size="base" name="featherChevronLeft" />
      </button>
      <input hlmInput max="9999" maxlength="4" minlength="0" [(ngModel)]="selectedYearNumber"
        (ngModelChange)="onDateChange()" class="w-[8.25rem]" type="number" />
      <button hlmBtn size="icon" variant="outline" (click)="nextYear()">
        <hlm-icon size="base" name="featherChevronRight" />
      </button>
    </div>
    }
    @if (monthPick()) {
    <div class="flex justify-end gap-1 py-2">
      <button hlmBtn size="icon" variant="outline" (click)="prevMonth()">
        <hlm-icon size="base" name="featherChevronLeft" />
      </button>
      <global-option-select [options]="months" [selectedOptionIndex]="selectedMonth.value"
        [(selectedOption)]="selectedMonth" (change)="onDateChange()">
      </global-option-select>
      <button hlmBtn size="icon" variant="outline" (click)="nextMonth()">
        <hlm-icon size="base" name="featherChevronRight" />
      </button>
    </div>
    }
  </div>
  <div class="elite-text-small mx-auto grid select-none grid-cols-7 pb-1 pl-1 text-center">
    @for (weekday of weekdays; track weekday) {
    <div>{{ weekday }}</div>
    }
  </div>
  <div (mouseleave)="expectedDateS.set(undefined)">
    @for (week of weeksS(); track week) {
    <div class="elite-text-tiny grid grid-cols-7 gap-y-1 text-center">
      @for (day of week.days; track day) {
      @if (rangePicker()) {
      <button
        class="hover:border-primary m-0.5 w-full skew-x-[20deg] cursor-pointer border border-transparent py-1 duration-200 hover:rounded-r-md"
        [ngClass]="{
                'bg-secondary rounded-md': day.isToday && !day.isInRange,
                'bg-primary text-primary-foreground scale-x-105': day.isInRange,
                'hover:rounded-md': !day.isInRange && !day.isSelectedStart && !day.isSelectedEnd,
                'text-muted': (!day.isSameMonth || (day.isSelected && !day.isInRange)) && !day.isInRange,
                '!bg-primary text-background font-medium': day.isSelectedStart || day.isSelectedEnd
              }" [class.rounded-l-md]="day.isSelectedStart" [class.rounded-r-md]="day.isSelectedEnd"
        (click)="selectStartDate(day.date)" (mouseenter)="expectedDateFunction(day.date)">
        <div class="-skew-x-[20deg]">
          {{ day.date | formatDate: 'd' }}
        </div>
      </button>
      } @else {
      <button
        class="hover:border-primary m-0.5 w-full skew-x-[20deg] cursor-pointer rounded-md border border-transparent py-1 duration-200"
        [ngClass]="{
                'bg-primary text-background hover:bg-primary font-medium': day.isSelected,
                'bg-secondary': day.isToday && !day.isSelected,
                'text-muted': !day.isSameMonth && !day.isSelected
              }" (click)="selectDate(day.date)">
        <div class="-skew-x-[20deg]">
          {{ day.date | formatDate: 'd' }}
        </div>
      </button>
      }
      }
    </div>
    }
    @if (!rangePicker() && pickToday()) {
    <button hlmBtn variant="secondary" class="w-full" (click)="onToday()">
      <hlm-icon size="base" class="mr-2" name="tablerCalendarDue" />
      Heute
    </button>
    }
  </div>
</div>