import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { GuestAppStore } from '../stores/guest-app.store';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  #router = inject(Router);
  #store = inject(GuestAppStore);

  async isUserLoggedIn() {
    let timeout = 5000;
    while (!this.#store.initialized()) {
      await new Promise((f) => setTimeout(f, 100));
      if (--timeout <= 0) {
        break;
      }
    }
    if (this.#store.user().jwt.length) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.#router.navigate(['/login']);
    return false;
  }
}

export const isUserLoggedIn = (permissionService = inject(PermissionsService)) => permissionService.isUserLoggedIn();
