import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormatDatePipe } from '@shared/util-global';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { getText } from '../../helper/get-text';
import { AppointmentModel, TypeId } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-appointment-line',
  standalone: true,
  imports: [CommonModule, TranslateModule, HlmIconComponent, HlmButtonDirective, FormatDatePipe],
  templateUrl: './appointment-line.component.html',
})
export class AppointmentLineComponent {
  #store = inject(GuestAppStore);
  #datePipe = inject(FormatDatePipe);

  appointment = input.required<AppointmentModel>();

  title = computed(() => getText(this.appointment().title, this.#store.language() ?? 'x'));
  description = computed(() => getText(this.appointment().description, this.#store.language() ?? ''));
  comment = computed(() => getText(this.appointment().comment ?? [], this.#store.language() ?? ''));
  locale = computed(() => this.#store.locale());
  language = computed(() => this.#store.language());
  isFavorite = computed(() => this.#store.user().favorites.includes(this.appointment().id));
  isEmployee = computed(() => this.#store.user().isemployee);
  isGuestLogin = computed(() => this.#store.isGuestLogin());
  fromUntilText = computed(() => {
    let result = '';
    if (this.appointment().typeId === TypeId.dayInfo) {
      return result;
    }
    let dateFormat = 'hh:mm a';
    if (this.#store.language() === 'de') {
      dateFormat = 'HH:mm';
    }
    result = this.#datePipe.transform(this.appointment().fromTime, dateFormat, this.#store.locale());
    if (this.appointment().fromTime.valueOf() !== this.appointment().untilTime.valueOf()) {
      result += this.#datePipe.transform(this.appointment().untilTime, ' - ' + dateFormat, this.#store.locale());
    }
    return result;
  });

  isCommon = computed(() => this.appointment().kind === 'common');
  isPersonal = computed(() => this.appointment().kind === 'personal');

  onAddFavorite(): void {
    this.#store.addFavoriteForUser(this.appointment());
  }

  onRemoveFavorite(): void {
    this.#store.removeFavoriteForUser(this.appointment());
  }
}
