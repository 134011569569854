interface Theme {
  cornerRadius: number;
  lightTheme: Record<string, ThemeColor>;
  darkTheme: Record<string, ThemeColor>;
}
// const emptyThemeREF: Theme = {
//   cornerRadius: 0,
//   lightTheme: {},
//   darkTheme: {},
// };
// const emptyTheme: Theme = structuredClone(emptyThemeREF);

export interface ThemeColor {
  hue: number;
  saturation: number;
  lightness: number;
}
const emptyThemeColorREF: ThemeColor = {
  hue: 0,
  saturation: 0,
  lightness: 0,
};
export const emptyThemeColor: ThemeColor = structuredClone(emptyThemeColorREF);

export interface ThemeEditorState extends Theme {
  copiedColor: string;
  simpleMode: boolean;
  simpleLightTheme: Record<string, ThemeColor>;
  simpleDarkTheme: Record<string, ThemeColor>;
}
const emptyThemeEditorStateREF: ThemeEditorState = {
  copiedColor: '',
  cornerRadius: 0,
  lightTheme: {},
  darkTheme: {},
  simpleMode: false,
  simpleLightTheme: {},
  simpleDarkTheme: {},
};
export const emptyThemeEditorState: ThemeEditorState = structuredClone(emptyThemeEditorStateREF);

export interface Snapshot {
  id: number;
  snapshot: Theme;
}
