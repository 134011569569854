<div class="elite-text-strong bg-primary text-primary-foreground w-full rounded-t-lg py-4 text-center">
  {{ title() }}
</div>
<div class="@lg:grid-cols-2 grid">
  <div class="flex">
    <img forImageLoadingService class="mx-auto w-full max-w-5xl object-cover" [src]="imageUrl()" />
  </div>
  <div class="@lg:p-2 @lg:justify-center flex h-full w-full flex-col" [class.@lg:items-center]="description() === ''">
    @if (description() !== '') {
    <div class="grow whitespace-pre-line px-2 pt-2">{{ description() }}</div>
    }
    <div class="grid grid-cols-2 items-center gap-x-4 gap-y-2 p-2" style="grid-template-columns: min-content auto">
      <div class="font-bold">{{ 'bookableItem.duration' | translate }}:</div>
      <div>{{ durationMinutes() }}</div>
      @if (fromTimes().length === 1) {
      <div class="pt-0.5 font-bold">{{ 'bookableItem.date' | translate }}:</div>
      {{ bookableItemGroup()[0].fromTime | formatDate: dateFormat() : locale() }}
      {{ 'bookableItem.time' | translate }}
      } @else {
      <div class="place-self-start pt-0.5 font-bold">{{ 'bookableItem.dates' | translate }}:</div>
      <brn-radio-group hlm>
        @for (time of fromTimes(); track $index) {
        <brn-radio hlm (change)="onSelectTime($event, $index)" [value]="time + $index">
          <hlm-radio-indicator indicator />
          {{ time | formatDate: dateFormat() : locale() }}
          {{ 'bookableItem.time' | translate }}
        </brn-radio>
        }
      </brn-radio-group>
      }
    </div>
  </div>
</div>
<div class="elite-text-strong bg-primary text-primary-foreground w-full cursor-pointer rounded-b-lg py-4 text-center"
  (click)="onBookItem()">
  {{ 'bookableItem.booknow' | translate }} EUR {{ price() | number: '1.2-2' }}
</div>