<div class="@container mx-2">
  <div class="border-border flex min-h-14 w-full gap-2 rounded-lg border-2 p-1 shadow">
    <div class="@xl:flex-row @xl:items-center flex w-full flex-col gap-x-4">
      <div class="flex w-fit min-w-36 items-center">
        <div class="@xl:w-full w-fit whitespace-nowrap text-center">
          {{ fromUntilText() }}
        </div>
      </div>
      <div>
        <div class="elite-text-bold whitespace-pre-line">{{ title() }}</div>
        <div class="whitespace-pre-line">{{ description() }}</div>
        <div class="elite-text-tiny text-muted-foreground whitespace-pre-line">
          {{ comment() }}
        </div>
      </div>
    </div>
    @if (!isGuestLogin()) {
      <div class="group flex items-center pr-2">
        @if (isPersonal()) {
          <hlm-icon [name]="appointment().isBookable ? 'tablerWorld' : 'featherUser'"></hlm-icon>
          <div
            class="border-border animate-fadeIn bg-background absolute right-0 z-50 mt-3 hidden w-fit rounded-lg border p-2 shadow group-hover:block">
            @if (!isEmployee() || !appointment().clientName) {
              {{ 'appointmentline.personalAppointment' | translate }}
            }
            @if (isEmployee() && appointment().clientName) {
              <div class="whitespace-nowrap">
                {{ appointment().clientName }}
                <div>
                  @if (language() === 'de') {
                    {{ 'appointmentline.arrival' | translate }}:
                    {{ appointment().clientArrival | formatDate: 'HH:mm' : locale() }}
                  } @else {
                    {{ 'appointmentline.arrival' | translate }}:
                    {{ appointment().clientArrival | formatDate: 'hh:mm a' : locale() }}
                  }
                </div>
                <div>
                  @if (language() === 'de') {
                    {{ 'appointmentline.departure' | translate }}:
                    {{ appointment().clientDeparture | formatDate: 'HH:mm' : locale() }}
                  } @else {
                    {{ 'appointmentline.departure' | translate }}:
                    {{ appointment().clientDeparture | formatDate: 'hh:mm a' : locale() }}
                  }
                </div>
              </div>
            }
          </div>
        }
        @if (!isEmployee() && isCommon()) {
          <hlm-icon
            [name]="isFavorite() ? 'tablerStarFilled' : 'tablerStar'"
            [class.text-primary]="isFavorite()"
            class="cursor-pointer"
            (click)="isFavorite() ? onRemoveFavorite() : onAddFavorite()"></hlm-icon>
        }
      </div>
    }
  </div>
</div>
