import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormatDatePipe, GlobalUISettingsService, imageExists } from '@shared/util-global';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { isSameDay } from 'date-fns';
import { GuestAppStore } from '../../stores/guest-app.store';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, LanguageSelectorComponent, HeaderMenuComponent, HlmIconComponent],
  selector: 'guest-app-header-line',
  template: `
    <div class="flex w-full">
      <img
        class="max-h-28 max-w-full cursor-pointer overflow-hidden p-1 pl-2"
        [src]="logoPath()"
        alt="company logo"
        (click)="onShowAppointments()" />
      <div class="flex min-w-fit grow items-center justify-end gap-2 self-center pr-2">
        <guest-app-language-selector></guest-app-language-selector>
        @if (darkModeAvailable()) {
          <hlm-icon
            size="lg"
            class="cursor-pointer"
            [name]="uiSettings.isDarkModeS() ? 'tablerSunHigh' : 'tablerMoon'"
            (click)="uiSettings.changeMode()" />
        }
        <guest-app-header-menu></guest-app-header-menu>
      </div>
    </div>
    @if (!hideDateLine()) {
      <div class="bg-primary text-primary-foreground mx-2 mb-2 rounded-lg py-1 pl-3">
        <div class="elite-text-large">
          {{ fullName() }}
        </div>
        @if (!isEmployee()) {
          {{ dateLine() }}
          {{ roomInfo() }}
        }
      </div>
    }
  `,
})
export class HeaderLineComponent {
  #router = inject(Router);
  #translate = inject(TranslateService);
  #store = inject(GuestAppStore);
  #datePipe = inject(FormatDatePipe);
  uiSettings = inject(GlobalUISettingsService);

  dateLine = computed(() => {
    let dateFormat = 'MMM dd,y';
    if (this.#store.language() === 'de') {
      dateFormat = 'dd.MM.y';
    }
    let dateLine = this.#datePipe.transform(this.arrival(), dateFormat, this.#store.locale());
    if (!isSameDay(this.arrival(), this.departure())) {
      dateLine += ' - ' + this.#datePipe.transform(this.departure(), dateFormat, this.#store.locale());
    }
    return dateLine;
  });
  roomInfo = computed(() => {
    this.#store.language();
    if (
      !this.#store.user().roomnumber ||
      this.#store.user().roomnumber === null ||
      this.#store.user().roomnumber === ''
    ) {
      return '';
    } else {
      const room = this.#translate.instant('headerline.room');
      return '(' + room + ': ' + this.#store.user().roomnumber + ')';
    }
  });
  fullName = computed(() => this.#store.user().name);
  isEmployee = computed(() => this.#store.user().isemployee);
  arrival = computed(() => new Date(this.#store.user().arrival));
  departure = computed(() => new Date(this.#store.user().departure));
  hideDateLine = computed(() => this.#store.user().hidedateline);
  darkModeAvailable = computed(() => this.#store.user().darkmodeavail);
  logoPath = computed(() => (this.uiSettings.isDarkModeS() ? this.#logoDarkUrl() : this.#logoLightUrl()));
  #logoDarkUrl = signal('assets/logo-darkmode.png');
  #logoLightUrl = signal('assets/logo-lightmode.png');

  constructor() {
    imageExists(this.#logoLightUrl()).then((exists) => {
      if (!exists) {
        this.#logoLightUrl.set('assets/fallback_logo-lightmode.png');
      }
    });
    imageExists(this.#logoDarkUrl()).then((exists) => {
      if (!exists) {
        this.#logoDarkUrl.set('assets/fallback_logo-darkmode.png');
      }
    });
  }

  onShowAppointments(): void {
    this.#router.navigate(['/']);
  }
}
