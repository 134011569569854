import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OptionItem, emptyOptionItem } from '@shared/util-global';

interface OptionItemWithIndex extends OptionItem {
  index: number;
}

@Component({
  selector: 'global-option-select',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './option-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionSelectComponent {
  //TODO Multiselection?
  //TODO Focus Ring and TAB Behavior...
  //TODO option sorting?
  public _options: OptionItemWithIndex[] = [];
  @Input({ required: true }) set options(options: OptionItem[]) {
    const newOptions: OptionItemWithIndex[] = [];
    for (let index = 0; index < options.length; index++) {
      newOptions.push({ ...options[index], index });
    }
    this._options = newOptions;
  }
  @Input() selectedOption: OptionItem = emptyOptionItem;
  @Input() selectedOptionIndex = 0;
  @Input() width = 'fit';
  @Output() selectedOptionChange = new EventEmitter<OptionItem>();
  @Output() selectedOptionIndexChange = new EventEmitter<number>();

  @HostBinding('class') public class =
    'flex items-center bg-transparent group border border-input cursor-pointer focus:ring disabled:opacity-50 disabled:pointer-events-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-ring h-10 hover:bg-accent hover:text-accent-foreground justify-center ring-offset-background rounded-md';

  onChange(index: any) {
    this.selectedOptionChange.emit({ label: this._options[index].label, value: this._options[index].value });
    this.selectedOptionIndexChange.emit(index);
  }
}
