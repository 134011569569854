import { Injectable } from '@angular/core';
import { QueryObserverResult, injectQuery } from '@ngneat/query';
import { Result } from '@ngneat/query/lib/types';
import { areIntervalsOverlapping } from 'date-fns';
import { of } from 'rxjs';
import { AppointmentModelApiEventMapper, AppointmentModelApiPersonalMapper } from '../../helper/appointment.mapper';
import { getDefaultAppointmentInfo } from '../../helper/mapper';
import {
  AppointmentInfo,
  AppointmentModel,
  Consts,
  EventData,
  GetAppointmentsResponseRoot,
  ModelBase,
  Personal,
  TypeId,
  User,
} from '../../models/models';
import { AppointmentServiceAbstract } from '../abstract/appointment-abstract.service';
import mockResponseAppointments from './mockResponseAppointments.json';

@Injectable()
export class AppointmentServiceMock extends AppointmentServiceAbstract {
  #query = injectQuery();
  #eventMapper: AppointmentModelApiEventMapper = new AppointmentModelApiEventMapper();
  #personalMapper: AppointmentModelApiPersonalMapper = new AppointmentModelApiPersonalMapper();

  #mapresponse(response: GetAppointmentsResponseRoot): AppointmentInfo {
    const result = getDefaultAppointmentInfo();

    if (response.get_appointments_response.status !== Consts.successStatus) {
      return result;
    }
    response.get_appointments_response.data.events.forEach((data: EventData) => {
      result.appointments.push(this.#eventMapper.mapFrom(data));
    });
    response.get_appointments_response.data.personal.forEach((data: Personal) => {
      result.appointments.push(this.#personalMapper.mapFrom(data));
    });
    result.availableEventsFromDate = new Date(response.get_appointments_response.data.availableeventsfromdate);
    result.availableEventsToDate = new Date(response.get_appointments_response.data.availableeventstodate);
    return result;
  }

  getAppointments(
    user: User,
    selectedDate: Date,
    onError: () => void,
  ): Result<QueryObserverResult<AppointmentInfo, Error>> {
    return this.#query({
      queryKey: ['appointments', user.username, user.isemployee, selectedDate] as const,
      queryFn: () => {
        const emptyInfo = getDefaultAppointmentInfo();
        //TODO fix this...
        // if (user.isemployee) {
        //   const filtered = this.#appointments.filter((value) => isSameDay(value.fromTime, selectedDate));
        //   // console.log('returning Employee appointments, filtered only for today', filtered, this.#appointments);
        //   return of({
        //     ...emptyInfo,
        //     appointments: filtered,
        //   });
        // }
        if (user.username.startsWith('{[($OpenUser$)]}')) {
          console.log('returning guestLogin appointments');
          if (Math.random() < 0.3) {
            console.warn('Simulated error in mocked guestLogin returning empty appointments!');
            onError();
            return of(emptyInfo);
          }
          return of({
            ...emptyInfo,
            appointments: this.#appointments.filter((value) => value.kind !== 'personal'),
          });
        }

        if (!user || !user.name) return of(emptyInfo);
        if (user.username === 'stefan') {
          const stiefelAppInfo: AppointmentInfo = {
            ...emptyInfo,
            appointments: this.stiefelAppointments,
          };
          return of(stiefelAppInfo);
        }
        if (user.username === 'lucas') {
          const lucasAppInfo: AppointmentInfo = {
            ...emptyInfo,
            appointments: this.#mapresponse(mockResponseAppointments).appointments,
          };
          return of(lucasAppInfo);
        }
        if (user.username === 'max') {
          const maxAppInfo: AppointmentInfo = {
            ...emptyInfo,
            appointments: this.#mapresponse(mockResponseAppointments).appointments,
          };
          return of(maxAppInfo);
        }
        if (user.username === '') {
          return of(emptyInfo);
        }
        const AI: AppointmentInfo = {
          ...emptyInfo,
          appointments: this.#appointments,
        };
        return of(AI);
      },
    });
  }

  public overlapAppointments(first: ModelBase, second: ModelBase): boolean {
    return areIntervalsOverlapping(
      { start: first.fromTime, end: first.untilTime },
      { start: second.fromTime, end: second.untilTime },
      { inclusive: true },
    );
  }

  public overlapWithPrivateAppointment(appointment: ModelBase): boolean {
    let result = false;
    this.#appointments.every((element) => {
      if (element.kind === 'personal' && this.overlapAppointments(appointment, element)) {
        result = true;
        return false;
      }
      return true;
    });
    return result;
  }

  stiefelAppointments: AppointmentModel[] = [
    {
      id: 1,
      fromTime: new Date(new Date().setHours(7, 0)).toISOString(),
      untilTime: new Date(new Date().setHours(7, 45)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'Geführter Morgenspaziergang',
        },
        {
          lang: 'en',
          text: 'Good Morning Walk',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'Treffpunkt in der Eingangshalle',
        },
        {
          lang: 'en',
          text: 'Meet at the Lobby',
        },
      ],
      comment: [
        {
          lang: 'de',
          text: 'Eigene Trinkflasche mitnehmen!',
        },
        {
          lang: 'en',
          text: 'Bring your own water bottle!',
        },
      ],
      kind: 'personal' as const,
      typeId: TypeId.normal,
      guestappcategoryid: -1,
      clientName: 'Erwin Huber',
      clientArrival: new Date('January 10, 2023'),
      clientDeparture: new Date('January 15, 2023'),
      isBookable: true,
    },
  ];

  #appointments = [
    {
      id: 1,
      fromTime: new Date(new Date().setHours(7, 0)).toISOString(),
      untilTime: new Date(new Date().setHours(7, 45)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'Geführter Morgenspaziergang',
        },
        {
          lang: 'en',
          text: 'Good Morning Walk',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'Treffpunkt in der Eingangshalle',
        },
        {
          lang: 'en',
          text: 'Meet at the Lobby',
        },
      ],
      kind: 'personal' as const,
      typeId: TypeId.normal,
      guestappcategoryid: -1,
    },
    {
      id: 99,
      fromTime: new Date(new Date().setHours(10, 0)).toISOString(),
      untilTime: new Date(new Date().setHours(10, 45)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'gesundes Frühstück',
        },
        {
          lang: 'en',
          text: 'healthy breakfast',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'in der Café Lounge',
        },
        {
          lang: 'en',
          text: 'at the Café Lounge',
        },
      ],
      kind: 'common' as const,
      typeId: TypeId.normal,
      guestappcategoryid: 1,
      isOnlineBookable: true,
    },
    {
      id: 98,
      fromTime: new Date(new Date().setHours(10, 0)).toISOString(),
      untilTime: new Date(new Date().setHours(12, 45)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'gesundes langes ausgedehntes Frühstück zum Wohlfühlen.',
        },
        {
          lang: 'en',
          text: 'healthy long extended breakfast to feel good.',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'in der Café Lounge',
        },
        {
          lang: 'en',
          text: 'at the Café Lounge',
        },
      ],
      kind: 'common' as const,
      typeId: TypeId.normal,
      guestappcategoryid: 2,
      isOnlineBookable: true,
    },
    {
      id: 2,
      fromTime: new Date(new Date().setHours(7, 30)).toISOString(),
      untilTime: new Date(new Date().setHours(11, 30)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'Möglich: Kneippen (00:20)',
        },
        {
          lang: 'en',
          text: 'Possible: Kneippen (00:20)',
        },
      ],
      description: [
        {
          lang: 'de',
          text: '',
        },
        {
          lang: 'en',
          text: '',
        },
      ],
      comment: [],
      kind: 'common' as const,
      typeId: TypeId.normal,
      guestappcategoryid: 1,
      clientName: 'Renate Weis',
      clientArrival: new Date('January 10, 2023'),
      clientDeparture: new Date('January 15, 2023'),
    },
    {
      id: 3,
      fromTime: new Date(new Date().setHours(11, 30)).toISOString(),
      untilTime: new Date(new Date().setHours(12, 30)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'Feldenkrais - Ganzheitliches Selbstheilen und Prävention auf Deutsch',
        },
        {
          lang: 'en',
          text: 'Feldenkrais - Self Healing',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'Wir bitten um Anmeldung an der medizinischen Rezeption (max. 8 Teilnehmer)',
        },
        {
          lang: 'en',
          text: 'Please book on the medical Reception (max. 8 persons)',
        },
      ],
      comment: [],
      kind: 'common' as const,
      typeId: TypeId.normal,
      guestappcategoryid: 1,
      clientName: 'Günther Schnitzer',
      clientArrival: new Date('January 10, 2023'),
      clientDeparture: new Date('January 15, 2023'),
    },
    {
      id: 4,
      fromTime: new Date(new Date().setHours(18, 5)).toISOString(),
      untilTime: new Date(new Date().setHours(18, 45)).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'Teilmassage',
        },
        {
          lang: 'en',
          text: 'Massage',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'Massageraum 2 - Musterfrau Gabi',
        },
        {
          lang: 'en',
          text: 'Massage Room 2 - Musterfrau Gabi',
        },
      ],
      comment: [
        {
          lang: 'de',
          text: 'Bitte angenehme Kleidung tragen.',
        },
        {
          lang: 'en',
          text: 'Please wear comfortable clothes.',
        },
      ],
      kind: 'personal' as const,
      typeId: TypeId.normal,
      guestappcategoryid: -1,
      clientName: 'Maximilian Muster',
      clientArrival: new Date('January 10, 2023'),
      clientDeparture: new Date('January 15, 2023'),
    },
    {
      id: 5,
      fromTime: new Date(new Date().setHours(18, 5) + 1000 * 60 * 60 * 24).toISOString(),
      untilTime: new Date(new Date().setHours(18, 45) + 1000 * 60 * 60 * 24).toISOString(),
      title: [
        {
          lang: 'de',
          text: 'Rückenmassage',
        },
        {
          lang: 'en',
          text: 'Back massage',
        },
      ],
      description: [
        {
          lang: 'de',
          text: 'Massageraum 2 - Mustermann Manni',
        },
        {
          lang: 'en',
          text: 'Massage Room 2 - Mustermann Manni',
        },
      ],
      comment: [
        {
          lang: 'de',
          text: 'Bitte angenehme Kleidung tragen.',
        },
        {
          lang: 'en',
          text: 'Please wear comfortable clothes.',
        },
      ],
      kind: 'common' as const,
      typeId: TypeId.normal,
      guestappcategoryid: 2,
      clientName: 'Friedrich Reich',
      clientArrival: new Date('January 10, 2023'),
      clientDeparture: new Date('January 15, 2023'),
    },
  ];
}
