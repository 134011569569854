import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, inject, signal } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BrnAlertDialogComponent,
  BrnAlertDialogContentDirective,
  BrnAlertDialogTriggerDirective,
} from '@spartan-ng/ui-alertdialog-brain';
import {
  HlmAlertDialogActionButtonDirective,
  HlmAlertDialogCancelButtonDirective,
  HlmAlertDialogComponent,
  HlmAlertDialogContentComponent,
  HlmAlertDialogDescriptionDirective,
  HlmAlertDialogFooterComponent,
  HlmAlertDialogHeaderComponent,
  HlmAlertDialogOverlayDirective,
  HlmAlertDialogTitleDirective,
} from '@spartan-ng/ui-alertdialog-helm';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { take } from 'rxjs';
import { ChangePinParams, Consts, emptyUser } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';
import { HeaderLineComponent } from '../header-line/header-line.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    HeaderLineComponent,
    HlmInputDirective,
    HlmButtonDirective,
    BrnAlertDialogTriggerDirective,
    BrnAlertDialogContentDirective,
    HlmAlertDialogComponent,
    HlmAlertDialogOverlayDirective,
    HlmAlertDialogHeaderComponent,
    HlmAlertDialogFooterComponent,
    HlmAlertDialogTitleDirective,
    HlmAlertDialogDescriptionDirective,
    HlmAlertDialogCancelButtonDirective,
    HlmAlertDialogActionButtonDirective,
    HlmAlertDialogContentComponent,
  ],
  selector: 'guest-app-settings-page',
  templateUrl: './settings-page.component.html',
})
export class SettingsPageComponent {
  #router = inject(Router);
  #store = inject(GuestAppStore);

  @ViewChild('lockDialogId') lockDialog!: BrnAlertDialogComponent;

  #pinMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.newpin === control.value.newpinConfirm ? null : { mismatch: true };
  };

  public changePinForm = new FormGroup(
    {
      oldpin: new FormControl(),
      newpin: new FormControl(),
      newpinConfirm: new FormControl(),
    },
    this.#pinMatchValidator,
  );

  passwordsMismatch = signal(false);
  pinchangeError = signal('');
  lockAccountError = signal('');

  onSubmit(): void {
    this.clearErrors();
    if (this.changePinForm.invalid) {
      const errors = this.changePinForm.errors ?? {};
      this.passwordsMismatch.set(errors['mismatch']);
      return;
    }
    this.passwordsMismatch.set(false);
    const params: ChangePinParams = {
      oldpin: this.changePinForm.controls.oldpin.value,
      newpin: this.changePinForm.controls.newpin.value,
      user: this.#store.user(),
    };
    this.#store
      .changePin(params)
      .pipe(take(1))
      .subscribe((data) => {
        console.log(data);
        if (data?.status === Consts.successStatus) {
          this.#store.setUser(data.user ?? emptyUser);
          this.pinchangeError.set('');
          this.onCancel();
          return;
        }
        if (data?.status === Consts.errorStatus) {
          this.#handleErrorState(data.message);
          return;
        }
        this.pinchangeError.set(data.message);
      });
  }

  #handleErrorState(errorMessage: string): void {
    this.#store.setErrorMessage(errorMessage);
    this.#store.setUser(emptyUser);
    this.#router.navigate(['/login']);
  }

  onOpenLockUserDialog(): void {
    this.clearErrors();
    this.lockDialog.open();
  }

  onCloseLockUserDialog(ctx: { close: () => void }): void {
    ctx.close();
  }

  onLockUser(): void {
    this.#store
      .lockUser(this.#store.user())
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.status === Consts.failStatus) {
          this.lockAccountError.set(data.message);
        } else {
          this.#handleErrorState(data.message);
        }
      });
  }

  clearErrors(): void {
    this.passwordsMismatch.set(false);
    this.pinchangeError.set('');
    this.lockAccountError.set('');
  }

  onCancel(): void {
    window.history.back();
  }
}
