<div
  [class.pb-2]="!showBar"
  class="bg-dim shadow-background group rounded-md px-2 pt-2 shadow-lg"
  [ngClass]="onDestroyingS() ? 'animate-moveOutTop' : ''"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
  (click)="onClick()">
  <div class="flex max-w-[95dvw] items-center gap-x-2 overflow-hidden sm:max-w-[50dvw]">
    <hlm-icon
      [size]="iconSize"
      [strokeWidth]="iconStroke"
      [color]="iconColor | transformTailwindColor"
      [name]="icon" />
    <div [innerHTML]="text"></div>
    <div class="invisible cursor-pointer self-start group-hover:visible">
      <hlm-icon
        (click)="onClose()"
        size="sm"
        class="text-foreground"
        name="featherX" />
    </div>
  </div>
  @if (showBar) {
    <progress
      [value]="timeout - counterS()"
      [max]="timeout"
      class="toast-bar w-full"></progress>
  }
</div>
