import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BookableModel, BookingResponse, GetBookablesResponseRoot } from '../../models/models';

@Injectable()
export class BookableServiceProduction {
  public appointments: BookableModel[] = [];
  #http = inject(HttpClient);

  readonly #url = './api/bookables';
  readonly #bookingUrl = './api/booking';
  readonly #bookingGuestUrl = './api/booking-guest';

  getBookables(from: Date, until: Date): Observable<GetBookablesResponseRoot> {
    const request = {
      get_bookables: {
        data: {
          from,
          until,
        },
      },
    };
    return this.#http.post<GetBookablesResponseRoot>(this.#url, request);
  }

  booking(username: string, id: number): Observable<BookingResponse> {
    const request = { booking: { username, id } };
    return this.#http.post<BookingResponse>(this.#bookingUrl, request);
  }

  bookingForGuest(
    givenname: string,
    surname: string,
    id: number,
    birthday: string,
    roomnumber: string,
    tel: string,
    email: string,
    from: string,
    to: string,
  ): Observable<BookingResponse> {
    const request = { booking: { givenname, surname, id, birthday, roomnumber, tel, email, from, to } };
    return this.#http.post<BookingResponse>(this.#bookingGuestUrl, request);
  }
}
