import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Locale, format } from 'date-fns';
import { deAT } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  transform(date: Date | string | undefined, form: string, locale?: Locale): string {
    if (date === undefined || date === null || date === '') {
      return '';
    }
    return format(new Date(date), form ?? 'PPPP', { locale: locale ?? deAT });
  }
}
