import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  GlobalUISettingsService,
  LocalStorageService,
  MachineInfoService,
  OptionItem,
  ToasterService,
  TransformTailwindColor,
} from '@shared/util-global';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { InputNumberComponent } from '../input-number/input-number.component';
import { OptionSelectComponent } from '../option-select/option-select.component';
import { TooltipDirective } from '../tooltip/tooltip.directive';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CheckboxComponent,
    InputNumberComponent,
    HlmButtonDirective,
    TooltipDirective,
    FormsModule,
    OptionSelectComponent,
    HlmIconComponent,
    TransformTailwindColor,
  ],
  selector: 'global-local-settings',
  templateUrl: './local-settings.component.html',
})
export class LocalSettingsComponent {
  @Output() public whenResetting = new EventEmitter();

  private localStorage = inject(LocalStorageService);
  private toaster = inject(ToasterService);

  public selectedModeIndex = 0;
  public selectedAnimationIndex = 0;

  constructor(
    public globalsUI: GlobalUISettingsService,
    public machine: MachineInfoService,
  ) {
    this.selectedAnimationIndex = this.globalsUI.animations.findIndex(
      (option) => option.value === this.globalsUI.SelectedAnimation.value,
    );
    this.selectedModeIndex = this.globalsUI.mode.findIndex(
      (option) => option.value === this.globalsUI.SelectedMode.value,
    );
  }

  public onSelectMode(event: OptionItem): void {
    this.globalsUI.SelectedMode =
      this.globalsUI.mode.find((option) => option.value === event.value) ?? this.globalsUI.mode[0];
  }

  public onSelectAnimation(event: OptionItem): void {
    this.globalsUI.SelectedAnimation =
      this.globalsUI.animations.find((option) => option.value === event.value) ?? this.globalsUI.animations[0];
  }

  public onResetSettings(): void {
    this.whenResetting.emit();
    this.globalsUI.resetSettings();
  }

  public onClearStorage(): void {
    const token = this.localStorage.getItem('id-token');
    this.localStorage.clearStorage();
    this.localStorage.setItem('id-token', token);
    this.toaster.insertToast({ text: 'Lokaler Speicher wurde gelöscht!', type: 'success' });
  }
}
