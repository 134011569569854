import { AppointmentModel, EventData, Personal, TypeId } from '../models/models';
import { Mapper } from './mapper';

export class AppointmentModelApiEventMapper implements Mapper<EventData, AppointmentModel> {
  mapFrom(param: EventData): AppointmentModel {
    return {
      description: param.descriptiontexts,
      comment: param.commenttexts,
      fromTime: new Date(param.startdatetime).toISOString(),
      untilTime: new Date(param.enddatetime).toISOString(),
      id: param.id,
      kind: 'common',
      title: param.titletexts,
      typeId: param.typeid,
      guestappcategoryid: param.guestappcategoryid,
      clientName: param.clientname,
      clientArrival: new Date(param.clientarrival ?? ''),
      clientDeparture: new Date(param.clientdeparture ?? ''),
      isBookable: param.isbookable,
    };
  }
}

export class AppointmentModelApiPersonalMapper implements Mapper<Personal, AppointmentModel> {
  mapFrom(param: Personal): AppointmentModel {
    return {
      description: param.ressourcetexts ?? [],
      comment: param.commenttexts,
      fromTime: new Date(param.startdatetime).toISOString(),
      untilTime: new Date(param.enddatetime).toISOString(),
      id: param.id,
      kind: 'personal',
      title: param.servicetexts,
      typeId: TypeId.normal,
      guestappcategoryid: -1,
      clientName: param.clientname,
      clientArrival: new Date(param.clientarrival ?? ''),
      clientDeparture: new Date(param.clientdeparture ?? ''),
      isBookable: param.isbookable,
    };
  }
}
