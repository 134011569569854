import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormatDatePipe, ToasterService } from '@shared/util-global';
import { BrnRadioChange, BrnRadioComponent, BrnRadioGroupComponent } from '@spartan-ng/ui-radiogroup-brain';
import { HlmRadioDirective, HlmRadioGroupDirective, HlmRadioIndicatorComponent } from '@spartan-ng/ui-radiogroup-helm';
import { formatDuration, intervalToDuration } from 'date-fns';
import { ImgLoadingDirective } from '../../directives/image-loaded.directive';
import { getText } from '../../helper/get-text';
import { BookableModel } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-bookable-item',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormatDatePipe,
    HlmRadioIndicatorComponent,
    HlmRadioDirective,
    HlmRadioGroupDirective,
    BrnRadioComponent,
    BrnRadioGroupComponent,
    ImgLoadingDirective,
  ],
  templateUrl: './bookable-item.component.html',
})
export class BookableItemComponent {
  #store = inject(GuestAppStore);
  #toaster = inject(ToasterService);
  #translate = inject(TranslateService);
  #selectedTime = signal('');
  #selectedIndex = signal(0);

  bookableItemGroup = input.required<BookableModel[]>();
  @Output() openDialog = new EventEmitter<number>();

  constructor() {
    effect(() => {
      this.bookableItemGroup();
      untracked(() => {
        this.#selectedTime.set('');
        this.#selectedIndex.set(0);
      });
    });
  }

  readonly #safeSelectedIndex = computed(() => {
    if (this.bookableItemGroup().length < this.#selectedIndex()) {
      return 0;
    }
    return this.#selectedIndex();
  });
  readonly title = computed(() => {
    return getText(this.bookableItemGroup()[this.#safeSelectedIndex()].title, this.#store.language());
  });
  readonly description = computed(() =>
    getText(this.bookableItemGroup()[this.#safeSelectedIndex()].description, this.#store.language()),
  );
  readonly price = computed(() => this.bookableItemGroup()[this.#safeSelectedIndex()].price);
  readonly imageUrl = computed(() => this.bookableItemGroup()[this.#safeSelectedIndex()].imageUrl);
  readonly locale = computed(() => this.#store.locale());
  readonly isGuestLogin = computed(() => this.#store.isGuestLogin());
  readonly durationMinutes = computed(() =>
    formatDuration(
      intervalToDuration({
        start: this.bookableItemGroup()[this.#safeSelectedIndex()].fromTime,
        end: this.bookableItemGroup()[this.#safeSelectedIndex()].untilTime,
      }),
      { format: ['hours', 'minutes'], locale: this.locale() },
    ),
  );
  readonly fromTimes = computed(() => this.bookableItemGroup().map((item) => item.fromTime));
  readonly language = computed(() => this.#store.language());
  readonly dateFormat = computed(() => {
    if (this.#store.language() === 'de') {
      return 'HH:mm';
    } else {
      return 'hh:mm a';
    }
  });

  onBookItem(): void {
    if (this.bookableItemGroup().length === 1) {
      this.#selectedTime.set(this.bookableItemGroup()[0].fromTime);
    }
    if (this.#selectedTime() === '') {
      this.#toaster.insertToast({ text: this.#translate.instant('bookableItem.selectOption'), type: 'info' });
      return;
    }
    this.openDialog.emit(this.bookableItemGroup()[this.#selectedIndex()].id);
  }

  onSelectTime(event: BrnRadioChange, index: number): void {
    this.#selectedTime.set(event.value);
    this.#selectedIndex.set(index);
  }
}
