import { AppointmentInfo } from '../models/models';

export interface Mapper<I, O> {
  mapFrom(param: I): O;
}

function getDefaultFromDate() {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return date;
}

function getDefaultToDate(): Date {
  const date = new Date();
  date.setDate(date.getDate() + 28);
  return date;
}

export function getDefaultAppointmentInfo(): AppointmentInfo {
  return {
    appointments: [],
    availableEventsFromDate: getDefaultFromDate(),
    availableEventsToDate: getDefaultToDate(),
  };
}
