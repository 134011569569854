import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { FormatDatePipe } from '@shared/util-global';
import { GuestAppStore } from '../../stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-dateline',
  standalone: true,
  imports: [FormatDatePipe],
  template: ` <div class="bg-primary text-primary-foreground elite-text-strong w-full rounded-lg p-2 py-3">
    {{ appointmentDate() | formatDate: 'EEEE, dd.MM.y' : locale() }}
  </div>`,
})
export class DatelineComponent {
  #store = inject(GuestAppStore);
  locale = computed(() => this.#store.locale());
  appointmentDate = input<Date | string>(new Date());
}
