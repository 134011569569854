//Info needed for Jest
if (!window.structuredClone) {
  window.structuredClone = function structuredClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  };
}

export interface OptionItem {
  label: string;
  value: any;
}

const emptyOptionItemREF: OptionItem = {
  label: '',
  value: '',
};
export const emptyOptionItem: OptionItem = structuredClone(emptyOptionItemREF);
