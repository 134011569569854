/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalUISettingsService, ToasterService, imageExists } from '@shared/util-global';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { take } from 'rxjs/operators';
import { UserResponse } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';
import { LanguageButtonsComponent } from '../language-buttons/language-buttons.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    LanguageButtonsComponent,
    HlmButtonDirective,
    HlmInputDirective,
  ],
  selector: 'guest-app-login-page',
  template: `<div class="@sm:w-4/5 @lg:w-3/5 flex w-full max-w-lg flex-col items-center justify-center gap-4">
    <guest-app-language-buttons></guest-app-language-buttons>
    <img
      class="h-auto max-h-[50vh] w-1/2 object-contain p-2 pt-4"
      [src]="logoPath()"
      alt="company logo big" />
    <div class="@xl:text-xxxxl @sm:text-xxxl text-xxl w-full whitespace-nowrap pb-4 text-center font-bold">
      {{ 'login.yourAppointments' | translate }}
    </div>
    <form
      class="flex w-full flex-col gap-4 px-2"
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()">
      <input
        hlmInput
        type="text"
        required="true"
        autofocus="true"
        autocomplete="username"
        formControlName="username"
        placeholder="E-Mail" />
      <input
        hlmInput
        type="password"
        required="true"
        autocomplete="current-password"
        formControlName="password"
        placeholder="PIN" />
      @if (errormessage().length) {
        <div class="text-destructive mb-4 w-full text-center">
          {{ errormessage() }}
        </div>
      }
      <button
        hlmBtn
        class="w-full"
        name="login">
        LOGIN
      </button>
    </form>
    @if (isCookiesDisabled()) {
      <div class="border-input border px-8 py-4">
        <div class="elite-text-strong pb-2 text-center">{{ 'common.notice' | translate }}</div>
        <div class="elite-text-small">
          {{ 'login.cookiesBlocked' | translate }}
        </div>
      </div>
    }
  </div> `,
  host: { class: '@container flex flex-col items-center h-full pt-8' },
})
export class LoginPageComponent {
  #store = inject(GuestAppStore);
  #uiSettings = inject(GlobalUISettingsService);
  #toaster = inject(ToasterService);
  #translator = inject(TranslateService);

  loginForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
  });
  errormessage = computed(() => this.#store.errorMessage());
  logoPath = computed(() => (this.#uiSettings.isDarkModeS() ? this.#logoDarkUrl() : this.#logoLightUrl()));
  #logoDarkUrl = signal('assets/logo-big-darkmode.png');
  #logoLightUrl = signal('assets/logo-big-lightmode.png');

  constructor() {
    imageExists(this.#logoLightUrl()).then((exists) => {
      if (!exists) {
        this.#logoLightUrl.set('assets/fallback_logo-big-lightmode.png');
      }
    });
    imageExists(this.#logoDarkUrl()).then((exists) => {
      if (!exists) {
        this.#logoDarkUrl.set('assets/fallback_logo-big-darkmode.png');
      }
    });
  }

  onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.#store
      .login({ username: this.loginForm.controls.username.value, password: this.loginForm.controls.password.value })
      .pipe(take(1))
      .subscribe({
        next: (data: UserResponse) => {
          if (!(data && data.user && data.user.jwt && data.user.arrival && data.user.departure)) {
            this.#store.setErrorMessage(data.message);
          } else {
            this.#store.showBookables(false);
            this.#store.setUser(data.user);
          }
        },
        error: () => {
          this.#toaster.insertToast({
            text: this.#translator.instant('login.cantReachServer'),
            type: 'error',
          });
        },
      });
  }

  isCookiesDisabled(): boolean {
    return !navigator.cookieEnabled;
  }
}
