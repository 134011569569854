import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getText } from '../../helper/get-text';
import {
  BooleanResponse,
  ChangeFavoritesResponse,
  ChangeFavoritesResponseRoot,
  ChangePasswordResponseRoot,
  ChangePinParams,
  Consts,
  LockUserResponseRoot,
  LoginCredentials,
  LoginResponseRoot,
  User,
  UserResponse,
} from '../../models/models';
import { UserServiceAbstract } from '../abstract/user-abstract.service';

@Injectable()
export class UserServiceProduction extends UserServiceAbstract {
  readonly url = './api/login';
  readonly refreshUrl = './api/refreshUser';
  readonly changePasswordUrl = './api/changepassword';
  readonly lockuserUrl = './api/lockuser';
  readonly changeFavoritesUrl = './api/changefavorites';

  #http = inject(HttpClient);

  constructor() {
    super();
  }

  loginUser(credentials: LoginCredentials, language: string): Observable<UserResponse> {
    const request = {
      login: {
        username: credentials.username,
        password: credentials.password,
      },
    };
    return this.#http.post<LoginResponseRoot>(this.url, request).pipe(
      map((resp: LoginResponseRoot): UserResponse => {
        const login = resp.login_response;
        let msg = login.message;
        let u: User | null = login.data;
        if (login.status === Consts.failStatus) {
          if (login.data.errortexts) {
            msg = getText(login.data.errortexts, language);
            u = null;
          }
        }
        return { status: login.status, message: msg, user: u };
      }),
    );
  }

  refreshUser(jwt: string, language: string): Observable<UserResponse> {
    const request = {
      data: jwt,
    };
    return this.#http.post<LoginResponseRoot>(this.refreshUrl, request).pipe(
      map((resp: LoginResponseRoot) => {
        const login = resp.login_response;
        let msg = login.message;
        let u: User | null = login.data;
        if (login.status === Consts.failStatus) {
          if (login.data.errortexts) {
            msg = getText(login.data.errortexts, language);
            u = null;
          }
        }

        return { status: login.status, message: msg, user: u };
      }),
    );
  }

  lockUser(user: User): Observable<BooleanResponse> {
    const request = {
      lock_user: {
        data: {
          username: user.username,
        },
      },
    };
    return this.#http.post<LockUserResponseRoot>(this.lockuserUrl, request).pipe(
      map((resp: LockUserResponseRoot) => {
        const lu = resp.lock_user_response;
        return {
          status: lu.status,
          message: lu.message,
          success: lu.status === Consts.successStatus,
        };
      }),
    );
  }

  changePIN(data: ChangePinParams, language: string): Observable<UserResponse> {
    const request = {
      change_password: {
        data: {
          oldpassword: data.oldpin,
          newpassword: data.newpin,
        },
      },
    };

    return this.#http.post<ChangePasswordResponseRoot>(this.changePasswordUrl, request).pipe(
      map((resp: ChangePasswordResponseRoot) => {
        let message = '';
        const respChangeUser = resp.change_password_response;
        if (respChangeUser.status === Consts.successStatus) {
          data.user.jwt = respChangeUser.data.jwt;
        } else if (respChangeUser.status === Consts.errorStatus) {
          message = respChangeUser.message;
        } else if (respChangeUser.status === Consts.failStatus) {
          message = getText(respChangeUser.data.errortexts, language);
        }

        return {
          status: respChangeUser.status,
          message: message ? message : '',
          user: data.user,
        };
      }),
    );
  }

  changeFavorites(user: User): Observable<ChangeFavoritesResponse> {
    const request = {
      change_favorites: {
        data: {
          favorites: user.favorites,
        },
      },
    };

    return this.#http.post<ChangeFavoritesResponseRoot>(this.changeFavoritesUrl, request).pipe(
      map((resp: ChangeFavoritesResponseRoot) => {
        let message = '';
        const respChangeFavorites = resp.change_favorites_response;
        if (respChangeFavorites.status === Consts.errorStatus) {
          message = respChangeFavorites.message;
        }

        return {
          status: respChangeFavorites.status,
          message: message ? message : '',
          data: {},
        };
      }),
    );
  }
}
