/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, computed, inject, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePickerComponent } from '@shared/ui-global';
import {
  BrnAlertDialogComponent,
  BrnAlertDialogContentDirective,
  BrnAlertDialogTriggerDirective,
} from '@spartan-ng/ui-alertdialog-brain';
import {
  HlmAlertDialogActionButtonDirective,
  HlmAlertDialogCancelButtonDirective,
  HlmAlertDialogComponent,
  HlmAlertDialogContentComponent,
  HlmAlertDialogDescriptionDirective,
  HlmAlertDialogFooterComponent,
  HlmAlertDialogHeaderComponent,
  HlmAlertDialogOverlayDirective,
  HlmAlertDialogTitleDirective,
} from '@spartan-ng/ui-alertdialog-helm';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { formatDate, isToday, isTomorrow, isYesterday, max } from 'date-fns';
import { GuestAppStore } from '../../stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HlmButtonDirective,
    HlmIconComponent,
    DatePickerComponent,
    BrnAlertDialogTriggerDirective,
    BrnAlertDialogContentDirective,
    HlmAlertDialogComponent,
    HlmAlertDialogOverlayDirective,
    HlmAlertDialogHeaderComponent,
    HlmAlertDialogFooterComponent,
    HlmAlertDialogTitleDirective,
    HlmAlertDialogDescriptionDirective,
    HlmAlertDialogCancelButtonDirective,
    HlmAlertDialogActionButtonDirective,
    HlmAlertDialogContentComponent,
  ],
  selector: 'guest-app-date-select',
  template: ` <div class="bg-background flex w-full items-center justify-between gap-1">
      <button
        hlmBtn
        size="icon"
        class="bg-background z-20 w-1/6"
        (click)="onShiftDayBackward()"
        variant="ghost">
        <hlm-icon name="featherChevronLeft" />
      </button>
      <button
        hlmBtn
        variant="ghost"
        (click)="dateSelectDialog.open()"
        class="w-4/6 whitespace-nowrap">
        {{ getDayText() }}
      </button>
      <button
        hlmBtn
        size="icon"
        class="bg-background z-20 w-1/6"
        (click)="onShiftDayForward()"
        variant="ghost">
        <hlm-icon name="featherChevronRight" />
      </button>
    </div>
    <div class="from-background h-2 bg-gradient-to-b to-transparent"></div>

    <hlm-alert-dialog
      #dateSelectDialogId
      [closeOnOutsidePointerEvents]="true">
      <hlm-alert-dialog-content
        *brnAlertDialogContent="let ctx"
        class="elite-scrollbar max-h-screen select-none">
        <hlm-alert-dialog-header>
          <global-date-picker
            [rangePicker]="false"
            [preSelectedDate]="selectedDate()"
            (selectedDate)="onNewSelectedDate($event)"></global-date-picker>
        </hlm-alert-dialog-header>
      </hlm-alert-dialog-content>
    </hlm-alert-dialog>`,
  host: { class: 'w-full sticky top-0 z-10 px-2' },
})
export class DateSelectComponent {
  #store = inject(GuestAppStore);
  #translate = inject(TranslateService);

  withEmblaCarousel = input.required<boolean>();

  selectedDate = () => this.#store.getSelectedDate();
  @ViewChild('dateSelectDialogId') dateSelectDialog!: BrnAlertDialogComponent;

  onShiftDayForward(): void {
    if (this.withEmblaCarousel()) {
      this.#store.emblaApi()?.scrollNext();
    } else {
      this.#store.shiftDate(1);
    }
  }

  onShiftDayBackward(): void {
    if (this.withEmblaCarousel()) {
      this.#store.emblaApi()?.scrollPrev();
    } else {
      this.#store.shiftDate(-1);
    }
  }

  onNewSelectedDate(event: Date): void {
    const date = max([event, new Date()]);
    this.#store.setSelectedDate(date, true);
    this.dateSelectDialog.close(event);
  }

  getDayText = computed(() => {
    const locale = this.#store.locale();
    const date = this.#store.getSelectedDate();
    if (isToday(date)) {
      return this.#translate.instant('filteroptions.today');
    } else if (isYesterday(date)) {
      return this.#translate.instant('filteroptions.yesterday');
    } else if (isTomorrow(date)) {
      return this.#translate.instant('filteroptions.tomorrow');
    } else {
      return formatDate(date, 'PPP', { locale: locale });
    }
  });
}
