<guest-app-header-line></guest-app-header-line>
<div class="flex w-full flex-col items-center gap-4 p-2 pt-8">
  <div class="elite-text-headerA">{{ 'settings.yourAccount' | translate }}</div>
  <form
    class="flex w-full max-w-md flex-col gap-4 pt-4"
    [formGroup]="changePinForm"
    (ngSubmit)="onSubmit()">
    <div class="elite-text-large text-center">{{ 'settings.changePin' | translate }}</div>
    <input
      hlmInput
      type="password"
      class="w-full"
      required="true"
      autofocus="true"
      formControlName="oldpin"
      [placeholder]="'settings.oldPin' | translate" />
    <input
      hlmInput
      type="password"
      required="true"
      formControlName="newpin"
      [placeholder]="'settings.newPin' | translate" />
    <input
      hlmInput
      type="password"
      required="true"
      formControlName="newpinConfirm"
      [placeholder]="'settings.confirmNewPin' | translate" />
    @if (passwordsMismatch()) {
      <div class="text-destructive elite-text-strong w-full text-center">
        {{ 'settings.pinsDontMatch' | translate }}
      </div>
    } @else if (pinchangeError() && pinchangeError() !== '') {
      <div class="text-destructive elite-text-strong w-full text-center">
        {{ pinchangeError() }}
      </div>
    }
    <button
      hlmBtn
      class="uppercase">
      {{ 'settings.changePin' | translate }}
    </button>
  </form>
  <div class="flex w-full max-w-md flex-col gap-4 pt-4">
    <div class="elite-text-large text-center">{{ 'settings.lockAccount' | translate }}</div>
    <button
      hlmBtn
      (click)="onOpenLockUserDialog()"
      class="uppercase">
      {{ 'settings.lockMyAccount' | translate }}
    </button>
    @if (lockAccountError() && lockAccountError() !== '') {
      <div class="text-destructive elite-text-strong w-full text-center">
        {{ lockAccountError() }}
      </div>
    }
    <button
      hlmBtn
      (click)="onCancel()"
      class="uppercase">
      {{ 'settings.back' | translate }}
    </button>
  </div>
</div>

<hlm-alert-dialog #lockDialogId>
  <hlm-alert-dialog-content
    *brnAlertDialogContent="let ctx"
    class="elite-v-scrollbar mx-auto max-h-screen max-w-[96%] select-none">
    <hlm-alert-dialog-header>
      <div class="elite-text-headerB pb-2">
        {{ 'settings.lockAccount' | translate }}
      </div>
      {{ 'settings.sureLock' | translate }}
    </hlm-alert-dialog-header>
    <hlm-alert-dialog-footer>
      <div class="flex w-full justify-around gap-2">
        <button
          hlmBtn
          variant="outline"
          (click)="onLockUser()">
          {{ 'common.YES' | translate }}
        </button>
        <button
          hlmBtn
          variant="outline"
          (click)="ctx.close()">
          {{ 'common.NO' | translate }}
        </button>
      </div>
    </hlm-alert-dialog-footer>
  </hlm-alert-dialog-content>
</hlm-alert-dialog>
