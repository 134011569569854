import { patchState, signalStore, type, withMethods, withState } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import { withUndoRedo } from '@shared/util-global';
import { Snapshot, ThemeEditorState, emptyThemeEditorState } from './theme-editor.models';

export const ThemeEditorStore = signalStore(
  { providedIn: 'root' },
  // withState({ ids: [] }),
  withState<ThemeEditorState>(emptyThemeEditorState),

  // withHooks({
  //   onInit: (store) => {},
  // }),

  withMethods((store) => {
    const setCornerRadius = (cornerRadius: number) => {
      patchState(store, { cornerRadius });
    };

    return { setCornerRadius };
  }),

  withEntities({ entity: type<Snapshot>() }),

  withUndoRedo(5),
);
