import { Subject, firstValueFrom } from 'rxjs';

export async function imageExists(url: string): Promise<boolean> {
  const subject$ = new Subject<boolean>();
  const img = new Image();
  img.src = url;
  img.onload = () => {
    subject$.next(true);
  };
  img.onerror = () => {
    subject$.next(false);
  };
  return await firstValueFrom(subject$);
}
