import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { GlobalUISettingsService } from '../services/settings/global-ui-settings';

interface TailwindColor {
  name: string[];
  dark: string;
  light: string;
}

interface TailwindColors {
  colors: TailwindColor[];
}
//TODO sobald alte colors nicht mehr verwendet werden, löschen (textA,bgB,...)!!!
//NOTICE mit secondary wird es conflicte geben solange wir noch die alten colors behalten!
//NOTICE Hier immer Tailwind Farben aktuell halten! (von default-styles.scss)

const tailwindColors: TailwindColors = {
  colors: [
    { name: ['bgA', 'background'], dark: '#1a2627', light: '#f0f0f0' },
    { name: ['textA', 'foreground'], dark: '#f0f0f0', light: '#000000' },
    { name: ['card'], dark: '#2b3738', light: '#ffffff' },
    { name: ['card-foreground'], dark: '#f0f0f0', light: '#2b3738' },
    { name: ['popover'], dark: '#2b3738', light: '#ffffff' },
    { name: ['popover-foreground'], dark: '#f0f0f0', light: '#2b3738' },
    { name: ['primary'], dark: '#9de04b', light: '#9de04b' },
    { name: ['textB', 'primary-foreground'], dark: '#000000', light: '#000000' },
    { name: ['bgB', 'secondary'], dark: '#2b3738', light: '#f0f0f0' },
    { name: ['secondary-foreground'], dark: '#000000', light: '#2b3738' },
    { name: ['tertiary', 'subtle', 'muted'], dark: '#555555', light: '#999999' },
    { name: ['muted-foreground'], dark: '#b4b4b4', light: '#313131' },
    { name: ['accent'], dark: '#2b3738', light: '#f0f0f0' },
    { name: ['accent-foreground'], dark: '#ffffff', light: '#2b3738' },
    { name: ['danger', 'destructive'], dark: '#a11212', light: '#ee6a6a' },
    { name: ['destructive-foreground'], dark: '#ffffff', light: '#000000' },
    { name: ['border'], dark: '#555555', light: '#999999' },
    { name: ['input'], dark: '#b4b4b4', light: '#b4b4b4' },
    { name: ['ring'], dark: '#9de04b', light: '#2b3738' },
    { name: ['warning'], dark: '#ffcc00', light: '#d9ad00' },
    { name: ['warning-foreground'], dark: '#000000', light: '#000000' },
    { name: ['selection'], dark: '#3367d1', light: '#3367d1' },
    { name: ['dark', 'dim'], dark: '#000000d9', light: '#f0f0f0f3' },
    { name: ['sheenGreen'], dark: '#9de04b', light: '#9de04b' },
    { name: ['charlestonGreen'], dark: '#2b3738', light: '#2b3738' },
    { name: ['white'], dark: '#fff', light: '#fff' },
    { name: ['black'], dark: '#000', light: '#000' },
    { name: ['transparent'], dark: '#ffffff00', light: '#ffffff00' },
  ],
};

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'transformTailwindColor',
  standalone: true,
})
export class TransformTailwindColor implements PipeTransform {
  private uiSettings = inject(GlobalUISettingsService);
  transform(colorName: string | undefined | null, mode?: string): string {
    if (colorName !== undefined && colorName !== null) {
      if (colorName.startsWith('#')) {
        return colorName;
      } else {
        for (const color of tailwindColors.colors) {
          if (color.name.includes(colorName)) {
            if (mode) {
              if (mode === 'dark') {
                return color.dark;
              } else {
                return color.light;
              }
            } else if (this.uiSettings.isDarkModeS()) {
              return color.dark;
            } else {
              return color.light;
            }
          }
        }
      }
    }
    if (this.uiSettings.isDarkModeS()) {
      return '#ffffff';
    } else {
      return '#000000';
    }
  }
}
