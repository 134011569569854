import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { GlobalUISettingsService } from '@shared/util-global';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'global-elite-logo-static',
  imports: [CommonModule],
  standalone: true,
  template: `<div class="flex h-full w-full">
    @if (showLogo) {
      <div
        class="my-auto"
        [ngClass]="showLogoText ? 'w-[38%]' : 'w-full'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2991.84 1572.24">
          <path
            [ngClass]="uiSettings.isDarkModeS() ? 'fill-sheenGreen' : 'fill-charlestonGreen'"
            d="M841.03,1280.9H239.09c-41.13,0-77.88-25.71-91.98-64.36L6.01,829.68c-23.29-63.87,24-131.45,91.98-131.45H699.93c41.13,0,77.88,25.71,91.98,64.36l141.1,386.86c23.29,63.87-24,131.45-91.98,131.45Z" />
          <path
            [ngClass]="uiSettings.isDarkModeS() ? 'fill-white' : 'fill-sheenGreen'"
            d="M1884.91,582.67H666.97c-41.13,0-77.88-25.71-91.98-64.36L433.89,131.45C410.6,67.58,457.89,0,525.87,0H1743.81c41.13,0,77.88,25.71,91.98,64.36l141.1,386.86c23.29,63.87-24,131.45-91.98,131.45Z" />
          <path
            [ngClass]="uiSettings.isDarkModeS() ? 'fill-white' : 'fill-sheenGreen'"
            d="M2893.85,1572.24H1259.7c-41.13,0-77.88-25.71-91.98-64.36l-141.1-386.86c-23.29-63.87,24-131.45,91.98-131.45h1634.16c41.13,0,77.88,25.71,91.98,64.36l141.1,386.86c23.29,63.87-24,131.45-91.98,131.45Z" />
        </svg>
      </div>
    }
    @if (showLogo && showLogoText) {
      <div class="w-[5%]"></div>
    }
    @if (showLogoText) {
      <div
        class="my-auto"
        [ngClass]="showLogo ? 'w-[57%]' : 'w-full'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="1800 0 2200 1138.62">
          <g>
            <g [ngClass]="uiSettings.isDarkModeS() ? 'fill-sheenGreen' : 'fill-charlestonGreen'">
              <path
                d="M2310.03,715.49c2.75,2.29,2.74,6.48,.03,8.8-32.16,27.47-64.61,47.46-97.37,59.99-34.52,13.2-73.78,19.8-117.77,19.8-38.58,0-74.62-6.95-108.12-20.81-33.5-13.87-62.79-33-87.82-57.36-25.05-24.37-44.67-53.13-58.88-86.3-14.21-33.15-21.32-68.69-21.32-106.6s6.76-74.45,20.31-107.62c13.53-33.15,31.98-61.93,55.33-86.3,23.35-24.37,51.27-43.48,83.76-57.36,32.49-13.87,67.34-20.81,104.57-20.81s72.59,6.95,104.06,20.81c31.47,13.88,58.54,32.99,81.22,57.36,22.67,24.36,40.43,53.65,53.3,87.82,12.85,34.19,19.29,71.57,19.29,112.19v29.79c0,3.17-2.57,5.74-5.74,5.74h-347.08c-3.85,0-6.64,3.73-5.5,7.41,7.97,25.75,22.17,46.96,42.58,63.66,22.33,18.28,48.38,27.41,78.17,27.41,21.65,0,41.45-3.71,59.39-11.17,16.12-6.69,29.65-16.13,40.59-28.3,2.06-2.29,5.55-2.54,7.91-.57l99.09,82.42Zm-228.31-326.65c-25.73,0-47.38,8.12-64.98,24.37-16.12,14.87-27.69,35.14-34.72,60.78-1,3.64,1.77,7.24,5.55,7.24h193.3c3.8,0,6.58-3.64,5.54-7.29-7.15-25.01-19.38-45.07-36.67-60.22-18.96-16.58-41.63-24.87-68.02-24.87Z" />
              <path
                d="M2607.61,71.65V788.19c0,3.17-2.57,5.74-5.74,5.74h-152.99c-3.17,0-5.74-2.57-5.74-5.74V89.35c0-12.27,8.95-22.71,21.08-24.58l114.73-17.71c15.07-2.33,28.66,9.33,28.66,24.58Z" />
              <path
                d="M2817.76,188.84c-25.73,0-47.56-8.96-65.48-26.9-17.94-17.92-26.9-39.42-26.9-64.47s9.14-46.7,27.41-64.98c18.28-18.28,39.93-27.41,64.98-27.41s46.86,9.14,65.48,27.41c18.61,18.28,27.92,39.94,27.92,64.98s-8.98,46.54-26.9,64.47c-17.94,17.94-40.1,26.9-66.5,26.9Zm82.23,86.96V788.19c0,3.17-2.57,5.74-5.74,5.74h-152.99c-3.17,0-5.74-2.57-5.74-5.74V275.8c0-3.17,2.57-5.74,5.74-5.74h152.99c3.17,0,5.74,2.57,5.74,5.74Z" />
              <path
                d="M3096.94,632.5v-235.54h-95.79c-3.17,0-5.74-2.57-5.74-5.74v-115.43c0-3.17,2.57-5.74,5.74-5.74h95.79v-124.87c0-11.82,8.32-22.01,19.9-24.37l114.73-23.37c15.41-3.14,29.83,8.64,29.83,24.37v148.24h134.36c3.17,0,5.74,2.57,5.74,5.74v115.43c0,3.17-2.57,5.74-5.74,5.74h-134.36v203.05c0,23.02,5.23,39.26,15.74,48.73,10.49,9.49,28.25,14.21,53.3,14.21,9.47,0,18.94-.67,28.43-2.03,8.01-1.14,18.45-3.5,31.31-7.07,3.65-1.01,7.27,1.74,7.27,5.53v121.15c0,2.56-1.69,4.79-4.15,5.52-14.1,4.17-30.99,7.63-50.68,10.4-21.67,3.05-40.96,4.57-57.87,4.57-61.6,0-108.3-14.21-140.1-42.64-31.82-28.43-47.72-70.39-47.72-125.89Z" />
              <path
                d="M3960.79,715.49c2.75,2.29,2.74,6.48,.03,8.8-32.16,27.47-64.61,47.46-97.37,59.99-34.52,13.2-73.78,19.8-117.77,19.8-38.58,0-74.62-6.95-108.12-20.81-33.5-13.87-62.79-33-87.82-57.36-25.05-24.37-44.67-53.13-58.88-86.3-14.21-33.15-21.32-68.69-21.32-106.6s6.76-74.45,20.31-107.62c13.53-33.15,31.98-61.93,55.33-86.3,23.35-24.37,51.27-43.48,83.76-57.36,32.49-13.87,67.34-20.81,104.57-20.81s72.59,6.95,104.06,20.81c31.47,13.88,58.54,32.99,81.22,57.36,22.67,24.36,40.43,53.65,53.3,87.82,12.85,34.19,19.29,71.57,19.29,112.19v29.79c0,3.17-2.57,5.74-5.74,5.74h-347.08c-3.85,0-6.64,3.73-5.5,7.41,7.97,25.75,22.17,46.96,42.58,63.66,22.33,18.28,48.38,27.41,78.17,27.41,21.65,0,41.45-3.71,59.39-11.17,16.12-6.69,29.65-16.13,40.59-28.3,2.06-2.29,5.55-2.54,7.91-.57l99.09,82.42Zm-228.31-326.65c-25.73,0-47.38,8.12-64.98,24.37-16.12,14.87-27.69,35.14-34.72,60.78-1,3.64,1.77,7.24,5.55,7.24h193.3c3.8,0,6.58-3.64,5.54-7.29-7.15-25.01-19.38-45.07-36.67-60.22-18.96-16.58-41.63-24.87-68.02-24.87Z" />
            </g>
            <g [ngClass]="uiSettings.isDarkModeS() ? 'fill-white' : 'fill-sheenGreen'">
              <path
                d="M1818.77,1093.87l15.54-18.51c13.88,13.01,26.94,22.26,39.17,27.77,12.23,5.51,25.84,8.26,40.83,8.26,17.85,0,32.61-4.46,44.3-13.39,11.68-8.93,17.52-20,17.52-33.22,0-11.46-3.92-20.27-11.74-26.45-7.83-6.17-21.11-10.91-39.84-14.21l-37.36-6.28c-21.16-3.74-36.64-10.19-46.45-19.34-9.81-9.14-14.71-21.43-14.71-36.86,0-19.39,7.38-35.26,22.15-47.6,14.76-12.34,33.83-18.51,57.19-18.51,17.19,0,33.17,2.37,47.94,7.11,14.76,4.74,29.31,12.18,43.64,22.31l-13.22,19.83c-11.9-8.81-24.52-15.65-37.85-20.5-13.34-4.85-26.73-7.27-40.17-7.27-16.09,0-29.31,4.02-39.67,12.07-10.36,8.05-15.54,18.02-15.54,29.92,0,10.14,3.41,18.07,10.25,23.8,6.83,5.73,17.96,9.81,33.39,12.23l37.03,6.28c24.46,4.41,42.31,11.57,53.55,21.49,11.24,9.92,16.86,23.47,16.86,40.66,0,9.92-2.21,19.17-6.61,27.77-4.41,8.59-10.53,15.98-18.35,22.15-7.83,6.17-17.14,11.02-27.93,14.55-10.8,3.52-22.48,5.29-35.04,5.29-18.07,0-34.88-3.25-50.41-9.75-15.54-6.5-30.36-16.36-44.46-29.59Z" />
              <path
                d="M2300.76,1014.2c0,16.53-2.98,32.12-8.93,46.78-5.95,14.66-14.11,27.33-24.46,38.02-10.36,10.69-22.54,19.12-36.53,25.29-14,6.17-29.04,9.26-45.13,9.26s-30.86-3.09-44.96-9.26c-14.11-6.17-26.34-14.6-36.69-25.29-10.36-10.69-18.51-23.36-24.46-38.02-5.95-14.65-8.93-30.25-8.93-46.78s2.98-32.12,8.93-46.78c5.95-14.65,14.1-27.33,24.46-38.02,10.36-10.69,22.59-19.12,36.69-25.29,14.1-6.17,29.09-9.26,44.96-9.26s31.13,3.09,45.13,9.26c13.99,6.17,26.17,14.6,36.53,25.29,10.36,10.69,18.51,23.36,24.46,38.02,5.95,14.66,8.93,30.25,8.93,46.78Zm-25.79,0c0-13.44-2.31-26.06-6.94-37.85-4.63-11.79-10.91-21.98-18.84-30.58-7.93-8.59-17.36-15.37-28.27-20.33-10.91-4.96-22.65-7.44-35.21-7.44s-24.3,2.48-35.21,7.44c-10.91,4.96-20.33,11.74-28.27,20.33-7.93,8.59-14.21,18.79-18.84,30.58-4.63,11.79-6.94,24.41-6.94,37.85s2.31,26.06,6.94,37.85c4.63,11.79,10.91,21.98,18.84,30.58,7.93,8.6,17.36,15.37,28.27,20.33,10.91,4.96,22.65,7.44,35.21,7.44s24.3-2.48,35.21-7.44c10.91-4.96,20.33-11.74,28.27-20.33,7.93-8.59,14.21-18.79,18.84-30.58,4.63-11.79,6.94-24.41,6.94-37.85Z" />
              <path d="M2385.39,1129.9v-231.41h25.46v208.93h130.58v22.48h-156.04Z" />
              <path
                d="M2793.33,1032.05c0,30.86-8.43,55.49-25.29,73.89-16.86,18.4-39.4,27.6-67.6,27.6s-51.02-9.14-67.77-27.44c-16.75-18.29-25.12-42.98-25.12-74.05v-133.56h25.46v133.56c0,23.58,6.12,42.54,18.35,56.86,12.23,14.33,28.6,21.49,49.09,21.49s36.86-7.16,49.09-21.49c12.23-14.32,18.35-33.28,18.35-56.86v-133.56h25.45v133.56Z" />
              <path d="M2863.74,920.97v-22.48h185.13v22.48h-79.67v208.93h-25.45v-208.93h-80Z" />
              <path d="M3123.58,1129.9v-231.41h25.45v231.41h-25.45Z" />
              <path
                d="M3463.42,1014.2c0,16.53-2.98,32.12-8.93,46.78-5.95,14.66-14.11,27.33-24.46,38.02-10.36,10.69-22.54,19.12-36.53,25.29-14,6.17-29.04,9.26-45.13,9.26s-30.86-3.09-44.96-9.26c-14.11-6.17-26.34-14.6-36.7-25.29-10.36-10.69-18.51-23.36-24.46-38.02-5.95-14.65-8.93-30.25-8.93-46.78s2.98-32.12,8.93-46.78c5.95-14.65,14.1-27.33,24.46-38.02,10.36-10.69,22.59-19.12,36.7-25.29,14.1-6.17,29.09-9.26,44.96-9.26s31.13,3.09,45.13,9.26c13.99,6.17,26.17,14.6,36.53,25.29,10.36,10.69,18.51,23.36,24.46,38.02,5.95,14.66,8.93,30.25,8.93,46.78Zm-25.79,0c0-13.44-2.31-26.06-6.94-37.85-4.63-11.79-10.91-21.98-18.84-30.58-7.93-8.59-17.35-15.37-28.26-20.33-10.91-4.96-22.65-7.44-35.21-7.44s-24.3,2.48-35.21,7.44c-10.91,4.96-20.33,11.74-28.27,20.33-7.93,8.59-14.21,18.79-18.84,30.58-4.63,11.79-6.94,24.41-6.94,37.85s2.31,26.06,6.94,37.85c4.63,11.79,10.91,21.98,18.84,30.58,7.93,8.6,17.36,15.37,28.27,20.33,10.91,4.96,22.64,7.44,35.21,7.44s24.3-2.48,35.21-7.44c10.91-4.96,20.33-11.74,28.26-20.33,7.93-8.59,14.22-18.79,18.84-30.58,4.63-11.79,6.94-24.41,6.94-37.85Z" />
              <path
                d="M3548.05,898.49h26.45l135.54,191.74-.66-191.74h24.13v231.41h-23.14l-138.85-196.04,.66,196.04h-24.13v-231.41Z" />
              <path
                d="M3808.55,1093.87l15.54-18.51c13.88,13.01,26.94,22.26,39.17,27.77,12.23,5.51,25.84,8.26,40.83,8.26,17.85,0,32.61-4.46,44.3-13.39,11.68-8.93,17.52-20,17.52-33.22,0-11.46-3.92-20.27-11.74-26.45-7.83-6.17-21.1-10.91-39.83-14.21l-37.36-6.28c-21.16-3.74-36.64-10.19-46.45-19.34-9.81-9.14-14.71-21.43-14.71-36.86,0-19.39,7.38-35.26,22.15-47.6,14.76-12.34,33.83-18.51,57.19-18.51,17.19,0,33.17,2.37,47.93,7.11,14.76,4.74,29.31,12.18,43.64,22.31l-13.22,19.83c-11.9-8.81-24.52-15.65-37.85-20.5-13.34-4.85-26.73-7.27-40.17-7.27-16.09,0-29.31,4.02-39.67,12.07-10.36,8.05-15.54,18.02-15.54,29.92,0,10.14,3.41,18.07,10.25,23.8,6.83,5.73,17.96,9.81,33.39,12.23l37.03,6.28c24.46,4.41,42.32,11.57,53.55,21.49,11.24,9.92,16.86,23.47,16.86,40.66,0,9.92-2.21,19.17-6.61,27.77-4.41,8.59-10.53,15.98-18.35,22.15-7.83,6.17-17.14,11.02-27.93,14.55-10.8,3.52-22.48,5.29-35.04,5.29-18.07,0-34.88-3.25-50.41-9.75-15.54-6.5-30.36-16.36-44.46-29.59Z" />
            </g>
          </g>
        </svg>
      </div>
    }
  </div>`,
})
export class EliteLogoStaticComponent {
  @Input() showLogo = true;
  @Input() showLogoText = true;

  public uiSettings = inject(GlobalUISettingsService);
}
