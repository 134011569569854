/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { EliteLogoStaticComponent, MockBadgeComponent, ToasterComponent } from '@shared/ui-global';
import { ngIconsRecord } from '@shared/util-global';
import { isValid } from 'date-fns';
import { debounceTime, take } from 'rxjs';
import { environment } from '../environments/environment';
import { StyleService } from './services/styles.service';
import { GuestAppStore } from './stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, EliteLogoStaticComponent, MockBadgeComponent, ToasterComponent],
  providers: provideIcons(ngIconsRecord),
  selector: 'guest-app-root',

  template: `
    @if (!environment.production) {
      <global-mock-badge></global-mock-badge>
    }
    <div class="grid w-full max-w-[64rem] grow">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="flex w-full justify-center pb-2 pt-4 opacity-30">
      <a
        href="https://elite.tirol"
        target="_blank"
        class="flex gap-2">
        <div>powered by</div>
        <global-elite-logo-static class="w-20"> </global-elite-logo-static>
        <div>(v2.2.3)</div>
      </a>
    </div>
    <global-toaster></global-toaster>
  `,
  host: { class: 'flex flex-col h-screen items-center' },
})
export class AppComponent {
  environment = environment;
  #styles = inject(StyleService);
  #store = inject(GuestAppStore);
  #activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.#styles.addStylesheet();

    this.#activatedRoute.queryParams.pipe(debounceTime(10), take(1)).subscribe((params) => {
      const day: string = params['date'];
      if (day && day.length) {
        const dayToSet = new Date(day);
        if (isValid(dayToSet)) {
          this.#store.setSelectedDate(dayToSet);
        }
      }
    });
  }
}
