import { Injectable, computed, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TokenService {
  #token = signal('');

  setToken(token: string) {
    this.#token.set(token.toString());
  }

  token = computed(() => {
    return this.#token();
  });
}
