import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QueryObserverResult } from '@ngneat/query';
import { LanguageInfos } from '../../models/models';
import { LanguageService } from '../../services/language.service';
import { LanguageButtonComponent } from '../language-button/language-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-language-buttons',
  standalone: true,
  imports: [CommonModule, LanguageButtonComponent],
  template: `<div class="flex w-full justify-around gap-2">
    @for (langinfo of languages(); track langinfo.name) {
      <guest-app-language-button [languageInfos]="langinfo"></guest-app-language-button>
    }
  </div>`,
})
export class LanguageButtonsComponent {
  #languageService = inject(LanguageService);

  languages = signal<LanguageInfos[]>([]);

  constructor() {
    this.#languageService
      .getAllLanguages()
      .result$.pipe(takeUntilDestroyed())
      .subscribe((result: QueryObserverResult<LanguageInfos[]>) => {
        const res = result.data ?? [];
        this.languages.set([...res]);
      });
  }
}
