import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormatDatePipe, MachineInfoService, OptionItem } from '@shared/util-global';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { addDays, addYears, format, isSameDay, startOfMonth, startOfWeek } from 'date-fns';
import { de } from 'date-fns/locale';
import { OptionSelectComponent } from '../option-select/option-select.component';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface WeekInfo {
  days: DayInfo[];
}

export interface DayInfo {
  date: Date;
  isSelected: boolean;
  isSameMonth: boolean;
  isToday: boolean;
  isInRange: boolean;
  isSelectedStart: boolean;
  isSelectedEnd: boolean;
}

//TODO add tests
//TODO einstellung nur in der zukunft, vergangenheit erlauben..
@Component({
  selector: 'global-date-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './date-picker.component.html',
  imports: [
    CommonModule,
    FormsModule,
    FormatDatePipe,
    OptionSelectComponent,
    HlmIconComponent,
    HlmButtonDirective,
    HlmInputDirective,
  ],
})
export class DatePickerComponent {
  currentDisplayedMonthS = signal(new Date());
  selectedDateS = signal<Date | undefined>(undefined);
  selectedStartS = signal<Date | undefined>(undefined);
  selectedEndS = signal<Date | undefined>(undefined);
  expectedDateS = signal<Date | undefined>(undefined);

  public weeksS = signal<WeekInfo[]>([]);

  monthPick = input(true);
  yearPick = input(true);
  rangePicker = input(false);
  border = input(false);
  pickToday = input(true);
  @Input() set preSelectedDate(date: Date | undefined) {
    if (date === undefined) {
      return;
    }
    this.selectedDateS.set(date);
    this.selectedYearNumber = date.getFullYear();
    const newSelectedMonth = date.getMonth();
    this.selectedMonth = { label: newSelectedMonth.toString(), value: newSelectedMonth };
    this.onDateChange();
  }
  @Input() set preSelectedRange(range: DateRange | undefined) {
    if (range === undefined) {
      return;
    }
    this.selectedStartS.set(range.startDate);
    this.selectedEndS.set(range.endDate);
    this.selectedYearNumber = range.startDate.getFullYear();
    const newSelectedMonth = range.startDate.getMonth();
    this.selectedMonth = { label: newSelectedMonth.toString(), value: newSelectedMonth };
    this.onDateChange();
  }
  @Output() selectedDate = new EventEmitter<Date>();
  @Output() selectedRange = new EventEmitter<DateRange | undefined>();

  public selectedYearNumber: number = new Date().getFullYear();
  public selectedMonth: OptionItem = { label: '', value: -1 };
  public readonly months: OptionItem[] = this.getMonths();
  public readonly weekdays: string[] = this.getWeekdays();

  private machinInfo = inject(MachineInfoService);

  constructor() {
    effect(() => {
      this.calcWeeks();
    });
  }

  calcWeeks(): void {
    const weeks: WeekInfo[] = [];
    const firstDayOfMonth = startOfMonth(this.currentDisplayedMonthS());
    const firstDayOfPicker = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
    let currentWeek: DayInfo[] = [];

    for (let i = 0; i < 42; i++) {
      const currentDay = addDays(firstDayOfPicker, i);
      const isToday = isSameDay(currentDay, new Date());
      const isSameMonth = this.currentDisplayedMonthS().getMonth() === currentDay.getMonth();
      let isSelected = false;
      const selectedDate = this.selectedDateS();
      if (selectedDate !== undefined) {
        isSelected = isSameDay(selectedDate, currentDay);
      }
      const isInRange = this.isRangeSelected(currentDay);
      const isSelectedStart = this.isSameDay(currentDay, this.selectedStartS());
      const isSelectedEnd = this.isSameDay(currentDay, this.selectedEndS());
      currentWeek.push({
        date: currentDay,
        isSameMonth,
        isSelected,
        isToday,
        isInRange,
        isSelectedEnd,
        isSelectedStart,
      });

      if (currentWeek.length === 7) {
        weeks.push({ days: currentWeek });

        currentWeek = [];
      }
    }
    untracked(() => {
      this.weeksS.set(weeks);
    });

    this.selectedYearNumber = this.currentDisplayedMonthS().getFullYear();
    const newSelectedMonth = this.currentDisplayedMonthS().getMonth();
    this.selectedMonth = { label: newSelectedMonth.toString(), value: newSelectedMonth };
  }

  isSameDay(date1: Date | undefined, date2: Date | undefined): boolean {
    if (date1 === undefined || date2 === undefined) {
      return false;
    }
    return isSameDay(date1, date2);
  }

  isRangeSelected(date: Date): boolean {
    const expected = this.expectedDateS();
    const selectedStartDate = this.selectedStartS();
    const selectedEndDate = this.selectedEndS();
    if (this.selectedEndS() === undefined) {
      if (selectedStartDate && expected) {
        return date >= selectedStartDate && date <= expected;
      }
      return false;
    } else {
      if (selectedStartDate && selectedEndDate) {
        return date >= selectedStartDate && date <= selectedEndDate;
      }
      return false;
    }
  }

  getMonths(): OptionItem[] {
    const months: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
    return months.map((month, index) => {
      const monthDate = new Date(new Date().getFullYear(), index, 1);
      return { label: format(monthDate, 'MMMM', { locale: de }), value: monthDate.getMonth() };
    });
  }

  getWeekdays(): string[] {
    const weekdays = [];
    const startDate = new Date(2023, 7, 2);

    for (let i = 5; i < 12; i++) {
      const weekday = new Date(startDate);
      weekday.setDate(startDate.getDate() + i);
      const formattedWeekday = format(weekday, 'EE', { locale: de });
      weekdays.push(formattedWeekday);
    }
    return weekdays;
  }

  getYearAsNumber(date: Date): number {
    return date.getFullYear();
  }

  onToday(): void {
    this.selectDate(new Date());
  }

  onDateChange(): void {
    if (isNaN(this.selectedYearNumber)) {
      return;
    }
    const date = new Date(new Date().setFullYear(this.selectedYearNumber));
    date.setMonth(Number(this.selectedMonth.value));
    this.currentDisplayedMonthS.set(date);
  }

  prevYear(): void {
    this.currentDisplayedMonthS.set(addYears(this.currentDisplayedMonthS(), -1));
  }

  nextYear(): void {
    this.currentDisplayedMonthS.set(addYears(this.currentDisplayedMonthS(), 1));
  }

  prevMonth(): void {
    this.currentDisplayedMonthS.set(addDays(startOfMonth(this.currentDisplayedMonthS()), -1));
  }
  nextMonth(): void {
    this.currentDisplayedMonthS.set(addDays(startOfMonth(this.currentDisplayedMonthS()), 31));
  }

  selectDate(selectedDate: Date): void {
    if (this.selectedDateS() === selectedDate) {
      this.selectedDateS.set(undefined);
    } else {
      this.selectedDateS.set(selectedDate);
    }
    this.selectedDate.emit(this.selectedDateS());
  }

  selectStartDate(selectedDate: Date): void {
    const selectedStartDate = this.selectedStartS();
    const selectedEndDate = this.selectedEndS();
    if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
      this.selectedStartS.set(selectedDate);
      this.selectedEndS.set(undefined);
    } else if (selectedEndDate && selectedDate < selectedEndDate) {
      this.selectedStartS.set(selectedDate);
      this.selectedEndS.set(undefined);
    } else if (selectedDate >= selectedStartDate && !selectedEndDate) {
      this.selectedEndS.set(selectedDate);
    } else if (selectedDate <= selectedStartDate) {
      this.selectedStartS.set(selectedDate);
      this.selectedEndS.set(undefined);
    }
    this.emitResult();
  }

  emitResult(): void {
    const selectedStartDate = this.selectedStartS();
    const selectedEndDate = this.selectedEndS();
    if (selectedStartDate && selectedEndDate) {
      this.selectedRange.emit({ startDate: selectedStartDate, endDate: selectedEndDate });
    } else {
      this.selectedRange.emit(undefined);
    }
  }

  expectedDateFunction(date: Date | undefined) {
    if (this.machinInfo.isMobile === true) {
      return;
    } else {
      if (this.selectedStartS() === undefined) {
        return;
      }
      if (this.selectedEndS() !== undefined) {
        return;
      }
      const expectedDate = this.expectedDateS();
      if (expectedDate && date) {
        if (isSameDay(date, expectedDate)) {
          return;
        }
      }
      this.expectedDateS.set(date);
    }
  }

  public selectedMonthNumber(): number {
    return Number(this.selectedMonth.value) ?? -1;
  }
}
