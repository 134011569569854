import { EmblaCarouselType } from 'embla-carousel-angular';

export class Consts {
  static successStatus = 'success';
  static errorStatus = 'error';
  static failStatus = 'fail';
  static openUserName = '{[($OpenUser$)]}-';
  static alreadyBookedStorageKey = 'alreadyBooked';
}

export interface Texts {
  lang: string;
  text: string;
}

export interface guestappcategory {
  id: number;
  texts: Texts[];
}

export interface GeneralInformation {
  title: Texts[];
  text: Texts[];
}

export interface User {
  username: string;
  name: string;
  roomnumber: string | null;
  arrival: Date;
  departure: Date;
  language: string | null;
  jwt: string;
  isemployee: boolean;
  favorites: number[];
  guestappcategories: guestappcategory[];
  hidecategories: boolean;
  hidedateline: boolean;
  canshowallappointments: boolean;
  canshowbookables: boolean;
  dataprotectionlink: string;
  generalinformations: GeneralInformation[];
  darkmodeavail: boolean;
  errortexts: Texts[];
}
const emptyUserREF: User = {
  username: '',
  name: '',
  roomnumber: null,
  arrival: new Date(),
  departure: new Date(),
  language: null,
  jwt: '',
  isemployee: false,
  favorites: [],
  guestappcategories: [],
  hidecategories: false,
  hidedateline: false,
  canshowallappointments: false,
  canshowbookables: false,
  dataprotectionlink: '',
  generalinformations: [],
  darkmodeavail: false,
  errortexts: [],
};
export const emptyUser: User = structuredClone(emptyUserREF);

export interface ChangePinParams {
  oldpin: string;
  newpin: string;
  user: User;
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface ChangeFavoritesResponse {
  status: string;
  message: string;
  data: object;
}

export interface ChangeFavoritesResponseRoot {
  change_favorites_response: ChangeFavoritesResponse;
}

export interface UserResponse {
  status: string;
  message: string;
  user: User | null;
}

export interface BooleanResponse {
  status: string;
  message: string;
  success: boolean;
}

export interface BookingResponse {
  booking_response: BookingResponseData;
}

export interface BookingResponseData {
  status: string;
  responseText?: Texts[];
  message?: string;
  errortexts?: Texts[];
}

export interface ChangePasswordResponseData {
  jwt: string;
  errortexts: Texts[];
}

export interface ChangePasswordResponse {
  status: string;
  message: string;
  data: ChangePasswordResponseData;
}

export interface ChangePasswordResponseRoot {
  change_password_response: ChangePasswordResponse;
}

export interface LockUserResponse {
  status: string;
  message: string;
  data: any;
}

export interface LockUserResponseRoot {
  lock_user_response: LockUserResponse;
}

export interface LoginResponseRoot {
  login_response: LoginResponse;
}

export interface LoginResponse {
  status: string;
  message: string;
  data: User;
}

export interface GeneralInformation {
  title: Texts[];
  text: Texts[];
}

export interface LanguageInfos {
  name: string;
  iconPath: string;
  locale: string;
}

export interface GetAppointmentsResponseRoot {
  get_appointments_response: GetAppointmentsResponse;
}

export interface GetBookablesResponseRoot {
  get_bookables_response: GetBookablesResponse;
}

export interface GetAppointmentsResponse {
  status: string;
  message?: string;
  data: AppointmentsData;
}

export interface GetBookablesResponse {
  status: string;
  message?: string;
  data: BookableModel[];
}

export interface AppointmentsData {
  message?: string;
  availableeventstodate: Date | string;
  availableeventsfromdate: Date | string;
  personal: Personal[];
  events: EventData[];
}

export interface EventData {
  id: number;
  startdatetime: Date | string;
  enddatetime: Date | string;
  categories: Category[];
  typeid: number;
  typetext: string;
  kindid: number;
  kindtext: string;
  shortdescription: string;
  language: string;
  titletexts: Texts[];
  descriptiontexts: Texts[];
  commenttexts?: Texts[];
  confirmed: boolean;
  confirmedby: string;
  confirmtimestamp: Date | string;
  ressourcecode?: string;
  ressourcetexts?: Texts[];
  employeecode?: string;
  employeetext?: string;
  isPersonal?: boolean;
  guestappcategoryid: number;
  clientname?: string;
  clientarrival?: Date | string;
  clientdeparture?: Date | string;
  isbookable?: boolean;
}

export interface Category {
  id: number;
  text: string;
}

export interface Personal extends EventData {
  billholderid: number;
  servicecode: string;
  servicetexts: Texts[];
  packagecode: string;
  packagetexts: Texts[];
}

export interface FilterOptions {
  selectedDate: Date;
  showCommonAppointments: boolean;
  showOldAppointments: boolean;
  showGeneralInfos: boolean;
  filterCategories: number[];
}
const emptyFilterOptionsREF: FilterOptions = {
  selectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
  showCommonAppointments: false,
  showOldAppointments: false,
  showGeneralInfos: true,
  filterCategories: [],
};
export const emptyFilterOptions: FilterOptions = structuredClone(emptyFilterOptionsREF);

export interface GuestAppState {
  loadingAppointments: boolean;
  loadingBookables: boolean;
  language: string;
  user: User;
  filterOptions: FilterOptions;
  appointments: AppointmentModel[];
  bookables: BookableModel[];
  errorMessage: string;
  initialized: boolean;
  gotUserFromServer: boolean;
  availableEventsFromDate: Date;
  availableEventsToDate: Date;
  showBookablePage: boolean;
  lastLoadedDateFrom: Date;
  lastLoadedDateTo: Date;
  forceNewBookables: boolean;
  emblaApi: EmblaCarouselType | undefined;
  alreadyBooked: number[];
}
const emptyGuestAppREF: GuestAppState = {
  loadingAppointments: false,
  loadingBookables: false,
  language: 'de',
  user: emptyUser,
  filterOptions: emptyFilterOptions,
  appointments: [],
  bookables: [],
  errorMessage: '',
  initialized: false,
  gotUserFromServer: false,
  availableEventsFromDate: new Date(),
  availableEventsToDate: new Date(),
  showBookablePage: false,
  lastLoadedDateFrom: new Date(),
  lastLoadedDateTo: new Date(),
  forceNewBookables: false,
  emblaApi: undefined,
  alreadyBooked: [],
};
export const emptyGuestAppState: GuestAppState = structuredClone(emptyGuestAppREF);

export enum TypeId {
  normal = 0,
  dayInfo = 1,
}

export type AppointmentType = 'personal' | 'common';

export interface ModelBase {
  id: number;
  title: Texts[];
  description: Texts[];
  fromTime: string;
  untilTime: string;
}

export interface AppointmentModel extends ModelBase {
  typeId: TypeId;
  guestappcategoryid: number;
  kind: AppointmentType;
  comment?: Texts[];
  clientName?: string;
  clientArrival?: Date;
  clientDeparture?: Date;
  isBookable?: boolean;
}

export interface BookableModel extends ModelBase {
  imageUrl: string;
  price: number;
  serviceCode: string;
}

export interface AppointmentInfo {
  appointments: AppointmentModel[];
  availableEventsFromDate: Date;
  availableEventsToDate: Date;
}
