import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDeAt from '@angular/common/locales/de-AT';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Routes, provideRouter } from '@angular/router';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ScreenService } from '@shared/util-screen';
import { AppComponent } from './app/app.component';
import { HomePageComponent } from './app/components/home-page/home-page.component';
import { LoginPageComponent } from './app/components/login-page/login-page.component';
import { SettingsPageComponent } from './app/components/settings-page/settings-page.component';
import { isUserLoggedIn } from './app/guards/auth.guard';
import { CustomGuestAppLoader } from './app/helper/translation';
import { JwtInterceptor } from './app/interceptors/jwt.interceptor';
import { AppointmentServiceAbstract } from './app/services/abstract/appointment-abstract.service';
import { BookableServiceAbstract } from './app/services/abstract/bookable-abstract.service';
import { UserServiceAbstract } from './app/services/abstract/user-abstract.service';
import { AppointmentServiceMock } from './app/services/mock/appointment-mock.service';
import { BookableServiceMock } from './app/services/mock/bookable-mock.service';
import { UserServiceMock } from './app/services/mock/user-mock.service';
import { AppointmentServiceProduction } from './app/services/prod/appointment-production.service';
import { BookableServiceProduction } from './app/services/prod/bookable-production.service';
import { UserServiceProduction } from './app/services/prod/user-production.service';
import { StyleService } from './app/services/styles.service';
import { environment } from './environments/environment';

const routes: Routes = [
  { path: '', component: HomePageComponent, canActivate: [() => isUserLoggedIn()] },
  { path: 'guestlogin', component: HomePageComponent, data: { guestlogin: true } },
  { path: 'login', component: LoginPageComponent },
  { path: 'settings', component: SettingsPageComponent, canActivate: [() => isUserLoggedIn()] },
];

registerLocaleData(localeDeAt);

const MockProviders = [
  { provide: AppointmentServiceAbstract, useClass: AppointmentServiceMock },
  { provide: BookableServiceAbstract, useClass: BookableServiceMock },
  { provide: UserServiceAbstract, useClass: UserServiceMock },
];

const ProductionProviders = [
  { provide: AppointmentServiceAbstract, useClass: AppointmentServiceProduction },
  { provide: BookableServiceAbstract, useClass: BookableServiceProduction },
  { provide: UserServiceAbstract, useClass: UserServiceProduction },
];
const QueryDevTools = [
  !environment.production
    ? provideQueryDevTools({
        initialIsOpen: true,
      })
    : [],
];

const providers = !environment.production ? MockProviders : ProductionProviders;

bootstrapApplication(AppComponent, {
  providers: [
    ...providers,
    ...QueryDevTools,
    ScreenService,
    StyleService,
    provideRouter(routes),
    importProvidersFrom(
      [HttpClientModule],
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useClass: CustomGuestAppLoader,
        },
      }),
    ),
    [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
