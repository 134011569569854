import { Injectable, NgZone, inject, signal } from '@angular/core';

export interface Toast {
  text: string;
  timeout?: number;
  type?: 'warning' | 'error' | 'success' | 'info' | '';
  showBar?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private ngZone = inject(NgZone);

  private messages: Toast[] = [];
  private isHandling = false;

  public outputS = signal<Toast>({ text: '', timeout: 0 });

  public insertToast(newMessage: Toast): void {
    this.messages.push(newMessage);
    if (!this.isHandling) {
      this.handleMessages();
    }
  }

  private handleMessages(): void {
    this.isHandling = true;
    if (this.messages.length > 0) {
      const popMessage = this.messages.splice(0, 1)[0];
      if (popMessage) {
        this.outputS.set(popMessage);
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.handleMessages();
          }, 200);
        });
      }
    } else {
      this.isHandling = false;
    }
  }
}
