import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Type,
  ViewChild,
  ViewContainerRef,
  effect,
  inject,
} from '@angular/core';
import { ToasterService } from '@shared/util-global';
import { ToastComponent } from './toast.component';

@Component({
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'global-toaster',
  template: `<ng-template #container></ng-template>`,
})
export class ToasterComponent {
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;

  private toast = inject(ToasterService);

  NewToastComponent = ToastComponent;

  @HostBinding('class') public class = 'fixed right-4 top-4 z-50 flex flex-col gap-2 items-end';

  constructor() {
    effect(() => {
      const newMessage = this.toast.outputS();
      if (newMessage.text !== '') {
        this.addComponent(
          this.NewToastComponent,
          newMessage.text,
          newMessage.timeout ?? this.calcTimeout(newMessage.text),
          newMessage.type ?? 'default',
          newMessage.showBar ?? false,
        );
      }
    });
  }

  private calcTimeout(text: string): number {
    let calcetTimeout = text.length * 60;
    if (calcetTimeout < 2000) {
      calcetTimeout = 2000;
    }
    return calcetTimeout;
  }

  private addComponent(componentClass: Type<unknown>, text: string, timeout: number, type: string, showBar: boolean) {
    if (this.container) {
      const component = this.container.createComponent(componentClass);
      component.setInput('text', text);
      component.setInput('timeout', timeout);
      component.setInput('type', type);
      component.setInput('showBar', showBar);
    } else {
      setTimeout(() => {
        this.addComponent(componentClass, text, timeout, type, showBar);
      }, 100);
    }
  }
}
