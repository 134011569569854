import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { QueryObserverResult, injectQuery } from '@ngneat/query';
import { Result } from '@ngneat/query/lib/types';
import { EMPTY, catchError, map } from 'rxjs';
import { AppointmentModelApiEventMapper, AppointmentModelApiPersonalMapper } from '../../helper/appointment.mapper';
import { getDefaultAppointmentInfo } from '../../helper/mapper';
import {
  AppointmentInfo,
  AppointmentModel,
  Consts,
  EventData,
  GetAppointmentsResponseRoot,
  ModelBase,
  Personal,
  User,
} from '../../models/models';
import { AppointmentServiceAbstract } from '../abstract/appointment-abstract.service';

@Injectable()
export class AppointmentServiceProduction extends AppointmentServiceAbstract {
  public appointments: AppointmentModel[] = [];
  #query = injectQuery();
  #url = './api/appointments';
  #http = inject(HttpClient);
  #eventMapper: AppointmentModelApiEventMapper = new AppointmentModelApiEventMapper();
  #personalMapper: AppointmentModelApiPersonalMapper = new AppointmentModelApiPersonalMapper();

  getAppointments(
    user: User,
    selectedDate: Date,
    onError: () => void,
  ): Result<QueryObserverResult<AppointmentInfo, Error>> {
    return this.#query({
      queryKey: ['appointments', user.username, user.isemployee, selectedDate] as const,
      queryFn: () => {
        const request = {
          get_appointments: {
            data: {
              username: user.username,
              isemployee: user.isemployee,
              selecteddate: selectedDate,
            },
          },
        };
        return this.#http.post<GetAppointmentsResponseRoot>(this.#url, request).pipe(
          map((resp: GetAppointmentsResponseRoot) => {
            return this.#mapresponse(resp);
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.error instanceof Error) {
              console.error('An error occurred:', error.error.message);
            } else {
              console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
            }
            onError();

            return EMPTY;
          }),
        );
      },
    });
  }

  public overlapAppointments(first: ModelBase, second: ModelBase): boolean {
    if (second.fromTime >= first.untilTime) return false;
    if (first.fromTime >= second.untilTime) return false;
    return true;
  }

  public overlapWithPrivateAppointment(appointment: ModelBase): boolean {
    let result = false;
    this.appointments.every((element) => {
      if (element.kind === 'personal' && this.overlapAppointments(appointment, element)) {
        result = true;
        return false;
      }
      return true;
    });
    return result;
  }

  #mapresponse(response: GetAppointmentsResponseRoot): AppointmentInfo {
    const result = getDefaultAppointmentInfo();

    if (response.get_appointments_response.status !== Consts.successStatus) {
      return result;
    }
    response.get_appointments_response.data.events.forEach((data: EventData) => {
      result.appointments.push(this.#eventMapper.mapFrom(data));
    });
    response.get_appointments_response.data.personal.forEach((data: Personal) => {
      result.appointments.push(this.#personalMapper.mapFrom(data));
    });
    result.availableEventsFromDate = new Date(response.get_appointments_response.data.availableeventsfromdate);
    result.availableEventsToDate = new Date(response.get_appointments_response.data.availableeventstodate);
    return result;
  }
}
