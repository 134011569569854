import { Observable } from 'rxjs';
import {
  BooleanResponse,
  ChangeFavoritesResponse,
  ChangePinParams,
  LoginCredentials,
  User,
  UserResponse,
} from '../../models/models';

export abstract class UserServiceAbstract {
  // send login to the api
  abstract loginUser(credentials: LoginCredentials, language: string): Observable<UserResponse>;
  abstract refreshUser(jwt: string, language: string): Observable<UserResponse>;
  // send lock user to the api
  abstract lockUser(token: User): Observable<BooleanResponse>;
  // changes PIN of user
  abstract changePIN(data: ChangePinParams, language: string): Observable<UserResponse>;
  abstract changeFavorites(user: User): Observable<ChangeFavoritesResponse>;
}
