/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  BooleanResponse,
  ChangeFavoritesResponse,
  ChangePinParams,
  LoginCredentials,
  User,
  UserResponse,
} from '../../models/models';
import { UserServiceAbstract } from '../abstract/user-abstract.service';

const MOCK_USER_RESPONSES: UserResponse[] = [
  {
    status: 'success',
    message: '',
    user: {
      username: '{[($OpenUser$)]}-7',
      name: '',
      roomnumber: null,
      arrival: new Date(new Date().setHours(0, 0)),
      departure: new Date(new Date().setHours(0, 0) + 1000 * 60 * 60 * 24 * 7),
      language: null,
      jwt: 'OPENUSERTOKEN',
      isemployee: false,
      favorites: [],
      guestappcategories: [
        {
          id: 1,
          texts: [
            {
              lang: 'de',
              text: 'Informationen',
            },
            {
              lang: 'en',
              text: 'Information',
            },
          ],
        },
        {
          id: 2,
          texts: [
            {
              lang: 'de',
              text: 'Veranstaltungen',
            },
            {
              lang: 'en',
              text: 'Events',
            },
          ],
        },
        {
          id: 3,
          texts: [
            {
              lang: 'de',
              text: 'Trainings',
            },
            {
              lang: 'en',
              text: 'Trainings',
            },
          ],
        },
      ],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: false,
      canshowbookables: true,
      dataprotectionlink: 'http://park-igls.at/datenschutz',
      darkmodeavail: false,
      errortexts: [],
      generalinformations: [
        {
          title: [
            {
              lang: 'de',
              text: 'Bauarbeiten auf dem Hof',
            },
            {
              lang: 'en',
              text: 'Construction work in the yard',
            },
          ],
          text: [
            {
              lang: 'de',
              text: 'Bitte benutzen Sie die ausgewiesenen Umleitungen (gelbe Schilder) zum Gebäude.',
            },
            {
              lang: 'en',
              text: 'Please use the designated detours (yellow signs) into the building.',
            },
          ],
        },
      ],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: 'benni',
      name: 'Benjamin Heiss',
      roomnumber: '404',
      arrival: new Date(new Date().setHours(0, 0)),
      departure: new Date(new Date().setHours(0, 0) + 1000 * 60 * 60 * 24),
      language: 'de',
      jwt: 'BENNITOKEN',
      isemployee: false,
      favorites: [2],
      guestappcategories: [
        {
          id: 1,
          texts: [
            {
              lang: 'de',
              text: 'Informationen',
            },
            {
              lang: 'en',
              text: 'Information',
            },
          ],
        },
        {
          id: 2,
          texts: [
            {
              lang: 'de',
              text: 'Veranstaltungen',
            },
            {
              lang: 'en',
              text: 'Events',
            },
          ],
        },
        {
          id: 3,
          texts: [
            {
              lang: 'de',
              text: 'Trainings',
            },
            {
              lang: 'en',
              text: 'Trainings',
            },
          ],
        },
      ],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: true,
      canshowbookables: true,
      dataprotectionlink: '',
      darkmodeavail: true,
      errortexts: [],
      generalinformations: [
        {
          title: [
            {
              lang: 'de',
              text: 'Stornoinformationen',
            },
            {
              lang: 'en',
              text: 'Cancellation information',
            },
          ],
          text: [
            {
              lang: 'de',
              text: 'Wenn Sie zu spät kommen, verkürzt sich die Behandlungszeit im gleichen Maße!',
            },
            {
              lang: 'en',
              text: 'If you are late, the treatment time will be reduced to the same extent!',
            },
          ],
        },
        {
          title: [
            {
              lang: 'de',
              text: 'Im Falle einer Krankheit',
            },
            {
              lang: 'en',
              text: 'In case of illness',
            },
          ],
          text: [
            {
              lang: 'de',

              text: 'Bitte melden Sie sich nach Möglichkeit am Vortag ab!',
            },
            {
              lang: 'en',
              text: 'Please sign out the day before if possible!',
            },
          ],
        },
        {
          title: [
            {
              lang: 'de',
              text: 'Bauarbeiten auf dem Hof',
            },
            {
              lang: 'en',
              text: 'Construction work in the yard',
            },
          ],
          text: [
            {
              lang: 'de',
              text: 'Bitte benutzen Sie die ausgewiesenen Umleitungen (gelbe Schilder) zum Gebäude.',
            },
            {
              lang: 'en',
              text: 'Please use the designated detours (yellow signs) into the building.',
            },
          ],
        },
        {
          title: [
            {
              lang: 'de',
              text: '',
            },
            {
              lang: 'en',
              text: '',
            },
          ],
          text: [
            {
              lang: 'de',
              text: 'Bitte benutzen Sie die ausgewiesenen Umleitungen (gelbe Schilder) zum Gebäude.',
            },
            {
              lang: 'en',
              text: 'Please use the designated detours (yellow signs) into the building.',
            },
          ],
        },
        {
          title: [
            {
              lang: 'de',
              text: 'Bauarbeiten auf dem Hof',
            },
            {
              lang: 'en',
              text: 'Construction work in the yard',
            },
          ],
          text: [
            {
              lang: 'de',
              text: '',
            },
            {
              lang: 'en',
              text: '',
            },
          ],
        },
      ],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: 'lucas',
      name: 'Lucas Rosswog',
      roomnumber: '303',
      arrival: new Date(new Date().setHours(0, 0) + 1 * 1000 * 60 * 60 * 24),
      departure: new Date(new Date().setHours(0, 0) + 5 * 1000 * 60 * 60 * 24),
      language: 'de',
      jwt: 'LUCASTOKEN',
      isemployee: false,
      favorites: [],
      guestappcategories: [
        {
          id: 1,
          texts: [
            {
              lang: 'de',
              text: 'Informationen',
            },
            {
              lang: 'en',
              text: 'Information',
            },
          ],
        },
        {
          id: 2,
          texts: [
            {
              lang: 'de',
              text: 'Veranstaltungen',
            },
            {
              lang: 'en',
              text: 'Events',
            },
          ],
        },
        {
          id: 3,
          texts: [
            {
              lang: 'de',
              text: 'Trainings',
            },
            {
              lang: 'en',
              text: 'Trainings',
            },
          ],
        },
      ],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: true,
      canshowbookables: true,
      dataprotectionlink: '',
      generalinformations: [],
      darkmodeavail: true,
      errortexts: [],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: 'dani',
      name: 'Daniel Heiss',
      roomnumber: '101',
      arrival: new Date(new Date('0001-01-01')),
      departure: new Date(new Date('0001-01-01')),
      language: 'en',
      jwt: 'DANITOKEN',
      isemployee: true,
      favorites: [],
      guestappcategories: [],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: true,
      canshowbookables: false,
      dataprotectionlink: '',
      generalinformations: [],
      darkmodeavail: true,
      errortexts: [],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: 'stefan',
      name: 'Stefan Ranner',
      roomnumber: '301',
      arrival: new Date(new Date().setHours(0, 0)),
      departure: new Date(new Date().setHours(0, 0) + 3 * 1000 * 60 * 60 * 24),
      language: 'fr',
      jwt: 'STEFANTOKEN',
      isemployee: true,
      favorites: [],
      guestappcategories: [],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: true,
      canshowbookables: false,
      dataprotectionlink: '',
      generalinformations: [],
      darkmodeavail: true,
      errortexts: [],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: 'werner',
      name: 'Werner Chizzali',
      roomnumber: '202',
      arrival: new Date(new Date().setHours(0, 0)),
      departure: new Date(new Date().setHours(0, 0) + 4 * 1000 * 60 * 60 * 24),
      language: 'de',
      jwt: 'WERNERTOKEN',
      isemployee: true,
      favorites: [],
      guestappcategories: [],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: false,
      canshowbookables: false,
      dataprotectionlink: '',
      generalinformations: [],
      darkmodeavail: true,
      errortexts: [],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: 'max',
      name: 'Max Mustermann',
      roomnumber: '555',
      arrival: new Date(new Date().setHours(0, 0)),
      departure: new Date(new Date().setHours(0, 0) + 8 * 1000 * 60 * 60 * 24),
      language: 'en',
      jwt: 'MAXTOKEN',
      isemployee: false,
      favorites: [],
      guestappcategories: [],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: false,
      canshowbookables: false,
      dataprotectionlink: '',
      generalinformations: [],
      darkmodeavail: true,
      errortexts: [],
    },
  },
  {
    status: 'success',
    message: '',
    user: {
      username: '',
      name: 'Keine Termine',
      roomnumber: '202',
      arrival: new Date(new Date().setHours(0, 0)),
      departure: new Date(new Date().setHours(0, 0) + 4 * 1000 * 60 * 60 * 24),
      language: 'de',
      jwt: 'EMPTYTOKEN',
      isemployee: false,
      favorites: [],
      guestappcategories: [],
      hidecategories: false,
      hidedateline: false,
      canshowallappointments: false,
      canshowbookables: false,
      dataprotectionlink: '',
      generalinformations: [],
      darkmodeavail: false,
      errortexts: [],
    },
  },
];

@Injectable()
export class UserServiceMock extends UserServiceAbstract {
  changeFavorites(user: User): Observable<ChangeFavoritesResponse> {
    return of({
      status: 'success',
      message: '',
      data: {},
    });
  }
  loginUser(credentials: LoginCredentials, language: string): Observable<UserResponse> {
    console.warn('UserRepositoryMock login password is NOT checked!: ' + JSON.stringify(credentials));
    // search username in MOCK_USER_RESPONSES
    const userResponse = MOCK_USER_RESPONSES.find((userResponse) =>
      userResponse.user?.username.toUpperCase().startsWith(credentials.username.toUpperCase()),
    );

    if (userResponse) {
      return of(userResponse);
    }

    return of({
      status: 'error',
      message: 'E-Mail oder PIN ungültig',
      user: null,
    });
  }

  refreshUser(jwt: string, language: string): Observable<UserResponse> {
    console.warn('MOCKED refresh user always returns user with same Favorites');
    const userResponse = MOCK_USER_RESPONSES.find((userResponse) => userResponse.user?.jwt === jwt);
    if (userResponse) {
      return of(userResponse);
    }
    return of({
      status: 'error',
      message: 'USER not Found in MOCK_DATA',
      user: null,
    });
  }

  lockUser(user: User): Observable<BooleanResponse> {
    console.log('locking user ' + user.jwt);
    if (user.jwt === 'BENNITOKEN') {
      return of({ status: 'success', message: '', success: true });
    } else if (user.jwt === 'DANITOKEN') {
      return of({
        status: 'fail',
        message: 'Datenbank kurzfristig erreichbar.',
        success: false,
      });
    }
    return of({
      status: 'error',
      message: 'Datenbank gelöscht.',
      success: false,
    });
  }

  changePIN(data: ChangePinParams, language: string): Observable<UserResponse> {
    if (data.newpin === 'OK') {
      return of({
        status: 'success',
        message: '',
        user: data.user,
      });
    } else if (data.newpin === 'error') {
      return of({
        status: 'error',
        message: 'Benutzer ist nicht mehr gültig.',
        user: null,
      });
    }
    return of({
      status: 'fail',
      message: 'Datenbank nicht erreichbar.',
      user: null,
    });
  }
}
