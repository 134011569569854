/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { LanguageInfos } from '../../models/models';
import { LanguageService } from '../../services/language.service';
import { GuestAppStore } from '../../stores/guest-app.store';
import { LanguageButtonComponent } from '../language-button/language-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, LanguageButtonComponent],
  selector: 'guest-app-language-selector',
  template: `<img
      class="m-1 w-7 cursor-pointer rounded-full"
      [src]="getActualLanguageInfo().iconPath"
      [alt]="getActualLanguageInfo().name" />
    <div
      class="border-border bg-background animate-fadeIn absolute z-50 hidden -translate-x-1/2 rounded-lg border shadow group-hover:block">
      @for (langinfo of otherLanguageInfo(); track langinfo.name) {
        <guest-app-language-button
          [forPopup]="true"
          [languageInfos]="langinfo">
        </guest-app-language-button>
      }
    </div>`,
  host: { class: 'group' },
})
export class LanguageSelectorComponent {
  #languageService = inject(LanguageService);
  #store = inject(GuestAppStore);

  #allLanguages = this.#languageService.getAllLanguages().result;

  languages = computed(() => {
    const result = this.#allLanguages();
    if (!result || !result.data) {
      return [];
    }
    return result.data;
  });
  otherLanguageInfo = computed(() => {
    return this.languages().filter((lang) => lang.locale !== this.#store.language());
  });

  getActualLanguageInfo(): LanguageInfos {
    return (
      this.languages().find((langauge) => langauge.locale === this.#store.language()) ?? {
        iconPath: '',
        locale: '',
        name: '',
      }
    );
  }
}
