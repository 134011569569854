import { Signal, effect, isSignal, signal, untracked } from '@angular/core';
import {
  SignalStoreFeature,
  patchState,
  signalStoreFeature,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { EntityId, EntityState } from '@ngrx/signals/entities';
import { EntitySignals } from '@ngrx/signals/entities/src/models';

type Entity = { id: EntityId };
type StackItem = Record<string, unknown>;

export function withUndoRedo(maxStackSize?: number): SignalStoreFeature<
  {
    state: EntityState<Entity>;
    signals: EntitySignals<Entity>;
    methods: {};
  },
  {
    state: {};
    signals: {
      canUndo: Signal<boolean>;
      canRedo: Signal<boolean>;
      redoCount: Signal<number>;
      stackSize: Signal<number>;
    };
    methods: {
      undo: () => void;
      redo: () => void;
    };
  }
>;

export function withUndoRedo(maxStackSize = 100): SignalStoreFeature<any, any> {
  let previous: StackItem | null = null;
  let skipOnce = false;

  const undoStack: StackItem[] = [];
  const redoStack: StackItem[] = [];

  const canUndo = signal(false);
  const canRedo = signal(false);
  const redoCount = signal(0);
  const stackSize = signal(0);

  const keys = ['entityMap', 'ids'];

  const updateInternal = () => {
    canUndo.set(undoStack.length > 1);
    canRedo.set(redoStack.length > 0);
    redoCount.set(redoStack.length);
    stackSize.set(undoStack.length + redoStack.length);
  };

  return signalStoreFeature(
    withComputed(() => ({
      canUndo: canUndo.asReadonly(),
      canRedo: canRedo.asReadonly(),
      redoCount: redoCount.asReadonly(),
      stackSize: stackSize.asReadonly(),
    })),

    withMethods((store) => ({
      undo(): void {
        const item = undoStack.pop();
        if (item && previous) {
          redoStack.push(previous);
        }
        if (item) {
          skipOnce = true;
          patchState(store, item);
          previous = item;
        }
        updateInternal();
      },

      redo(): void {
        const item = redoStack.pop();
        if (item && previous) {
          undoStack.push(previous);
        }
        if (item) {
          skipOnce = true;
          patchState(store, item);
          previous = item;
        }
        updateInternal();
      },
    })),

    withHooks({
      onInit(store: Record<string, unknown>) {
        effect(() => {
          const cand = keys.reduce((acc, key) => {
            const s = store[key];
            if (s && isSignal(s)) {
              return {
                ...acc,
                [key]: s(),
              };
            }
            return acc;
          }, {});
          if (skipOnce) {
            skipOnce = false;
            return;
          }
          redoStack.splice(0);
          if (previous) {
            undoStack.push(previous);
          }
          if (redoStack.length > maxStackSize) {
            undoStack.unshift();
          }
          previous = cand;
          untracked(() => updateInternal());
        });
      },
    }),
  );
}
