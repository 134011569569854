import { computed, effect, inject, untracked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import {
  CookieHandlerService,
  FormatDatePipe,
  LocalStorageService,
  SessionStorageService,
  ToasterService,
} from '@shared/util-global';
import { addDays, compareAsc, interval, isAfter, isBefore, isSameDay, isWithinInterval, max } from 'date-fns';
import { deAT, enGB } from 'date-fns/locale';
import { EmblaCarouselType } from 'embla-carousel-angular';
import { EMPTY, Observable, catchError, debounceTime, of, pipe, switchMap, take, tap } from 'rxjs';
import {
  AppointmentModel,
  BookableModel,
  BooleanResponse,
  ChangeFavoritesResponse,
  ChangePinParams,
  Consts,
  FilterOptions,
  GuestAppState,
  LoginCredentials,
  User,
  UserResponse,
  emptyFilterOptions,
  emptyGuestAppState,
  emptyUser,
} from '../models/models';
import { AppointmentServiceAbstract } from '../services/abstract/appointment-abstract.service';
import { BookableServiceAbstract } from '../services/abstract/bookable-abstract.service';
import { UserServiceAbstract } from '../services/abstract/user-abstract.service';
import { TokenService } from '../services/token.service';

const languageKey = 'currentLanguage';
const filterKey = 'filterOptions';
const userTokenKey = 'usertoken';
const REFRESH_PAUSE_AFTER_LOGIN = 2000;
const maxAttempts = 5;
let errorCount = 0;

export const GuestAppStore = signalStore(
  { providedIn: 'root' },
  withState<GuestAppState>(emptyGuestAppState),

  withComputed((store) => {
    const locale = computed(() => {
      if (store.language() === 'de') {
        return deAT;
      } else {
        return enGB;
      }
    });

    const isGuestLogin = computed(() => {
      return store.user().username.startsWith(Consts.openUserName) ?? false;
    });

    const forceBookables = computed(() => {
      if (!isGuestLogin()) {
        return false;
      }
      return store.appointments().length === 0;
    });

    const filteredAndGroupedAppointments = computed<AppointmentModel[][]>(() => {
      return groupByDate(store.appointments().filter(doFilterByOptions));
    });

    const doFilterByOptions = (data: AppointmentModel) => {
      const personalFilterOK =
        isGuestLogin() ||
        store.filterOptions().showCommonAppointments ||
        data.kind === 'personal' ||
        store.user().favorites.find((v) => v === data.id);
      const oldFilterOk =
        isGuestLogin() || store.filterOptions().showOldAppointments || isAfter(data.fromTime, new Date());
      const filterCategoryCount = store.filterOptions().filterCategories?.length ?? 0;
      const categoryFilterOK =
        data.kind === 'personal' ||
        filterCategoryCount === 0 ||
        store.filterOptions().filterCategories.includes(data.guestappcategoryid);
      if (personalFilterOK && oldFilterOk) {
        if (categoryFilterOK) {
          return true;
        }
      }
      return false;
    };

    const availableCategories = computed(() => {
      const result: number[] = [];
      for (const appointment of store.appointments()) {
        if (!result.includes(appointment.guestappcategoryid)) {
          if (appointment.kind !== 'personal') {
            if (store.filterOptions().showOldAppointments || isAfter(appointment.fromTime, new Date())) {
              if (
                store.filterOptions().showCommonAppointments ||
                store.user().favorites.find((v) => v === appointment.id)
              ) {
                result.push(appointment.guestappcategoryid);
              }
            }
          }
        }
      }
      return result;
    });

    const outOfValidRange = computed(() => {
      return isBefore(
        store.filterOptions().selectedDate,
        store.availableEventsFromDate() || isAfter(store.filterOptions().selectedDate, store.availableEventsToDate()),
      );
    });

    return {
      locale,
      isGuestLogin,
      outOfValidRange,
      availableCategories,
      filteredAndGroupedAppointments,
      forceBookables,
    };
  }),

  withMethods((store) => {
    const storage = inject(LocalStorageService);
    const translate = inject(TranslateService);
    const userRepository = inject(UserServiceAbstract);
    const toaster = inject(ToasterService);
    const appointmentService = inject(AppointmentServiceAbstract);
    const bookableService = inject(BookableServiceAbstract);
    const cookieHandler = inject(CookieHandlerService);
    const datePipe = inject(FormatDatePipe);
    const router = inject(Router);
    const tokenService = inject(TokenService);

    const saveCurrentToken = (): void => {
      tokenService.setToken(store.user().jwt);
      if (!store.isGuestLogin()) {
        cookieHandler.setCookie(userTokenKey, store.user().jwt, 30);
      }
    };

    const changeFavorites = (): Observable<ChangeFavoritesResponse> => {
      const changeFavoritesResponse = userRepository.changeFavorites(store.user());
      changeFavoritesResponse.subscribe((data: ChangeFavoritesResponse) => {
        if (data.status != 'success') {
          toaster.insertToast({ text: data.message, type: 'error' });
        }
      });
      return changeFavoritesResponse;
    };

    const addFavoriteForUser = (appointment: AppointmentModel) => {
      patchState(store, { user: { ...store.user(), favorites: [...store.user().favorites, appointment.id] } });
      changeFavorites().subscribe((data: ChangeFavoritesResponse) => {
        if (data.status === 'success') {
          if (appointmentService.overlapWithPrivateAppointment(appointment)) {
            if (store.language() !== 'de') {
              toaster.insertToast({ text: 'Attention: Overlap with a personal appointment.', type: 'warning' });
            } else {
              toaster.insertToast({
                text: 'Achtung: Überschneidung mit einem persönlichen Termin.',
                type: 'warning',
              });
            }
          }
        } else {
          removeFavoriteForUser(appointment);
        }
      });
    };

    const removeFavoriteForUser = (appointment: AppointmentModel) => {
      const index = store.user().favorites.indexOf(appointment.id);
      if (index < 0) {
        return;
      }
      const newFavorites = store.user().favorites;
      newFavorites.splice(index, 1);
      patchState(store, { user: { ...store.user(), favorites: newFavorites } });
      changeFavorites().subscribe((data: ChangeFavoritesResponse) => {
        if (data.status != 'success') {
          patchState(store, { user: { ...store.user(), favorites: [...store.user().favorites, appointment.id] } });
          if (store.language() !== 'de') {
            toaster.insertToast({ text: 'Favorite could not be removed. Please try again later.', type: 'warning' });
          } else {
            toaster.insertToast({
              text: 'Favorit konnte nicht entfernt werden. Bitte versuchen Sie es später erneut.',
              type: 'warning',
            });
          }
        }
      });
    };

    const setUser = (user: User) => {
      if (store.user() === user) {
        return;
      }
      setLanguage(user.language ?? 'de');
      const lastDate = new Date(new Date(store.filterOptions().selectedDate).setHours(12, 0, 0, 0));
      const arrival = new Date(new Date(user.arrival).setHours(12, 0, 0, 0));
      const date: Date = max([lastDate, arrival]);
      if (user.isemployee) {
        setFilterOptions({
          selectedDate: lastDate,
          showCommonAppointments: true,
          showOldAppointments: true,
          showGeneralInfos: true,
          filterCategories: [],
        });
      } else {
        setSelectedDate(date, true);
      }
      patchState(store, { user });
      saveCurrentToken();
      loadAppointments();
    };

    const getSelectedDate = () => {
      return store.filterOptions().selectedDate;
    };

    const setSelectedDate = (selectedDate: Date, reloadAll = false) => {
      const newDate = new Date(selectedDate.setHours(12, 0, 0, 0));
      const oldSelectedDate = store.filterOptions().selectedDate;
      patchState(store, {
        filterOptions: { ...store.filterOptions(), selectedDate: newDate },
        forceNewBookables: reloadAll,
      });
      saveFilterOptions();

      // load appointments for the new date and three additional in the direction of the change
      let from = addDays(newDate, -1);
      let until = addDays(newDate, 1);
      if (newDate > oldSelectedDate) {
        until = addDays(newDate, 3);
      } else {
        from = addDays(newDate, -3);
      }
      const oldInterval = interval(store.lastLoadedDateFrom(), store.lastLoadedDateTo());
      const dayBefore = addDays(newDate, -1);
      const dayAfter = addDays(newDate, 1);
      if (
        (isWithinInterval(dayBefore, oldInterval) && isWithinInterval(dayAfter, oldInterval)) ||
        !tokenService.token() ||
        !store.showBookablePage()
      ) {
        return;
      }
      from = new Date(from.setHours(0, 0, 0, 0));
      until = new Date(until.setHours(24, 0, 0, 0));
      loadBookables({ from, until });
    };

    const shiftDate = (days: number) => {
      setSelectedDate(addDays(store.filterOptions().selectedDate, days));
    };

    const setShowCommonAppointments = (showCommonAppointments: boolean) => {
      patchState(store, { filterOptions: { ...store.filterOptions(), showCommonAppointments } });
      saveFilterOptions();
    };

    const setShowOldAppointments = (showOldAppointments: boolean) => {
      patchState(store, { filterOptions: { ...store.filterOptions(), showOldAppointments } });
      saveFilterOptions();
    };

    const setShowGeneralInfos = (showGeneralInfos: boolean) => {
      patchState(store, { filterOptions: { ...store.filterOptions(), showGeneralInfos } });
      saveFilterOptions();
    };

    const setFilterCategories = (filterCategories: number[]) => {
      patchState(store, { filterOptions: { ...store.filterOptions(), filterCategories } });
      saveFilterOptions();
    };

    const setFilterOptions = (filterOptions: FilterOptions) => {
      patchState(store, { filterOptions });
      saveFilterOptions();
    };

    const saveFilterOptions = () => {
      if (navigator.cookieEnabled) {
        storage.setObject(filterKey, store.filterOptions());
      }
    };

    const setLanguage = (language: string) => {
      patchState(store, { language });
      translate.use(language);
      if (navigator.cookieEnabled) {
        storage.setItem(languageKey, language);
      }
    };

    const setErrorMessage = (errorMessage: string) => {
      patchState(store, { errorMessage });
    };

    const setInitialized = (initialized: boolean) => {
      patchState(store, { initialized });
    };

    const setGotUserFromServer = (gotUserFromServer: boolean) => {
      patchState(store, { gotUserFromServer });
    };

    const setAlreadyBooked = (alreadyBooked: number[]) => {
      patchState(store, { alreadyBooked });
    };

    const addAlreadyBooked = (alreadyBooked: number) => {
      const newAlreadyBooked = [...store.alreadyBooked(), alreadyBooked];
      storage.setObject(Consts.alreadyBookedStorageKey, newAlreadyBooked);
      patchState(store, { alreadyBooked: newAlreadyBooked });
    };

    const showBookables = (showBookableAppointments: boolean) => {
      patchState(store, { showBookablePage: showBookableAppointments });
    };

    const login = (credentials: LoginCredentials): Observable<UserResponse> => {
      setGotUserFromServer(true);
      setTimeout(() => {
        setGotUserFromServer(false);
      }, REFRESH_PAUSE_AFTER_LOGIN);
      if (credentials.username.startsWith(Consts.openUserName)) {
        credentials.password = '{[($' + datePipe.transform(new Date(), 'yyyy-MM-dd') + '$)]}';
      }
      if (errorCount >= maxAttempts) {
        let message = 'Zu viele Fehlversuche.';
        if (store.language() !== 'de') {
          message = 'Too many wrong attempts.';
        }
        return of({
          status: Consts.failStatus,
          message,
          user: null,
        });
      }
      const user = userRepository.loginUser(credentials, store.language());

      return user.pipe(
        tap((data) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (
            data === null ||
            data.user === null ||
            data.user.arrival === null ||
            data.user.departure === null ||
            data.user.jwt === null
          ) {
            errorCount++;
            logout();
            return;
          }
          errorCount = 0;
          if (window.location.pathname !== '/guestlogin') {
            router.navigate(['/']);
          }
        }),
      );
    };

    const logout = (): void => {
      cookieHandler.deleteCookie(userTokenKey);
      setUser(emptyUser);
      router.navigate(['/login']);
    };

    const changePin = (params: ChangePinParams): Observable<UserResponse> => {
      return userRepository.changePIN(params, store.language());
    };

    const lockUser = (user: User): Observable<BooleanResponse> => {
      return userRepository.lockUser(user);
    };

    const onLoadAppointmentsError = () => {
      if (!store.isGuestLogin()) {
        logout();
      }
    };

    const loadAppointments = rxMethod<void>((_) =>
      _.pipe(
        tap(() => patchState(store, { loadingAppointments: true })),
        switchMap(() => {
          return appointmentService
            .getAppointments(store.user(), store.filterOptions().selectedDate, onLoadAppointmentsError)
            .result$.pipe(
              tap((newAppointments) => {
                const newData = newAppointments.data;
                if (newData) {
                  const noAppointments = newData.appointments.length === 0;

                  if (noAppointments && store.isGuestLogin()) {
                    patchState(store, { showBookablePage: true });
                  }

                  patchState(store, {
                    availableEventsFromDate: newData.availableEventsFromDate,
                    availableEventsToDate: newData.availableEventsToDate,
                    appointments: newData.appointments,
                    loadingAppointments: false,
                  });
                }
              }),
              catchError((err) => {
                patchState(store, { loadingAppointments: false });
                console.error('Get appointments error', err);
                return EMPTY;
              }),
            );
        }),
      ),
    );

    const loadBookables = rxMethod<{ from: Date; until: Date }>(
      pipe(
        tap(() => {
          patchState(store, { loadingBookables: true });
        }),
        switchMap((props) => {
          return bookableService.getBookables(props.from, props.until).pipe(
            tap((newBookables) => {
              const newData = newBookables.get_bookables_response.data ?? [];
              patchState(store, {
                bookables: [...newData],
                lastLoadedDateFrom: props.from,
                lastLoadedDateTo: props.until,
                loadingBookables: false,
              });
            }),
            catchError((err) => {
              console.error('loadBookables', err);
              patchState(store, {
                bookables: [],
                loadingBookables: false,
              });
              return EMPTY;
            }),
          );
        }),
      ),
    );

    const groupedBookablesByDay = (day: Date): BookableModel[][] => {
      const result: BookableModel[][] = [];
      const bookablesForDay = store
        .bookables()
        .filter(
          (bookable) => isSameDay(new Date(bookable.fromTime), day) && !store.alreadyBooked().includes(bookable.id),
        );
      for (const bookableItem of bookablesForDay) {
        if (appointmentService.overlapWithPrivateAppointment(bookableItem)) {
          continue;
        }
        const serviceCode = bookableItem.serviceCode;
        let pushed = false;

        for (const bookableItemsInResult of result) {
          if (bookableItemsInResult[0].serviceCode === serviceCode) {
            bookableItemsInResult.push(bookableItem);
            pushed = true;
          }
        }
        if (!pushed) {
          result.push([bookableItem]);
        }
      }
      for (const bookableItemsInResult of result) {
        bookableItemsInResult.sort((a, b) => new Date(a.fromTime).valueOf() - new Date(b.fromTime).valueOf());
      }
      return result.sort((a, b) => compareAsc(new Date(a[0].fromTime), new Date(b[0].fromTime)));
    };

    const connectedEmblaApi = (api: EmblaCarouselType) => {
      patchState(store, { emblaApi: api });
    };

    return {
      setUser,
      addFavoriteForUser,
      removeFavoriteForUser,
      getSelectedDate,
      setSelectedDate,
      shiftDate,
      setShowCommonAppointments,
      setShowOldAppointments,
      setShowGeneralInfos,
      setFilterCategories,
      setFilterOptions,
      setLanguage,
      setErrorMessage,
      setInitialized,
      setGotUserFromServer,
      showBookables,
      login,
      logout,
      changePin,
      lockUser,
      loadAppointments,
      setAlreadyBooked,
      addAlreadyBooked,
      groupedBookablesByDay,
      connectedEmblaApi,
    };
  }),

  withHooks({
    onInit: (store) => {
      const storage = inject(LocalStorageService);
      const activatedRoute = inject(ActivatedRoute);
      const cookieHandler = inject(CookieHandlerService);
      const userRepository = inject(UserServiceAbstract);
      const session = inject(SessionStorageService);

      const showBookablePage = session.getItem('showBookablePage');

      const alreadyBooked = storage.getObject(Consts.alreadyBookedStorageKey);
      if (alreadyBooked.length) {
        store.setAlreadyBooked(alreadyBooked);
      }

      if (navigator.cookieEnabled) {
        const storageFilterOptions: FilterOptions = storage.getObject(filterKey);
        const filteroptions = { ...emptyFilterOptions, ...storageFilterOptions };
        store.setFilterOptions(filteroptions);
        activatedRoute.queryParams.pipe(debounceTime(10), take(1)).subscribe((params) => {
          const paramLanguage: string = params['lg'];
          if (paramLanguage) {
            store.setLanguage(paramLanguage);
          } else {
            store.setLanguage(storage.getItem(languageKey) ?? 'de');
          }
        });
      }

      effect(() => {
        store.user();
        store.filterOptions();
        session.setItem('showBookablePage', JSON.stringify(store.showBookablePage()));

        untracked(() => {
          if (store.user().username !== '' && !store.showBookablePage() && !store.loadingAppointments()) {
            store.loadAppointments();
          }
        });
      });

      if (window.location.pathname === '/guestlogin') {
        store.setInitialized(true);
      } else {
        // AutoLogin:
        const jwt = cookieHandler.getCookie(userTokenKey);
        if (jwt) {
          if (store.gotUserFromServer()) {
            store.setInitialized(true);
            console.log('Login less than 2000ms ago, no refresh needed');
          } else {
            userRepository.refreshUser(jwt, store.language()).subscribe((data) => {
              if (data.status === Consts.successStatus) {
                store.setUser(data.user ?? emptyUser);
              } else if (data.status === Consts.failStatus) {
                // if the token is invalid
                store.logout();
              }
              store.setInitialized(true);
            });
          }
        } else {
          store.setInitialized(true);
        }
      }
      if (showBookablePage === 'true') {
        store.showBookables(true);
      }
    },
  }),
);

export function groupByDate(xs: AppointmentModel[]): AppointmentModel[][] {
  if (xs.length === 0) {
    return [];
  }
  const sorted = xs.sort((a, b) => new Date(a.fromTime).valueOf() - new Date(b.fromTime).valueOf());
  const grouped: AppointmentModel[][] = sorted.reduce((acc: AppointmentModel[][], curr: AppointmentModel) => {
    const lastGroup = acc[acc.length - 1];
    if (lastGroup && isSameDay(lastGroup[0].fromTime, curr.fromTime)) {
      lastGroup.push(curr);
    } else {
      acc.push([curr]);
    }
    return acc;
  }, []);

  return grouped;
}
