import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HlmSwitchComponent } from '@spartan-ng/ui-switch-helm';
import { getText } from '../../helper/get-text';
import { GeneralInformation } from '../../models/models';
import { GuestAppStore } from '../../stores/guest-app.store';

interface GeneralInfo {
  title: string;
  text: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, HlmSwitchComponent, FormsModule],
  selector: 'guest-app-general-informations',
  template: `@if (createdInfos() && createdInfos().length >= 1) {
    <div class="@container flex flex-col gap-4 px-2 pt-2">
      <label
        class="flex select-none"
        hlmLabel>
        <hlm-switch
          class="mr-2"
          [checked]="showGeneralInfos()"
          (changed)="onChangeGeneralInfos($event)" />
        {{ 'filteroptions.showGeneralInformation' | translate }}
      </label>
      @if (showGeneralInfos()) {
        <div class="@xl:flex-row elite-h-scrollbar flex flex-col gap-4 pb-2">
          @for (info of createdInfos(); track $index) {
            <div
              class="border-border @xl:h-auto flex h-full min-h-12 w-full items-center rounded-lg border-2 p-2 shadow">
              <div class="flex h-full w-full flex-col">
                @if (info.title.length) {
                  <div class="elite-text-bold">
                    {{ info.title }}
                  </div>
                }
                {{ info.text }}
              </div>
            </div>
          }
        </div>
      }
    </div>
  } `,
})
export class GeneralInformationsComponent {
  #store = inject(GuestAppStore);

  showGeneralInfos = computed(() => this.#store.filterOptions().showGeneralInfos);

  createdInfos = computed(() => {
    const newInfos = [];
    const generalInfos: GeneralInformation[] = this.#store.user().generalinformations;
    if (generalInfos) {
      for (const generalInfo of generalInfos) {
        const newInfo: GeneralInfo = {
          title: getText(generalInfo.title, this.#store.language()),
          text: getText(generalInfo.text, this.#store.language()),
        };
        newInfos.push(newInfo);
      }
    }
    return newInfos;
  });

  onChangeGeneralInfos(event: boolean): void {
    this.#store.setShowGeneralInfos(event);
  }
}
