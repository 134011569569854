import { DOCUMENT } from '@angular/common';
import { Injectable, RendererFactory2, computed, inject, signal } from '@angular/core';
import { LocalStorageService } from '../../local-storage.service';
import { OptionItem } from '../../models/option-select';

export interface UISettings {
  minimizedMenu: boolean;
  lowCPU: boolean;
  animation: string;
  mode: string;
  showTooltips: boolean;
  tooltipKeyControl: boolean;
  tooltipDelay: number;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalUISettingsService {
  #localStorage = inject(LocalStorageService);
  #_renderer = inject(RendererFactory2).createRenderer(null, null);
  #_document = inject(DOCUMENT);
  
  readonly storageName = 'localUISettings';
  
  #defaultMode = 1; //TODO wär super wenn man den defaultMode für Theme beim injecten mitgeben könnte...

  isMinimizedMenuS = computed(() => this.#minimizedMenuS());
  #minimizedMenuS = signal(false);
  toggleMinimizedMenu(): void {
    this.#minimizedMenuS.set(!this.#minimizedMenuS());
    this.#saveSettings();
  }

  #showTooltips = true;
  get ShowTooltips(): boolean {
    return this.#showTooltips;
  }
  set ShowTooltips(v: boolean) {
    this.#showTooltips = v;
    this.#saveSettings();
  }

  #tooltipKeyControl = false;
  get TooltipKeyControl(): boolean {
    return this.#tooltipKeyControl;
  }
  set TooltipKeyControl(v: boolean) {
    this.#tooltipKeyControl = v;
    this.#saveSettings();
  }

  #tooltipDelay = 600;
  get TooltipDelay(): number {
    return this.#tooltipDelay;
  }
  set TooltipDelay(v: number) {
    this.#tooltipDelay = v;
    this.#saveSettings();
  }

  #lowCPU = true;
  get LowCPU(): boolean {
    return this.#lowCPU;
  }
  set LowCPU(v: boolean) {
    this.#lowCPU = v;
    this.#saveSettings();
  }

  readonly animations: OptionItem[] = [
    { label: 'none', value: '' },
    { label: 'fade', value: 'animate-fadeIn' },
    { label: 'zoom', value: 'animate-zoomIn' },
    { label: 'slide', value: 'animate-slideIn' },
  ];
  #selectedAnimation: OptionItem = this.animations[0];
  get SelectedAnimation(): OptionItem {
    return this.#selectedAnimation;
  }
  set SelectedAnimation(event: OptionItem) {
    this.#selectedAnimation = event;
    this.#saveSettings();
  }

  readonly mode: OptionItem[] = [
    { label: 'dark', value: 'dark' },
    { label: 'light', value: 'light' },
    { label: 'system', value: 'system' },
  ];
  #selectedMode: OptionItem = this.mode[this.#defaultMode];
  get SelectedMode(): OptionItem {
    return this.#selectedMode;
  }
  set SelectedMode(event: OptionItem) {
    this.#selectedMode = event;
    this.#setMode();
    this.#saveSettings();
  }
  readonly isDarkModeS = signal(false);

  constructor() {
    const localStoreSettingsString = this.#localStorage.getItem(this.storageName);
    if (localStoreSettingsString === null) {
      this.SelectedMode = this.mode[this.#defaultMode];
      return;
    }
    const savedSettings = JSON.parse(localStoreSettingsString);
    this.SelectedAnimation =
      this.animations.find((option) => savedSettings.animation === option.value) ?? this.animations[0];
    this.SelectedMode = this.mode.find((option) => savedSettings.mode === option.value) ?? this.mode[0];
    this.LowCPU = savedSettings.lowCPU;
    this.ShowTooltips = savedSettings.showTooltips;
    this.#tooltipKeyControl = savedSettings.tooltipKeyControl;
    this.TooltipDelay = savedSettings.tooltipDelay;
    this.#minimizedMenuS.set(savedSettings.minimizedMenu);
  }

  #setMode(): void {
    let mode = 'light';
    if (this.#selectedMode.value === 'system') {
      const isSystemDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (isSystemDark) {
        mode = 'dark';
      }
    } else {
      mode = this.#selectedMode.value;
    }
    if (mode === 'dark') {
      this.isDarkModeS.set(true);
      this.#_renderer.addClass(this.#_document.documentElement, 'dark');
    } else {
      this.isDarkModeS.set(false);
      if (this.#_document.documentElement.className.includes('dark')) {
        this.#_renderer.removeClass(this.#_document.documentElement, 'dark');
      }
    }
  }

  changeMode(): void {
    if (this.SelectedMode.value === 'dark' || this.SelectedMode.value === 'system') {
      this.SelectedMode = this.mode[1];
    } else {
      this.SelectedMode = this.mode[0];
    }
  }

  #saveSettings(): void {
    const newSettings: UISettings = {
      lowCPU: this.#lowCPU,
      animation: this.SelectedAnimation.value,
      mode: this.SelectedMode.value,
      showTooltips: this.#showTooltips,
      tooltipKeyControl: this.#tooltipKeyControl,
      tooltipDelay: this.#tooltipDelay,
      minimizedMenu: this.#minimizedMenuS(),
    };
    this.#localStorage.setItem(this.storageName, JSON.stringify(newSettings));
  }

  resetSettings(): void {
    this.#localStorage.removeItem(this.storageName);
    this.#lowCPU = true;
    this.#showTooltips = true;
    this.#tooltipKeyControl = false;
    this.#tooltipDelay = 300;
    this.SelectedAnimation = this.animations[0];
    this.SelectedMode = this.mode[0];
    this.#minimizedMenuS.set(false);
  }
}
