import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, inject, signal } from '@angular/core';
import { TransformTailwindColor } from '@shared/util-global';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, HlmIconComponent, TransformTailwindColor],
  selector: 'global-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent {
  public elRef = inject(ElementRef);

  @Input() public text = '';
  @Input() public timeout = 0;
  @Input() public showBar = false;
  @Input() set type(type: string) {
    this.setType(type);
  }

  public icon = '';
  public iconStroke: string | number | undefined = 1.5;
  public iconSize = '1.5rem';
  public iconColor = '';

  private pause = false;
  private timer = 0;
  public counterS = signal(0);
  public onDestroyingS = signal(false);

  @HostBinding('class') public class = 'animate-moveInRight';

  private setType(type: string): void {
    switch (type) {
      case 'warning':
        this.icon = 'featherAlertCircle';
        this.iconColor = 'warning';
        break;
      case 'error':
        this.icon = 'featherAlertTriangle';
        this.iconColor = 'destructive';
        break;
      case 'success':
        this.icon = 'featherCheck';
        this.iconColor = 'primary';
        break;
      case 'info':
        this.icon = 'featherInfo';
        this.iconColor = 'warning';
        break;

      default:
        break;
    }
    this.timer = setInterval(() => {
      this.counterS.update((value) => {
        if (this.pause) {
          return value;
        } else {
          return value + 16;
        }
      });
      if (this.counterS() >= this.timeout) {
        this.onClose();
      }
    }, 16);
  }

  public onClick(): void {
    this.showBar = false;
    clearInterval(this.timer);
  }

  public onLeave(): void {
    this.pause = false;
  }

  public onEnter(): void {
    this.timeout = 750;
    this.counterS.set(0);
    this.pause = true;
  }

  public onClose(): void {
    this.onDestroyingS.set(true);
    setTimeout(() => {
      clearInterval(this.timer);
      this.elRef.nativeElement.remove();
    }, 200);
  }
}
