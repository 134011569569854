/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { GuestAppStore } from '../../stores/guest-app.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, HlmIconComponent, HlmButtonDirective],
  selector: 'guest-app-header-menu',
  template: `
    <hlm-icon
      size="lg"
      name="tablerUserCircle"
      class="cursor-pointer">
    </hlm-icon>
    <div
      class="border-border bg-background animate-fadeIn absolute z-50 hidden -translate-x-3/4 translate-y-2/3 flex-col rounded-lg border shadow group-hover:flex">
      @if (hasDataProtection()) {
        <button
          hlmBtn
          class="flex w-full justify-start gap-2"
          (click)="onDataProtection()"
          variant="ghost">
          <hlm-icon
            size="base"
            name="tablerShieldSearch" />
          {{ 'headerline.dataprotection' | translate }}
        </button>
      }
      @if (!isGuestLogin()) {
        <button
          hlmBtn
          class="flex w-full justify-start gap-2"
          (click)="onOpenSettings()"
          variant="ghost">
          <hlm-icon
            size="base"
            name="featherSettings" />
          {{ 'headerline.settings' | translate }}
        </button>
      }
      <button
        hlmBtn
        class="flex w-full justify-start gap-2"
        (click)="onLogout()"
        variant="ghost">
        <hlm-icon
          size="base"
          [name]="isGuestLogin() ? 'featherLogOut' : 'featherLogIn'" />
        @if (isGuestLogin()) {
          {{ 'headerline.login' | translate }}
        } @else {
          {{ 'headerline.logout' | translate }}
        }
      </button>
    </div>
  `,
  host: { class: 'group flex items-center' },
})
export class HeaderMenuComponent {
  #store = inject(GuestAppStore);
  #router = inject(Router);

  isGuestLogin = computed(() => this.#store.isGuestLogin());
  hasDataProtection = computed<boolean>(() => this.#store.user().dataprotectionlink !== '');

  onLogout(): void {
    this.#store.logout();
    this.#router.navigate(['/login']);
  }

  onOpenSettings(): void {
    this.#router.navigate(['/settings']);
  }

  onDataProtection(): void {
    window.open(this.#store.user().dataprotectionlink, '_blank');
  }
}
