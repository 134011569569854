//NOTICE: Hier Icons importieren die verwendet werden. Müssen an 3 stellen hinzugefügt werden!
import {
  heroArrowDownOnSquare,
  heroArrowDownOnSquareStack,
  heroFingerPrint,
  heroHome,
  heroInboxArrowDown,
  heroPencil,
  heroPencilSquare,
  heroQrCode,
  heroWrenchScrewdriver,
} from '@ng-icons/heroicons/outline';

import {
  featherAlertCircle,
  featherAlertTriangle,
  featherArrowDownCircle,
  featherArrowLeftCircle,
  featherArrowRightCircle,
  featherArrowUpCircle,
  featherCalendar,
  featherCheck,
  featherCheckCircle,
  featherChevronDown,
  featherChevronLeft,
  featherChevronRight,
  featherChevronUp,
  featherChevronsDown,
  featherChevronsLeft,
  featherChevronsRight,
  featherChevronsUp,
  featherClock,
  featherCopy,
  featherDatabase,
  featherDelete,
  featherEdit,
  featherFileText,
  featherFilter,
  featherHelpCircle,
  featherImage,
  featherInfo,
  featherKey,
  featherList,
  featherLogIn,
  featherLogOut,
  featherMaximize,
  featherMenu,
  featherMinimize,
  featherMinus,
  featherMinusCircle,
  featherMoreHorizontal,
  featherMoreVertical,
  featherMove,
  featherPauseCircle,
  featherPhone,
  featherPieChart,
  featherPlayCircle,
  featherPlus,
  featherPlusCircle,
  featherPrinter,
  featherRefreshCcw,
  featherSave,
  featherSearch,
  featherServer,
  featherSettings,
  featherSlash,
  featherStopCircle,
  featherToggleLeft,
  featherToggleRight,
  featherTool,
  featherTrash2,
  featherType,
  featherUser,
  featherX,
  featherXCircle,
  featherZoomIn,
  featherZoomOut,
} from '@ng-icons/feather-icons';

import {
  tablerArrowBackUp,
  tablerArrowForwardUp,
  tablerBackspace,
  tablerBug,
  tablerBulb,
  tablerBulbOff,
  tablerCalendar,
  tablerCalendarCheck,
  tablerCalendarDue,
  tablerCalendarEvent,
  tablerCalendarStar,
  tablerCaretDown,
  tablerCaretLeftFilled,
  tablerCaretRightFilled,
  tablerCaretUp,
  tablerCloudDownload,
  tablerColorSwatch,
  tablerDashboard,
  tablerDatabase,
  tablerDatabaseExclamation,
  tablerDiscount2,
  tablerFilter,
  tablerFilterCog,
  tablerHome,
  tablerLayoutBoardSplit,
  tablerLayoutBottombarCollapseFilled,
  tablerLayoutNavbarExpand,
  tablerLayoutSidebarRightCollapse,
  tablerLoader2,
  tablerMinus,
  tablerMoodConfuzedFilled,
  tablerMoon,
  tablerNumber0,
  tablerNumber1,
  tablerNumber2,
  tablerNumber3,
  tablerNumber4,
  tablerNumber5,
  tablerNumber6,
  tablerNumber7,
  tablerNumber8,
  tablerNumber9,
  tablerPointFilled,
  tablerRefresh,
  tablerRefreshAlert,
  tablerReload,
  tablerReportMoney,
  tablerSend,
  tablerShieldSearch,
  tablerSortAscendingLetters,
  tablerSortDescendingLetters,
  tablerStar,
  tablerStarFilled,
  tablerSunHigh,
  tablerUserCircle,
  tablerWindowMaximize,
  tablerWindowMinimize,
  tablerWorld,
} from '@ng-icons/tabler-icons';

import { lucideClipboardCopy, lucideClipboardPaste } from '@ng-icons/lucide';

export const iconList = [
  'featherAlertCircle',
  'featherAlertTriangle',
  'featherArrowDownCircle',
  'featherArrowLeftCircle',
  'featherArrowRightCircle',
  'featherArrowUpCircle',
  'featherCalendar',
  'featherCheck',
  'featherCheckCircle',
  'featherChevronDown',
  'featherChevronLeft',
  'featherChevronRight',
  'featherChevronUp',
  'featherChevronsDown',
  'featherChevronsLeft',
  'featherChevronsRight',
  'featherChevronsUp',
  'featherClock',
  'featherCopy',
  'featherDatabase',
  'featherDelete',
  'featherEdit',
  'featherFileText',
  'featherFilter',
  'featherHelpCircle',
  'featherImage',
  'featherInfo',
  'featherKey',
  'featherLogIn',
  'featherLogOut',
  'featherList',
  'featherMaximize',
  'featherMinimize',
  'featherMinus',
  'featherMenu',
  'featherMinusCircle',
  'featherPlusCircle',
  'featherMoreHorizontal',
  'featherMoreVertical',
  'featherMove',
  'featherPhone',
  'featherPieChart',
  'featherPlayCircle',
  'featherPlus',
  'featherPauseCircle',
  'featherPrinter',
  'featherRefreshCcw',
  'featherSave',
  'featherSearch',
  'featherServer',
  'featherSettings',
  'featherSlash',
  'featherStopCircle',
  'featherToggleLeft',
  'featherToggleRight',
  'featherTool',
  'featherTrash2',
  'featherType',
  'featherUser',
  'featherX',
  'featherXCircle',
  'featherZoomIn',
  'featherZoomOut',
  'heroFingerPrint',
  'heroWrenchScrewdriver',
  'heroArrowDownOnSquareStack',
  'heroArrowDownOnSquare',
  'heroHome',
  'heroPencil',
  'heroPencilSquare',
  'heroQrCode',
  'heroInboxArrowDown',
  'tablerCalendarEvent',
  'tablerCalendarStar',
  'tablerFilter',
  'tablerFilterCog',
  'tablerColorSwatch',
  'tablerLayoutBoardSplit',
  'tablerWindowMaximize',
  'tablerWindowMinimize',
  'tablerCalendar',
  'tablerCalendarDue',
  'tablerDashboard',
  'tablerBug',
  'tablerCaretDown',
  'tablerCaretUp',
  'tablerSunHigh',
  'tablerMoon',
  'tablerSend',
  'tablerArrowBackUp',
  'tablerHome',
  'tablerLayoutNavbarExpand',
  'tablerCloudDownload',
  'tablerLoader2',
  'tablerDatabase',
  'tablerDatabaseExclamation',
  'tablerNumber0',
  'tablerNumber1',
  'tablerNumber2',
  'tablerNumber3',
  'tablerNumber4',
  'tablerNumber5',
  'tablerNumber6',
  'tablerNumber7',
  'tablerNumber8',
  'tablerNumber9',
  'tablerBackspace',
  'tablerBulb',
  'tablerBulbOff',
  'tablerReload',
  'tablerRefresh',
  'tablerRefreshAlert',
  'tablerDiscount2',
  'tablerMoodConfuzedFilled',
  'tablerArrowForwardUp',
  'tablerStar',
  'tablerStarFilled',
  'tablerUserCircle',
  'tablerShieldSearch',
  'tablerCalendarCheck',
  'tablerSortAscendingLetters',
  'tablerSortDescendingLetters',
  'tablerPointFilled',
  'tablerCaretRightFilled',
  'tablerCaretLeftFilled',
  'tablerMinus',
  'tablerLayoutSidebarRightCollapse',
  'tablerLayoutBottombarCollapseFilled',
  'tablerReportMoney',
  'tablerWorld',
  'lucideClipboardPaste',
  'lucideClipboardCopy',
];

export const ngIconsRecord: Record<string, string> = {
  featherAlertCircle,
  featherAlertTriangle,
  featherArrowDownCircle,
  featherArrowLeftCircle,
  featherArrowRightCircle,
  featherArrowUpCircle,
  featherCalendar,
  featherCheck,
  featherCheckCircle,
  featherChevronDown,
  featherChevronLeft,
  featherChevronRight,
  featherChevronUp,
  featherChevronsDown,
  featherChevronsLeft,
  featherChevronsRight,
  featherChevronsUp,
  featherClock,
  featherCopy,
  featherDatabase,
  featherDelete,
  featherEdit,
  featherFileText,
  featherFilter,
  featherHelpCircle,
  featherImage,
  featherInfo,
  featherKey,
  featherLogIn,
  featherLogOut,
  featherList,
  featherMaximize,
  featherMinimize,
  featherMinus,
  featherMenu,
  featherMinusCircle,
  featherPlusCircle,
  featherMoreHorizontal,
  featherMoreVertical,
  featherMove,
  featherPhone,
  featherPieChart,
  featherPlayCircle,
  featherPlus,
  featherPauseCircle,
  featherPrinter,
  featherRefreshCcw,
  featherSave,
  featherSearch,
  featherServer,
  featherSettings,
  featherSlash,
  featherStopCircle,
  featherToggleLeft,
  featherToggleRight,
  featherTool,
  featherTrash2,
  featherType,
  featherUser,
  featherX,
  featherXCircle,
  featherZoomIn,
  featherZoomOut,
  heroFingerPrint,
  heroWrenchScrewdriver,
  heroArrowDownOnSquareStack,
  heroArrowDownOnSquare,
  heroHome,
  heroPencil,
  heroPencilSquare,
  heroQrCode,
  heroInboxArrowDown,
  tablerCalendarEvent,
  tablerCalendarStar,
  tablerFilter,
  tablerFilterCog,
  tablerColorSwatch,
  tablerLayoutBoardSplit,
  tablerWindowMaximize,
  tablerWindowMinimize,
  tablerCalendar,
  tablerCalendarDue,
  tablerDashboard,
  tablerBug,
  tablerCaretDown,
  tablerCaretUp,
  tablerSunHigh,
  tablerMoon,
  tablerSend,
  tablerArrowBackUp,
  tablerHome,
  tablerLayoutNavbarExpand,
  tablerCloudDownload,
  tablerLoader2,
  tablerDatabase,
  tablerDatabaseExclamation,
  tablerNumber0,
  tablerNumber1,
  tablerNumber2,
  tablerNumber3,
  tablerNumber4,
  tablerNumber5,
  tablerNumber6,
  tablerNumber7,
  tablerNumber8,
  tablerNumber9,
  tablerBackspace,
  tablerBulb,
  tablerBulbOff,
  tablerReload,
  tablerRefresh,
  tablerRefreshAlert,
  tablerDiscount2,
  tablerMoodConfuzedFilled,
  tablerArrowForwardUp,
  tablerStar,
  tablerStarFilled,
  tablerUserCircle,
  tablerShieldSearch,
  tablerCalendarCheck,
  tablerSortAscendingLetters,
  tablerSortDescendingLetters,
  tablerPointFilled,
  tablerCaretRightFilled,
  tablerCaretLeftFilled,
  tablerMinus,
  tablerLayoutSidebarRightCollapse,
  tablerLayoutBottombarCollapseFilled,
  tablerReportMoney,
  tablerWorld,
  lucideClipboardPaste,
  lucideClipboardCopy,
};
