import { Texts } from '../models/models';

export function getText(texts: Texts[], language: string): string {
  if (!texts || texts.length === 0) {
    return '';
  }

  const textelement = texts.find((element) => element.lang.toString() === language);
  const result = textelement?.text;

  if (result) {
    return result;
  }

  return texts[0].text;
}
