import { QueryObserverResult } from '@ngneat/query';
import { Result } from '@ngneat/query/lib/types';
import { AppointmentInfo, ModelBase, User } from '../../models/models';

export abstract class AppointmentServiceAbstract {
  abstract getAppointments(
    user: User,
    selectedDate: Date,
    onError: () => void,
  ): Result<QueryObserverResult<AppointmentInfo, Error>>;
  abstract overlapAppointments(first: ModelBase, second: ModelBase): boolean;
  abstract overlapWithPrivateAppointment(appointment: ModelBase): boolean;
}
