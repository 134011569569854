<div
  emblaCarousel
  class="w-full overflow-hidden"
  [options]="emblaOptions"
  [eventsThrottleTime]="1">
  <div class="flex-start flex">
    @for (day of days(); track day; let i = $index) {
      <guest-app-bookable-items-day
        [attr.id]="'day' + i"
        (bookItemClicked)="onOpenDialog($event)"
        [day]="day()" />
    }
  </div>
</div>

<hlm-alert-dialog
  #formularDialogID
  [closeOnOutsidePointerEvents]="false">
  <hlm-alert-dialog-content
    *brnAlertDialogContent="let ctx"
    class="elite-v-scrollbar mx-auto max-h-screen min-w-96 max-w-[96%] select-none">
    <hlm-alert-dialog-header>
      <div class="text-primary elite-text-strong">
        {{ selectedBookAbleItemTitle() }}
      </div>
      <div class="text-primary elite-text-strong">
        {{ selectedBookAbleItemTime() | formatDate: dateFormat() : locale() }}
        {{ 'bookableItem.time' | translate }}
      </div>
      <div class="elite-text-small pb-2">{{ 'bookableItem.enterData' | translate }}.</div>
    </hlm-alert-dialog-header>
    <hlm-alert-dialog-footer>
      <form
        [formGroup]="dataForm"
        class="flex w-full flex-col gap-2">
        <div>
          <label hlmLabel
            >{{ 'bookableItem.givenname' | translate }}
            <input
              hlmInput
              required="true"
              formControlName="givenname"
              type="text"
              autocomplete="given-name"
              class="w-full" />
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.surname' | translate }}
            <input
              hlmInput
              required="true"
              formControlName="surname"
              type="text"
              autocomplete="family-name"
              class="w-full" />
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.birthday' | translate }}
            <hlm-icon
              name="tablerCalendarEvent"
              class="absolute right-8 translate-y-8 cursor-pointer"
              (click)="birthdayInputID.showPicker()"></hlm-icon>
            <input
              hlmInput
              #birthdayInputID
              required="true"
              formControlName="birthday"
              type="date"
              autocomplete="bday"
              class="after:content w-full" />
            <!-- <button hlmBtn (click)="onSelectDate('birthday')" variant="outline" [ngClass]="
                dataForm.invalid && dataForm.controls.birthday.touched && dataForm.controls.birthday.invalid
                  ? '!border-destructive'
                  : ''
              " class="w-full justify-start">
              {{ this.dataForm.controls.birthday.value | formatDate: 'PPP' }}
            </button> -->
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.email' | translate }}
            <input
              hlmInput
              (ngModelChange)="onEmailChange($event)"
              [required]="!hasTel()"
              formControlName="email"
              autocomplete="email"
              [email]="true"
              type="email"
              class="w-full" />
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.tel' | translate }}
            <input
              hlmInput
              (ngModelChange)="onTelChange($event)"
              [required]="!hasEmail()"
              formControlName="tel"
              autocomplete="tel"
              type="tel"
              class="w-full" />
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.roomnumber' | translate }}
            <input
              hlmInput
              formControlName="roomnumber"
              type="text"
              class="w-full" />
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.dateOfResidence' | translate }} {{ 'bookableItem.from' | translate }}
            <hlm-icon
              name="tablerCalendarEvent"
              class="absolute right-8 translate-y-8 cursor-pointer"
              (click)="fromInputID.showPicker()"></hlm-icon>
            <input
              hlmInput
              #fromInputID
              formControlName="from"
              type="date"
              class="w-full" />
            <!-- <button hlmBtn (click)="onSelectDate('from')" variant="outline" class="w-full justify-start">
              {{ this.dataForm.controls.from.value | formatDate: 'PPP' }}
            </button> -->
          </label>
        </div>
        <div>
          <label hlmLabel
            >{{ 'bookableItem.dateOfResidence' | translate }} {{ 'bookableItem.to' | translate }}
            <hlm-icon
              name="tablerCalendarEvent"
              class="absolute right-8 translate-y-8 cursor-pointer"
              (click)="toInputID.showPicker()"></hlm-icon>
            <input
              hlmInput
              #toInputID
              formControlName="to"
              type="date"
              class="w-full" />
            <!-- <button hlmBtn (click)="onSelectDate('to')" variant="outline" class="w-full justify-start">
              {{ this.dataForm.controls.to.value | formatDate: 'PPP' }}
            </button> -->
          </label>
        </div>
        <div class="flex flex-row-reverse gap-2">
          <button
            hlmBtn
            (click)="onBookItemForGuest()"
            class="mt-6 w-full">
            {{ 'bookableItem.booknow' | translate }}
          </button>
          <button
            hlmBtn
            (click)="ctx.close()"
            variant="outline"
            class="mt-6 w-full">
            {{ 'bookableItem.cancel' | translate }}
          </button>
        </div>
      </form>
    </hlm-alert-dialog-footer>
  </hlm-alert-dialog-content>
</hlm-alert-dialog>

<hlm-alert-dialog
  #confirmDialogID
  [closeOnOutsidePointerEvents]="true">
  <hlm-alert-dialog-content
    *brnAlertDialogContent="let ctx"
    class="elite-v-scrollbar mx-auto max-h-screen min-w-96 max-w-[96%] select-none">
    <hlm-alert-dialog-header>
      <div class="text-primary elite-text-strong">
        {{ selectedBookAbleItemTitle() }}
      </div>
      <div class="text-primary elite-text-strong">
        {{ selectedBookAbleItemTime() | formatDate: dateFormat() : locale() }}
        {{ 'bookableItem.time' | translate }}
      </div>
      <div class="elite-text-large pb-2">{{ 'bookableItem.bindBooking' | translate }}?</div>
    </hlm-alert-dialog-header>
    <hlm-alert-dialog-footer>
      <div class="flex w-full flex-row-reverse justify-around gap-4">
        <button
          hlmBtn
          (click)="onBookItemForUser()"
          variant="default"
          class="w-full">
          {{ 'common.YES' | translate }}
        </button>
        <button
          hlmBtn
          (click)="ctx.close()"
          variant="outline"
          class="w-full">
          {{ 'common.NO' | translate }}
        </button>
      </div>
    </hlm-alert-dialog-footer>
  </hlm-alert-dialog-content>
</hlm-alert-dialog>

<hlm-alert-dialog
  #successDialogID
  [closeOnOutsidePointerEvents]="true">
  <button
    brnAlertDialogTrigger
    class="hidden"></button>
  <hlm-alert-dialog-content
    *brnAlertDialogContent="let ctx"
    class="elite-v-scrollbar mx-auto max-h-screen min-w-96 max-w-[96%] select-none">
    <hlm-alert-dialog-header>
      <div class="flex w-full flex-col items-center gap-4">
        <hlm-icon
          name="tablerCalendarCheck"
          class="text-primary"
          size="xl">
        </hlm-icon>
        <div class="max-w-[80%] text-center">
          @if (isGuestLogin()) {
            {{ 'bookableItem.successText' | translate }}.
          } @else {
            {{ bookableSuccsessText() }}
          }
        </div>
      </div>
    </hlm-alert-dialog-header>
    <hlm-alert-dialog-footer>
      <button
        hlmBtn
        (click)="ctx.close()"
        variant="outline">
        {{ 'common.close' | translate }}
      </button>
    </hlm-alert-dialog-footer>
  </hlm-alert-dialog-content>
</hlm-alert-dialog>

<hlm-alert-dialog
  #failDialogID
  [closeOnOutsidePointerEvents]="true">
  <button
    brnAlertDialogTrigger
    class="hidden"></button>
  <hlm-alert-dialog-content
    *brnAlertDialogContent="let ctx"
    class="elite-v-scrollbar mx-auto max-h-screen min-w-96 max-w-[96%] select-none">
    <hlm-alert-dialog-header>
      <div class="flex w-full flex-col items-center gap-4">
        <hlm-icon
          name="featherAlertTriangle"
          class="text-destructive"
          size="xl">
        </hlm-icon>
        <div class="max-w-[80%] text-center">
          {{ failMessage() }}
        </div>
      </div>
    </hlm-alert-dialog-header>
    <hlm-alert-dialog-footer>
      <button
        hlmBtn
        (click)="ctx.close()"
        variant="outline">
        {{ 'common.close' | translate }}
      </button>
    </hlm-alert-dialog-footer>
  </hlm-alert-dialog-content>
</hlm-alert-dialog>

<!-- <hlm-alert-dialog #datePickerDialogID [closeOnOutsidePointerEvents]="true">
  <button brnAlertDialogTrigger class="hidden"></button>
  <hlm-alert-dialog-content *brnAlertDialogContent="let ctx"
    class="elite-v-scrollbar mx-auto max-h-screen min-w-80 max-w-[96%] select-none">
    <global-date-picker (selectedDate)="onSetNewDate($event)" [preSelectedDate]="preSelectedDate()">
    </global-date-picker>
  </hlm-alert-dialog-content>
</hlm-alert-dialog> -->
