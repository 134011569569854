/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '@shared/ui-global';
import { FormatDatePipe, ToasterService } from '@shared/util-global';
import {
  BrnAlertDialogComponent,
  BrnAlertDialogContentDirective,
  BrnAlertDialogTriggerDirective,
} from '@spartan-ng/ui-alertdialog-brain';
import {
  HlmAlertDialogActionButtonDirective,
  HlmAlertDialogCancelButtonDirective,
  HlmAlertDialogComponent,
  HlmAlertDialogContentComponent,
  HlmAlertDialogDescriptionDirective,
  HlmAlertDialogFooterComponent,
  HlmAlertDialogHeaderComponent,
  HlmAlertDialogOverlayDirective,
  HlmAlertDialogTitleDirective,
} from '@spartan-ng/ui-alertdialog-helm';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { addDays } from 'date-fns';
import { EmblaCarouselDirective, EmblaCarouselType, EmblaOptionsType } from 'embla-carousel-angular';
import { getText } from '../../helper/get-text';
import { BookableServiceAbstract } from '../../services/abstract/bookable-abstract.service';
import { ImageLoadedService } from '../../services/image.loaded.service';
import { GuestAppStore } from '../../stores/guest-app.store';
import { BookableItemComponent } from '../bookable-item/bookable-item.component';
import { BookableItemsDayComponent } from '../bookable-items-day/bookable-items-day.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'guest-app-bookable-items',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BookableItemComponent,
    TranslateModule,
    HlmInputDirective,
    HlmButtonDirective,
    BrnAlertDialogTriggerDirective,
    BrnAlertDialogContentDirective,
    HlmAlertDialogComponent,
    HlmAlertDialogOverlayDirective,
    HlmAlertDialogHeaderComponent,
    HlmAlertDialogFooterComponent,
    HlmAlertDialogTitleDirective,
    HlmAlertDialogDescriptionDirective,
    HlmAlertDialogCancelButtonDirective,
    HlmAlertDialogActionButtonDirective,
    HlmAlertDialogContentComponent,
    HlmIconComponent,
    BookableItemsDayComponent,
    EmblaCarouselDirective,
    DatePickerComponent,
    FormatDatePipe,
  ],
  templateUrl: './bookable-items.component.html',
  host: { class: 'flex justify-center h-full' },
})
export class BookableItemsComponent {
  #store = inject(GuestAppStore);
  #toaster = inject(ToasterService);
  #bookableService = inject(BookableServiceAbstract);
  #detector = inject(ChangeDetectorRef);
  #imageLoadedService = inject(ImageLoadedService);

  emblaOptions: EmblaOptionsType = {
    loop: true,
  };

  emblaCarousel = viewChild(EmblaCarouselDirective);
  dayContainers = viewChildren(BookableItemsDayComponent, { read: ElementRef });
  #emblaApi: EmblaCarouselType | undefined = undefined;
  readonly selectedIndex = signal(0);
  readonly visibleIndexes = signal<number[]>([0]);
  readonly days = signal([
    signal(new Date()),
    signal(addDays(new Date(), 1)),
    signal(addDays(new Date(), 2)),
    signal(addDays(new Date(), -2)),
    signal(addDays(new Date(), -1)),
  ]);

  @ViewChild('formularDialogID') formularDialog!: BrnAlertDialogComponent;
  @ViewChild('confirmDialogID') confirmDialog!: BrnAlertDialogComponent;
  @ViewChild('successDialogID') successDialog!: BrnAlertDialogComponent;
  @ViewChild('failDialogID') failDialog!: BrnAlertDialogComponent;
  // @ViewChild('datePickerDialogID') datePickerDialog!: BrnAlertDialogComponent;

  readonly selectedDate = computed(() => this.#store.getSelectedDate());
  readonly selectedBookAbleItem = computed(() => {
    const bookableItemsWithAllTimes = this.#store.groupedBookablesByDay(this.selectedDate()).find((bookablesItems) => {
      return bookablesItems.find((bookableItem) => {
        return bookableItem.id === this.#selectedIdOfBookable();
      });
    });
    return bookableItemsWithAllTimes?.find((bookable) => bookable.id === this.#selectedIdOfBookable());
  });
  readonly selectedBookAbleItemTitle = computed(() => {
    return getText(this.selectedBookAbleItem()?.title ?? [], this.#store.language());
  });
  readonly selectedBookAbleItemTime = computed(() => {
    return this.selectedBookAbleItem()?.fromTime;
  });
  readonly locale = computed(() => this.#store.locale());
  readonly dateFormat = computed(() => {
    if (this.#store.language() === 'de') {
      return 'dd. MMMM H:mm';
    } else {
      return 'MMMM dd, h:mm a';
    }
  });

  readonly isGuestLogin = computed(() => this.#store.isGuestLogin());
  readonly bookableSuccsessText = signal<string>('');

  readonly hasTel = signal(false);
  readonly hasEmail = signal(false);
  readonly failMessage = signal('');
  dataForm = new FormGroup({
    givenname: new FormControl(),
    surname: new FormControl(),
    birthday: new FormControl(),
    roomnumber: new FormControl(),
    email: new FormControl(),
    tel: new FormControl(),
    from: new FormControl(),
    to: new FormControl(),
  });
  #selectedIdOfBookable = signal(0);
  // protected whichDate = signal<'birthday' | 'from' | 'to'>('birthday');
  // protected preSelectedDate = signal(new Date('2000-01-01'));

  constructor() {
    // this.dataForm.controls['birthday'].addValidators([Validators.required]);

    this.visibleIndexes.set([this.selectedIndex()]);

    this.#imageLoadedService.imagesLoading$.pipe(takeUntilDestroyed()).subscribe((loading) => {
      // when no image is loading, reset the height of the carousel
      if (loading === 0) {
        const emblaApi = this.#emblaApi;
        if (!emblaApi) return;
        emblaApi.containerNode().style.height = '';
        this.#detector.detectChanges();
        this.#setEmblaContainerHeight();
      }
    });

    // this effect is used, when the date is selected by the date picker, then the days array is updated
    effect(() => {
      const selDay = this.selectedDate() ?? new Date();
      untracked(() => {
        // set the day of the selected date to the selected index
        const dayLength = this.days().length;
        const daysHalf = Math.floor(dayLength / 2);
        for (let i = 0; i < dayLength; i++) {
          const offset = i - this.selectedIndex();
          let adjustedOffset;
          if (offset > daysHalf) {
            adjustedOffset = offset - dayLength;
          } else if (offset < -daysHalf) {
            adjustedOffset = offset + dayLength;
          } else {
            adjustedOffset = offset;
          }
          this.days()[i].set(addDays(selDay, adjustedOffset));
        }
      });
    });

    effect(() => {
      if (this.selectedIndex() === -1) return;
      untracked(() => {
        const selectedDay = this.days()[this.selectedIndex()];
        this.#store.setSelectedDate(selectedDay());
      });
    });

    effect(() => {
      if (!this.emblaCarousel() || !this.emblaCarousel()?.emblaApi) {
        return;
      }
      const api = this.emblaCarousel()?.emblaApi;
      if (!api) return;
      this.#emblaApi = api;
      this.#initEngine(api);
      untracked(() => {
        this.#store.connectedEmblaApi(api);
      });
    });
  }

  #onSelect = () => {
    const emblaApi = this.#emblaApi;
    if (!emblaApi) return;
    // first set the height empty to get the correct height of the selected day
    emblaApi.containerNode().style.height = '';
    this.selectedIndex.set(emblaApi.internalEngine().index.get() ?? -1);
    // then set the height of the container to the height of the selected day
    this.#setEmblaContainerHeight();
  };
  #onResize = () => {
    setTimeout(() => {
      const emblaApi = this.#emblaApi;
      if (!emblaApi) return;
      emblaApi.containerNode().style.height = '';
      this.#setEmblaContainerHeight();
    }, 100);
  };

  #initEngine(api: EmblaCarouselType) {
    api.off('select', this.#onSelect);
    api.on('select', this.#onSelect);
    api.off('resize', this.#onResize);
    api.on('resize', this.#onResize);
    this.#setEmblaContainerHeight();
  }

  #setEmblaContainerHeight() {
    const emblaApi = this.#emblaApi;
    if (!emblaApi) return;
    const selectedHeight = Math.max(this.dayContainers()[this.selectedIndex()].nativeElement.offsetHeight, 200);
    emblaApi.containerNode().style.height = selectedHeight + 'px';
  }

  onOpenDialog(id: number): void {
    this.#selectedIdOfBookable.set(id);
    if (this.#store.isGuestLogin()) {
      this.dataForm.markAsUntouched();
      this.formularDialog.open();
    } else {
      this.confirmDialog.open();
    }
  }

  onBookItemForUser(): void {
    this.confirmDialog.close(this.#selectedIdOfBookable());
    this.#bookItemForUser();
  }

  #bookItemForUser(): void {
    this.#bookableService.booking(this.#store.user().username, this.#selectedIdOfBookable()).subscribe({
      next: (result) => {
        if (result.booking_response.status === 'success') {
          this.bookableSuccsessText.set(getText(result.booking_response.responseText ?? [], this.#store.language()));
          this.#store.addAlreadyBooked(this.#selectedIdOfBookable());
          this.successDialog.open();
          this.#detector.detectChanges();
        } else if (result.booking_response.status === 'fail') {
          this.failMessage.set(getText(result.booking_response.errortexts ?? [], this.#store.language()));
          this.failDialog.open();
          this.#detector.detectChanges();
        } else {
          this.#toaster.insertToast({ text: result.booking_response.message ?? '', type: 'error' });
        }
      },
      error: (error: any) => {
        this.#toaster.insertToast({ text: error.message, type: 'error' });
      },
    });
  }

  onBookItemForGuest(): void {
    if (this.dataForm.invalid) {
      this.dataForm.markAllAsTouched();
      return;
    }
    this.formularDialog.close(this.dataForm.value);
    this.#bookItemForGuest();
  }

  #bookItemForGuest(): void {
    this.#bookableService
      .bookingForGuest(
        this.dataForm.controls.givenname.value,
        this.dataForm.controls.surname.value,
        this.#selectedIdOfBookable(),
        this.dataForm.controls.birthday.value,
        this.dataForm.controls.roomnumber.value,
        this.dataForm.controls.tel.value,
        this.dataForm.controls.email.value,
        this.dataForm.controls.from.value,
        this.dataForm.controls.to.value,
      )
      .subscribe({
        next: (result) => {
          if (result.booking_response.status === 'success') {
            this.#store.addAlreadyBooked(this.#selectedIdOfBookable());
            this.successDialog.open();
            this.#detector.detectChanges();
          } else if (result.booking_response.status === 'fail') {
            this.failMessage.set(getText(result.booking_response.errortexts ?? [], this.#store.language()));
            this.failDialog.open();
            this.#detector.detectChanges();
          } else {
            this.#toaster.insertToast({ text: result.booking_response.message ?? '', type: 'error' });
          }
        },
        error: (error: any) => {
          this.#toaster.insertToast({ text: error.message, type: 'error' });
        },
      });
  }

  onTelChange(event: string): void {
    this.hasTel.set(event !== '' && event !== null);
  }

  onEmailChange(event: string): void {
    this.hasEmail.set(event !== '' && event !== null);
  }

  // onSelectDate(which: string): void {
  //   this.whichDate.set(which as 'birthday' | 'from' | 'to');
  //   this.datePickerDialog.open();
  //   switch (which) {
  //     case 'birthday':
  //       this.dataForm.controls.birthday.markAsTouched();
  //       this.preSelectedDate.set(this.dataForm.controls.birthday.value ?? new Date('2000-01-01'));
  //       break;
  //     case 'from':
  //       this.dataForm.controls.from.markAsTouched();
  //       this.preSelectedDate.set(this.dataForm.controls.from.value ?? new Date());
  //       break;
  //     case 'to':
  //       this.dataForm.controls.to.markAsTouched();
  //       this.preSelectedDate.set(this.dataForm.controls.to.value ?? new Date());
  //       break;
  //   }
  // }

  // onSetNewDate(event: Date): void {
  //   const controls = this.dataForm.controls;
  //   switch (this.whichDate()) {
  //     case 'birthday':
  //       controls.birthday.setValue(this.#formatDate.transform(event, 'yyyy-MM-dd'));
  //       break;
  //     case 'from':
  //       controls.from.setValue(this.#formatDate.transform(event, 'yyyy-MM-dd'));
  //       break;
  //     case 'to':
  //       controls.to.setValue(this.#formatDate.transform(event, 'yyyy-MM-dd'));
  //       break;
  //   }
  //   this.datePickerDialog.close(event);
  // }

  isIndexVisible(visibleSlides: number[], index: number): boolean {
    return visibleSlides.includes(index);
  }
}
