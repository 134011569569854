/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  WritableSignal,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { patchState } from '@ngrx/signals';
import { addEntity } from '@ngrx/signals/entities';
import {
  GlobalUISettingsService,
  ToasterService,
  TransformTailwindColor,
  hexToHsl,
  hslToHex,
} from '@shared/util-global';
import { camelize, kebabize } from '@shared/util-strings';
import { BrnAccordionContentComponent } from '@spartan-ng/ui-accordion-brain';
import {
  HlmAccordionContentDirective,
  HlmAccordionDirective,
  HlmAccordionIconDirective,
  HlmAccordionItemDirective,
  HlmAccordionTriggerDirective,
} from '@spartan-ng/ui-accordion-helm';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { HlmLabelDirective } from '@spartan-ng/ui-label-helm';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { SliderComponent } from '../slider/slider.component';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { ThemeColorPickerComponent } from './theme-color-picker.component';
import { ThemeEditorStore } from './theme-editor.store';

interface ThemeColor {
  hue: number;
  saturation: number;
  lightness: number;
}
const emptyThemeColorREF: ThemeColor = {
  hue: 0,
  saturation: 50,
  lightness: 50,
};
const emptyThemeColor: ThemeColor = structuredClone(emptyThemeColorREF);

const fullTemplate: Record<string, ThemeColor> = {
  background: emptyThemeColor,
  foreground: emptyThemeColor,
  card: emptyThemeColor,
  cardForeground: emptyThemeColor,
  popover: emptyThemeColor,
  popoverForeground: emptyThemeColor,
  primary: emptyThemeColor,
  primaryForeground: emptyThemeColor,
  secondary: emptyThemeColor,
  secondaryForeground: emptyThemeColor,
  muted: emptyThemeColor,
  mutedForeground: emptyThemeColor,
  accent: emptyThemeColor,
  accentForeground: emptyThemeColor,
  destructive: emptyThemeColor,
  destructiveForeground: emptyThemeColor,
  border: emptyThemeColor,
  input: emptyThemeColor,
  ring: emptyThemeColor,
  warning: emptyThemeColor,
  warningForeground: emptyThemeColor,
  selection: emptyThemeColor,
};

//TODO localstorage for config
//TODO store for config history
//TODO font style
//TODO config text validator
//TODO copy and paste buttons for colors
//TODO simpleMode Switch bug

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'global-theme-editor',
  standalone: true,
  imports: [
    CommonModule,
    HlmLabelDirective,
    HlmButtonDirective,
    HlmIconComponent,
    SliderComponent,
    HlmAccordionContentDirective,
    HlmAccordionDirective,
    HlmAccordionIconDirective,
    HlmAccordionItemDirective,
    HlmAccordionTriggerDirective,
    BrnAccordionContentComponent,
    HlmInputDirective,
    ThemeColorPickerComponent,
    CheckboxComponent,
    TooltipDirective,
  ],
  host: {
    class: 'w-full h-full flex justify-center items-center bg-background text-foreground selection:bg-selection',
    '[style]': 'computedStyle()',
  },
  templateUrl: './theme-editor.component.html',
})
export class ThemeEditorComponent implements OnInit {
  public uiSettings = inject(GlobalUISettingsService);
  private tailwindColor = inject(TransformTailwindColor);
  private clipboard = inject(Clipboard);
  private toaster = inject(ToasterService);
  private store = inject(ThemeEditorStore);

  public onlySimpleMode = input(false);

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    event.preventDefault();
  }

  public simpleDarkColors = input<Record<string, string[]>>({
    Akzent: ['primary', 'ring'],
    Hintergrund: ['background'],
    Text: ['foreground', 'cardForeground', 'popoverForeground', 'secondaryForeground', 'destructiveForeground'],
    Karten: ['card', 'popover', 'secondary', 'accent'],
    Akzenttext: ['primaryForeground', 'warningForeground'],
    Zweittext: ['accentForeground'],
    Ränder: ['muted', 'border'],
    Stummtext: ['mutedForeground'],
    Destruktiv: ['destructive'],
    Eingabe: ['input'],
    Warnung: ['warning'],
    Auswahl: ['selection'],
  });
  public simpleLightColors = input<Record<string, string[]>>({
    Akzent: ['primary'],
    Hintergrund: ['background'],
    Text: ['foreground', 'destructiveForeground', 'warningForeground', 'primaryForeground'],
    Karten: ['card', 'popover'],
    KartenB: ['cardForeground', 'popoverForeground', 'secondaryForeground', 'accentForeground', 'ring'],
    Zweitakzent: ['secondary', 'accent'],
    Ränder: ['muted', 'border'],
    Stummtext: ['mutedForeground'],
    Destruktiv: ['destructive'],
    Eingabe: ['input'],
    Warnung: ['warning'],
    Auswahl: ['selection'],
  });

  public meterMax = computed(() => 96 + 'px');
  public meterValue = computed(() => (96 / (this.store.stackSize() - 1)) * this.store.redoCount() + 'px');
  public canUndo = computed(() => this.store.canUndo());
  public canRedo = computed(() => this.store.canRedo());

  public computedStyle = computed(() => {
    if (this.uiSettings.isDarkModeS()) {
      let computedStyle = '--radius: ' + this.cornerRadius() + 'rem;';
      for (const colorKey of this.keysOf(this.darkTheme())) {
        computedStyle += '--' + kebabize(colorKey) + ': ' + this.composedHSL(this.darkTheme()[colorKey]) + '; ';
      }
      return computedStyle;
    } else {
      let computedStyle = '--radius: ' + this.cornerRadius() + 'rem; ';
      for (const colorKey of this.keysOf(this.lightTheme())) {
        computedStyle += '--' + kebabize(colorKey) + ': ' + this.composedHSL(this.lightTheme()[colorKey]) + '; ';
      }
      return computedStyle;
    }
  });

  public computedStyleFinalized = computed(() => {
    let computedStyle = ':root {\n';
    for (const colorKey of this.keysOf(this.lightTheme())) {
      computedStyle += '   --' + kebabize(colorKey) + ': ' + this.composedHSL(this.lightTheme()[colorKey]) + ';\n';
    }
    computedStyle += '   --radius: ' + this.cornerRadius() + 'rem;\n}\n\n ';
    computedStyle += '.dark {\n';
    for (const colorKey of this.keysOf(this.darkTheme())) {
      computedStyle += '   --' + kebabize(colorKey) + ': ' + this.composedHSL(this.darkTheme()[colorKey]) + ';\n';
    }
    computedStyle += '}\n';
    return computedStyle;
  });

  public cornerRadius = signal(0.5);
  public lightTheme = signal<Record<string, ThemeColor>>({ ...fullTemplate });
  public darkTheme = signal<Record<string, ThemeColor>>({ ...fullTemplate });

  public simpleMode = signal(true);
  public simpleLightTheme = signal<Record<string, ThemeColor>>({});
  public simpleDarkTheme = signal<Record<string, ThemeColor>>({});

  constructor() {
    for (const colorKey of this.keysOf(this.darkTheme())) {
      this.darkTheme()[colorKey] = {
        hue: hexToHsl(this.tailwindColor.transform(kebabize(colorKey), 'dark'))[0],
        saturation: hexToHsl(this.tailwindColor.transform(kebabize(colorKey), 'dark'))[1],
        lightness: hexToHsl(this.tailwindColor.transform(kebabize(colorKey), 'dark'))[2],
      };
    }
    for (const colorKey of this.keysOf(this.lightTheme())) {
      this.lightTheme()[colorKey] = {
        hue: hexToHsl(this.tailwindColor.transform(kebabize(colorKey), 'light'))[0],
        saturation: hexToHsl(this.tailwindColor.transform(kebabize(colorKey), 'light'))[1],
        lightness: hexToHsl(this.tailwindColor.transform(kebabize(colorKey), 'light'))[2],
      };
    }
  }

  public ngOnInit(): void {
    this.setPredefinedSimpleColors();
    this.onMakeSnapshot();
  }

  public undo(): void {
    this.store.undo();
    const lastSnapshot = this.store.entities()[this.store.entities().length - 1].snapshot;
    this.cornerRadius.set(lastSnapshot.cornerRadius);
    this.darkTheme.set({ ...lastSnapshot.darkTheme });
    this.lightTheme.set({ ...lastSnapshot.lightTheme });
    // console.log(this.store.entities());
    // console.log(this.darkTheme()['primary'].hue);
    // patchState(this.store, {
    //   cornerRadius: lastSnapshot.cornerRadius,
    //   darkTheme: lastSnapshot.darkTheme,
    //   lightTheme: lastSnapshot.lightTheme,
    // });
  }

  public redo(): void {
    this.store.redo();
    const lastSnapshot = this.store.entities()[this.store.entities().length - 1].snapshot;
    this.cornerRadius.set(lastSnapshot.cornerRadius);
    this.darkTheme.set({ ...lastSnapshot.darkTheme });
    this.lightTheme.set({ ...lastSnapshot.lightTheme });
    // patchState(this.store, {
    //   cornerRadius: lastSnapshot.cornerRadius,
    //   darkTheme: lastSnapshot.darkTheme,
    //   lightTheme: lastSnapshot.lightTheme,
    // });
  }

  public onMakeSnapshot(): void {
    patchState(
      this.store,
      addEntity({
        id: this.store.entities().length,
        snapshot: {
          cornerRadius: this.cornerRadius(),
          darkTheme: structuredClone(this.darkTheme()),
          lightTheme: structuredClone(this.lightTheme()),
        },
      }),
    );
  }

  private setPredefinedSimpleColors(): void {
    for (const simpleColor of this.keysOf(this.simpleDarkColors())) {
      this.simpleDarkTheme()[simpleColor] = {
        hue: hexToHsl(this.tailwindColor.transform(kebabize(this.simpleDarkColors()[simpleColor][0]), 'dark'))[0],
        saturation: hexToHsl(
          this.tailwindColor.transform(kebabize(this.simpleDarkColors()[simpleColor][0]), 'dark'),
        )[1],
        lightness: hexToHsl(this.tailwindColor.transform(kebabize(this.simpleDarkColors()[simpleColor][0]), 'dark'))[2],
      };
    }
    for (const simpleColor of this.keysOf(this.simpleLightColors())) {
      this.simpleLightTheme()[simpleColor] = {
        hue: hexToHsl(this.tailwindColor.transform(kebabize(this.simpleLightColors()[simpleColor][0]), 'light'))[0],
        saturation: hexToHsl(
          this.tailwindColor.transform(kebabize(this.simpleLightColors()[simpleColor][0]), 'light'),
        )[1],
        lightness: hexToHsl(
          this.tailwindColor.transform(kebabize(this.simpleLightColors()[simpleColor][0]), 'light'),
        )[2],
      };
    }
  }

  public onSetColor(
    dark: boolean,
    element: string,
    key: string,
    theme: WritableSignal<Record<string, ThemeColor>>,
    value: number,
  ): void {
    let keysToSet: string[] = [key];
    if (this.simpleMode()) {
      if (dark) {
        keysToSet = this.simpleDarkColors()[key];
      } else {
        keysToSet = this.simpleLightColors()[key];
      }
    }
    for (const key of keysToSet) {
      if (element === 'hue') {
        theme()[key][element] = value;
      } else if (element === 'saturation') {
        theme()[key].saturation = value;
      } else if (element === 'lightness') {
        theme()[key].lightness = value;
      }
    }
    if (dark) {
      this.darkTheme.set({ ...theme() });
    } else {
      this.lightTheme.set({ ...theme() });
    }
  }

  private composedHSL(themeColor: ThemeColor): string {
    return `${themeColor.hue} ${themeColor.saturation}% ${themeColor.lightness}%`;
  }

  public colorAsHex(dark: boolean, key: string): string {
    let keyToSet = key;
    if (dark) {
      if (this.simpleMode()) {
        keyToSet = this.simpleDarkColors()[key][0];
      }
      return hslToHex(
        this.darkTheme()[keyToSet].hue,
        this.darkTheme()[keyToSet].saturation,
        this.darkTheme()[keyToSet].lightness,
      );
    } else {
      if (this.simpleMode()) {
        keyToSet = this.simpleLightColors()[key][0];
      }
      return hslToHex(
        this.lightTheme()[keyToSet].hue,
        this.lightTheme()[keyToSet].saturation,
        this.lightTheme()[keyToSet].lightness,
      );
    }
  }

  public keysOf(record: Record<string, ThemeColor | string[]>) {
    return Object.keys(record);
  }

  public onSetColorFromHex(key: string, theme: Record<string, ThemeColor>, event: any): void {
    const hexColor = event.target.value.toString();
    const hslColor = hexToHsl(hexColor);
    theme[key].hue = hslColor[0];
    theme[key].saturation = hslColor[1];
    theme[key].lightness = hslColor[2];
    this.onMakeSnapshot();
  }

  public copyToClipboard() {
    this.toaster.insertToast({ text: 'Konfiguration kopiert!', type: 'success' });
    this.clipboard.copy(this.computedStyleFinalized());
  }

  public onConfigTextChange(event: any, clear = false): void {
    setTimeout(() => {
      const sections = event.target.value.split('.dark');
      if (sections.length !== 2) {
        this.toaster.insertToast({ text: 'Die Konfiguration ist unvollständig!', type: 'error' });
        return;
      }
      for (const theme of sections) {
        if (theme.includes('root')) {
          this.lightTheme.set({ ...this.parseTextToTheme(theme, { ...this.lightTheme() }) });
        } else {
          this.darkTheme.set({ ...this.parseTextToTheme(theme, { ...this.darkTheme() }) });
        }
      }
      if (clear) {
        event.target.value = '';
      }
    });
  }

  private parseTextToTheme(text: string, theme: Record<string, ThemeColor>): Record<string, ThemeColor> {
    const lines = text.split('\n');
    for (const line of lines) {
      if (line.includes('--')) {
        const key = camelize(line.split(':')[0].trim().replace('--', ''));
        const value = line.split(':')[1].trim();
        const hslColor = value.split(',');
        if (hslColor.length === 3) {
          const hue = parseInt(hslColor[0].trim());
          const saturation = parseInt(hslColor[1].trim().replace('%', ''));
          const lightness = parseInt(hslColor[2].trim().replace('%', ''));
          theme[key] = { hue, saturation, lightness };
        }
      }
    }
    return theme;
  }

  public importFromClipboard(): void {
    navigator['clipboard'].readText().then((data) => {
      this.onConfigTextChange({ target: { value: data } }, true);
    });
  }

  public onFileInput(event: any): void {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = () => {
      this.onConfigTextChange({ target: { value: fileReader.result } }, true);
    };
    fileReader.onerror = (error) => {
      this.toaster.insertToast({ text: 'Fehler beim Lesen der Datei! ' + error, type: 'error' });
    };
  }
}
